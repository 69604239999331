export interface ProgramReportQuestions {
  programs: ProgramOption[];
}

export interface ValidProgramReportQuestions {
  reports: ReportQuestion[]
}

export interface ProgramOption {
  programId: string,
  programTitle:  string,
  programDate: Date,
  reports: ReportQuestion[]
}

export interface ReportQuestion {
  reportId: string;
  title: string;
  reportDate: Date;
  questionIds: ReportQuestionId[]
}

export interface ReportQuestionId
{ 
  questionId: string;
  title: string;
  type: InsightsChartType;
}

export interface QuestionMapping {
  reportId: string;
  questionId: string;
}

export interface ChartFilter {
  type: InsightsChartType;
  programIdFilter: string[],
  reportIdFilter: string[],
  questionIdFilter: string[],
  gptquery: string,
  action?:string,
  chartType:string
}

export interface TableData {
  questionType: string;
  questionTitle: string;
  response: string;
  score: number;
}


export interface ChartDataPoint {
  description: any;
  title: string,
  order: number,
  filter: ChartFilter,
  staticMedia?: StaticMedia,
  dataPointId?: string;
}

export interface StaticMedia {
  type: string;
  media: string;
}

export interface InsightChartData {
  title: string;
  description: string;
  data: {
    categories: string[];
    series: {
      [key: string]: number[];
    },
    reportQuestions: {
      [key: string]: string[]
    }
  };
  questionMappings: QuestionMapping[];
}

export interface InsightsResponse {
  clientId: string;
  insightsId: string;
  insightsCharts: ChartDataPoint[];
  name: string;
  description: string;
}

export interface InsightsChartsProps {
  insightsCharts: ChartDataPoint[];
  fetchData: () => void;
  boardId: string;
}

export enum InsightsChartType {
  Unmapped = "Unmapped",
  Opinion = "Opinion",
  OpinionSummary = "OpinionSummary",
  Open = "Open",
  Multi = "Multi",
  Nps = "Nps",
  Ranking = "Ranking",
  Rating = "Rating",
  None = "None"
}



export enum InsightsChartAction {
  Sum = "Sum",
  Average = "Average",
  Count = "Count"
}

export const allowChartTypes = {
  [InsightsChartType.OpinionSummary]: [InsightsChartType.Opinion],
  [InsightsChartType.Opinion]: [InsightsChartType.Opinion, InsightsChartType.OpinionSummary],
  [InsightsChartType.Rating]: [InsightsChartType.Rating],
  [InsightsChartType.Nps]: [InsightsChartType.Nps],
  [InsightsChartType.Open]: [] as InsightsChartType[],
  [InsightsChartType.Multi]: [InsightsChartType.Multi],
  [InsightsChartType.Ranking]: [] as InsightsChartType[],
  [InsightsChartType.Unmapped]: [] as InsightsChartType[],
  [InsightsChartType.None]: [] as InsightsChartType[],
}