import { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    Menu, 
    MenuItem, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField, 
    Grid,
    IconButton,
    Stack,
    Alert
} from '@mui/material';
import FathomClient from 'api/fathomapi';
import { useAuth0 } from '@auth0/auth0-react';
import { read, utils } from 'xlsx';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Title from "@einhorn/title";

interface ParticipantsUploadProps {
    programId: string | null;
    onParticipantsUpdate?: (participants: {name: string, email: string}[]) => void;
}

const ParticipantsUpload = ({ programId, onParticipantsUpdate }: ParticipantsUploadProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [xlsxAvailable, setXlsxAvailable] = useState(true);

    // Check if xlsx is available
    useEffect(() => {
        try {
            // Just test if we can access the xlsx methods
            if (typeof read !== 'function' || typeof utils !== 'object') {
                console.error('xlsx library not properly loaded');
                setXlsxAvailable(false);
            }
        } catch (error) {
            console.error('Error checking xlsx availability:', error);
            setXlsxAvailable(false);
        }
    }, []);

    // Add participant menu state
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    // Modal states
    const [manualAddOpen, setManualAddOpen] = useState(false);
    const [csvUploadOpen, setCsvUploadOpen] = useState(false);
    
    // New participant form state
    const [newParticipant, setNewParticipant] = useState({
        name: '',
        email: ''
    });
    
    // Multiple participants list
    const [participants, setParticipants] = useState<{name: string, email: string}[]>([]);
    
    // CSV file state
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewData, setPreviewData] = useState<{name: string, email: string}[]>([]);
    const [isPreviewReady, setIsPreviewReady] = useState(false);

    // Update the parent component when participants change
    useEffect(() => {
        if (onParticipantsUpdate) {
            onParticipantsUpdate(participants);
        }
    }, [participants, onParticipantsUpdate]);

    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleManualAddOpen = () => {
        handleMenuClose();
        setManualAddOpen(true);
    };

    const handleManualAddClose = () => {
        setManualAddOpen(false);
        setNewParticipant({
            name: '',
            email: ''
        });
    };

    const handleCsvUploadOpen = () => {
        handleMenuClose();
        setCsvUploadOpen(true);
        setPreviewData([]);
        setIsPreviewReady(false);
    };

    const handleCsvUploadClose = () => {
        setCsvUploadOpen(false);
        setSelectedFile(null);
        setPreviewData([]);
        setIsPreviewReady(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewParticipant(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            processFile(e.target.files[0]);
        }
    };

    const processFile = (file: File) => {
        try {
            setLoading(true);
            
            // Read the file using XLSX library
            const fileReader = new FileReader();
            
            fileReader.onload = (event) => {
                try {
                    if (!event.target || !event.target.result) {
                        throw new Error('Failed to read file');
                    }
                    
                    // Parse workbook from array buffer
                    const arrayBuffer = event.target.result;
                    const workbook = read(arrayBuffer, { type: 'array' });
                    
                    // Get the first worksheet
                    const worksheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[worksheetName];
                    
                    // Convert to JSON
                    const data = utils.sheet_to_json(worksheet);
                    
                    if (!Array.isArray(data) || data.length === 0) {
                        throw new Error('No valid data found in file');
                    }
                    
                    // Map to the required format
                    const mappedData = mapToParticipantFormat(data);
                    setPreviewData(mappedData);
                    setIsPreviewReady(true);
                } catch (error) {
                    console.error('Error processing file:', error);
                    setErrorMessage('Error processing file: ' + (error instanceof Error ? error.message : 'Invalid format'));
                } finally {
                    setLoading(false);
                }
            };
            
            fileReader.onerror = () => {
                console.error('Error reading file');
                setLoading(false);
                setErrorMessage('Error reading file');
            };
            
            // Read as array buffer for XLSX processing
            fileReader.readAsArrayBuffer(file);
            
        } catch (error) {
            console.error('Error uploading file:', error);
            setLoading(false);
        }
    };

    const handleAddParticipant = () => {
        // Add the participant to the list
        setParticipants(prev => [...prev, {...newParticipant}]);
        
        // Show success message
        setSuccessMessage(`Added ${newParticipant.name} to the program`);
        setTimeout(() => setSuccessMessage(''), 3000);
        
        // Close the dialog
        handleManualAddClose();
    };

    const handleSubmitCsv = () => {
        if (previewData.length === 0) {
            setErrorMessage('No valid participants found in file');
            return;
        }
        
        // Add all preview data to participants
        setParticipants(prev => [...prev, ...previewData]);
        
        // Show success message
        setSuccessMessage(`Added ${previewData.length} participants from file`);
        setTimeout(() => setSuccessMessage(''), 3000);
        
        // Close the dialog
        handleCsvUploadClose();
    };

    // Function to map spreadsheet data to the participant format
    const mapToParticipantFormat = (data: any[]): Array<{name: string, email: string}> => {
        return data.map(row => {
            // Try various possible column names for name and email
            const name = row.name || row.Name || row.NAME || row.fullName || row['Full Name'] || 
                         (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : null) ||
                         (row.FirstName && row.LastName ? `${row.FirstName} ${row.LastName}` : null);
                         
            const email = row.email || row.Email || row.EMAIL || row['Email Address'] || row.emailAddress;
            
            if (!name || !email) {
                console.warn('Skipping row due to missing name or email:', row);
            }
            
            return { name, email };
        }).filter(item => item.name && item.email); // Filter out items with missing fields
    };

    return (
        <Box>
            <Title>Program Participants</Title>
            <Typography variant="subtitle1" gutterBottom>
                Add participants to your program. You can add them individually or upload a CSV/Excel file.
                {programId ? "" : " Participants will be added when you save the program."}
            </Typography>
            
            {/* Success and Error Messages */}
            {successMessage && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {successMessage}
                </Alert>
            )}
            
            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}
            
            <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ mb: 3 }}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                    color="primary"
                >
                    Add Participants
                </Button>
            </Stack>
            
            {/* Participants List */}
            {participants.length > 0 ? (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Added Participants ({participants.length})
                    </Typography>
                    <Grid container spacing={1}>
                        {participants.map((p, index) => (
                            <Grid item xs={12} sm={6} md={4} key={`${p.email}-${index}`}>
                                <Box 
                                    sx={{ 
                                        p: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1
                                    }}
                                >
                                    <Typography variant="subtitle2">{p.name}</Typography>
                                    <Typography variant="body2" color="text.secondary">{p.email}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ) : (
                <Alert severity="info" sx={{ mb: 3 }}>
                    No participants added yet. Use the &quot;Add Participants&quot; button to add people to your program.
                </Alert>
            )}
            
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleManualAddOpen}>
                    <PersonAddIcon sx={{ mr: 1 }} />
                    Add Manually
                </MenuItem>
                {xlsxAvailable ? (
                    <MenuItem onClick={handleCsvUploadOpen}>
                        <FileUploadIcon sx={{ mr: 1 }} />
                        Upload CSV
                    </MenuItem>
                ) : (
                    <MenuItem disabled>
                        <FileUploadIcon sx={{ mr: 1 }} />
                        CSV Upload Unavailable
                    </MenuItem>
                )}
            </Menu>
            
            {/* Manual Add Dialog */}
            <Dialog open={manualAddOpen} onClose={handleManualAddClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Add Participant
                    <IconButton
                        aria-label="close"
                        onClick={handleManualAddClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Name"
                                value={newParticipant.name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                label="Email"
                                type="email"
                                value={newParticipant.email}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleManualAddClose}>Cancel</Button>
                    <Button 
                        onClick={handleAddParticipant} 
                        variant="contained" 
                        disabled={!newParticipant.name || !newParticipant.email}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            
            {/* CSV Upload Dialog */}
            <Dialog open={csvUploadOpen} onClose={handleCsvUploadClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    Upload Participants
                    <IconButton
                        aria-label="close"
                        onClick={handleCsvUploadClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Upload a CSV or Excel file with participant information. The file should have columns for name and email.
                    </Typography>
                    <Box 
                        sx={{ 
                            backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                            p: 2, 
                            borderRadius: 1,
                            fontFamily: 'monospace',
                            mb: 2
                        }}
                    >
                        <pre style={{ margin: 0, overflow: 'auto' }}>
{`name,email
John Doe,john@example.com
Jane Smith,jane@example.com`}
                        </pre>
                    </Box>
                    <Button
                        variant="outlined"
                        component="label"
                        startIcon={<FileUploadIcon />}
                    >
                        Select File
                        <input
                            type="file"
                            accept=".csv,.xlsx,.xls"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                    {selectedFile && (
                        <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                            Selected file: {selectedFile.name}
                        </Typography>
                    )}
                    
                    {/* Preview Data */}
                    {isPreviewReady && (
                        <>
                            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                Preview ({previewData.length} participants)
                            </Typography>
                            
                            {previewData.length > 0 ? (
                                <Grid container spacing={1} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                    {previewData.slice(0, 20).map((p, index) => (
                                        <Grid item xs={12} sm={6} key={`${p.email}-${index}`}>
                                            <Box 
                                                sx={{ 
                                                    p: 1,
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Typography variant="subtitle2">{p.name}</Typography>
                                                <Typography variant="body2" color="text.secondary">{p.email}</Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Alert severity="warning" sx={{ mt: 1 }}>
                                    No valid participant data found in the file. Please check the file format.
                                </Alert>
                            )}
                            
                            {previewData.length > 20 && (
                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                    Showing 20 of {previewData.length} participants
                                </Typography>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCsvUploadClose}>Cancel</Button>
                    <Button 
                        onClick={handleSubmitCsv} 
                        variant="contained"
                        disabled={!isPreviewReady || previewData.length === 0}
                    >
                        Add {previewData.length} Participant{previewData.length !== 1 ? 's' : ''}
                    </Button>
                </DialogActions>
            </Dialog>
        
        </Box>
    );
};

export default ParticipantsUpload; 