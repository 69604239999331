import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import Moment from "moment";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChangeEvent, useEffect, useRef, useState } from "react";
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { PagedResult } from "app/types";
import debounce from 'lodash/debounce';
import LoadingBar from "@einhorn/loadingBar";
import ConfirmDialog from '@einhorn/confirmDialog';

const cookies = new Cookies();

const drawerWidth = 339;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: 'relative',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      },
    },
  ],
}));


export default function Programs() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [programs, setPrograms] = useState({
    data: [],
    paging: {
      length: 0,
      pageSize: 0,
      page: 0,
      total: 0
    }
  } as PagedResult<any>);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedProgramName, setSelectedProgramName] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const mapData = (z: any) => {
    return {
      id: z.id,
      name: z.name,
      description: z.description,
      startDate: z.startDate,
    };
  };

  const IsAdmin = () => {
    return localStorage.getItem("x-fathom-currentRole") == "Admin"
  }

  const createNew = () => {
    navigate("/programs/create");
  };
  
  const edit = (id: string) => {
    navigate(`/programs/create/${id}`);
  };

  const viewReports = (programId: string) => {
    navigate("/programs/" + programId);
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage != page) {
      setPage(page);
      loadPrograms(search, newPage)
    }
  };

  const handleSearch = debounce((searchTerm: string, pageNo: number) => {
    loadPrograms(searchTerm, pageNo)
  }, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue)
    handleChangePage({} as ChangeEvent<unknown>, 1)
    handleSearch(newValue, 1);
  };

  const loadPrograms = async (searchTerm: string, page: number) => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    const { data } = await apiClient.get(`{clientId}/programs?page=` + page + "&search=" + searchTerm);
    setPrograms(data);
    var mapped = data.data.map(mapData);
    setRows(mapped);
    setLoading(false);
  }

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      if (cookies.get("x-fathom-clientId") === undefined) {
        var loc = localStorage.getItem("saved_invite");
        if (loc != undefined) {
          navigate(loc || '')
        }
      } else {
        loadPrograms("", 1)
      }
    };
    setLoading(true);
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, programId: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedProgramId(programId);
  };

  const handleMenuClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedProgramId) {
      edit(selectedProgramId);
      handleMenuClose(e);
    }
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedProgramId) {
      // Find the program name for the confirmation dialog
      const program = programs.data.find((p: any) => p.id === selectedProgramId);
      setSelectedProgramName(program?.name || "this program");
      // Don't close the menu here to keep the selectedProgramId value
      setConfirmDialogOpen(true);
      handleMenuClose(e);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      if (!selectedProgramId) {
        console.error("No program id selected for deletion");
        return;
      }
      
      console.log("Deleting program:", selectedProgramId);
      setDeleteLoading(true);
      
      const apiClient = new FathomClient(await getAccessTokenSilently());
      await apiClient.delete(`{clientId}/programs/${selectedProgramId}`);
      
      // Reload programs after successful deletion
      await loadPrograms(search, page);
    } catch (error) {
      console.error("Error deleting program:", error);
    } finally {
      setDeleteLoading(false);
      setConfirmDialogOpen(false);
      setSelectedProgramId(null);
    }
  };


  const getPrograms = () => {
    return (
      <Grid container spacing={3}>
        {programs.data.map((x: any) => (
          <Grid item xs={12} sm={6} md={4} key={x.id}>
            <Paper
              className="programOverview"
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 128,
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: "0px 0px 5px 1px rgba(99, 39, 230, 0.1)",
                  '& .menu-button': {
                    opacity: 1,
                  },
                },
                position: 'relative'
              }}
              onClick={() => viewReports(x.id)}
            >
              <IconButton
                className="menu-button"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  zIndex: 1,
                }}
                onClick={(e) => handleMenuClick(e, x.id)}
              >
                <MoreVertIcon />
              </IconButton>

              <Typography
                variant="h5"
                noWrap
                sx={{
                  mb: 0.5,
                  fontSize: '14px',
                  fontWeight: 'bold',
                  paddingRight: '32px' // Make room for menu button
                }}
              >
                {x.name || "Untitled Program"}
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{
                  mb: 0.5,
                  fontSize: '14px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1.2em',
                  height: '2.4em'
                }}
              >
                {x.description || "No description"}
              </Typography>

              <Typography variant="body2" noWrap sx={{ fontSize: '12px', color: 'text.secondary', mt: 'auto' }} >
                {Moment(x.startDateUtc).format("Do MMMM YYYY") || ""}
              </Typography>

            </Paper>
          </Grid>
        ))}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditClick}>
            <EditIcon fontSize="small" sx={{ mr: 1 }} />
            Edit
          </MenuItem>
          <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    );
  };

  return (
    <Main>
      <Container>
        <br />
        <Button >Programs</Button>
        <Button variant="outlined" hidden={!IsAdmin()} onClick={() => createNew()} sx={{ float: 'right', marginBottom: '-30px' }}>
          New program
        </Button>
        <br />
        <br />
        {loading && programs.paging.length == 0 &&
          <LoadingBar />
        }

        {!loading &&
          <>
            <Stack spacing={2} direction="row" justifyContent="left" sx={{ marginBottom: '-20px' }}>
              <TextField
                label="Search"
                variant="standard"
                value={search}
                onChange={handleSearchChange}
              />
            </Stack>
            <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
              <Pagination
                count={Math.ceil(programs.paging.total / programs.paging.pageSize)}
                page={programs.paging.page}
                onChange={handleChangePage}
                color="primary"
                variant="outlined"
                showFirstButton showLastButton
              />
            </Stack>
          </>
        }
        {programs.paging.length == 0 && !loading && (
          <Paper>
            {" "}
            <Container>
              <br />
              <Typography variant="h6">
                No programs found
              </Typography>
              <br />
            </Container>
          </Paper>
        )}
        {getPrograms()}
      </Container>
      <br />
      
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Delete Program"
        content={`Are you sure you want to delete "${selectedProgramName}"? This action cannot be undone.`}
        confirmAction={handleConfirmDelete}
        setOpen={setConfirmDialogOpen}
        confirmText="Delete"
        confirmButtonProps={{ 
          color: "error",
          disabled: deleteLoading 
        }}
        isLoading={deleteLoading}
      />
    </Main>
  );
}
