import { useAuth0 } from "@auth0/auth0-react";
import Title from "@einhorn/title";
import {
  Container,
  Toolbar,
  Grid,
  Button,
  Paper,
  LinearProgress,
  Typography,
  Box,
  Modal,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Tab,
  Pagination,
  Stack,
} from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "@einhorn/confirmDialog";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Moment from "moment";
import './users.css';
import { PagedResult } from "app/types";
import LoadingBar from "@einhorn/loadingBar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "56%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  "& > .MuiBackdrop-root": {
    bgcolor: "white",
  },
};
const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
export default function UsersList() {
  const { getAccessTokenSilently } = useAuth0();
  const [saveError, setSaveError] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState({
    id: "",
    name: "",
    email: "",
  });
  const [users, setUsers] = useState({
    data: [],
    paging: {
      length: 0,
      pageSize: 0,
      page: 0,
      total: 0
    }
  } as PagedResult<any>);
  const [invites, setInvites] = useState({
    data: [],
    paging: {
      length: 0,
      pageSize: 0,
      page: 0,
      total: 0
    }
  } as PagedResult<any>);
  const [loading, setLoading] = useState(true);
  const [saveUserClicked, setSaveUserClicked] = useState(false);
  const [loadingInvites, setLoadingInvites] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    UserFathomClientRole: "viewer",
  });
  const [tabValue, setTabValue] = useState("0");
  const [page, setPage] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const fetchUsersData = async (pageNo: number) => {
    setLoading(true);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const { data } = await apiClient.get(`{clientId}/users?page=${pageNo}`);
      setUsers(data);
    } catch (error) {
      setUsers({ data: [], paging: { length: 0, pageSize: 0, page: 0, total: 0 } });
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitesData = async (pageNo: number) => {
    setLoadingInvites(true);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const { data } = await apiClient.get(`{clientId}/users/invites?page=${pageNo}`);
      setInvites(data);
    } catch (error) {
      setInvites({ data: [], paging: { length: 0, pageSize: 0, page: 0, total: 0 } });
    } finally {
      setLoadingInvites(false);
    }
  };

  useEffect(() => {
    fetchUsersData(1);
    fetchInvitesData(1);
  }, [getAccessTokenSilently]);

  const inviteUser = () => {
    setModalOpen(true);
  };

  const IsAdmin = () => {
    return localStorage.getItem("x-fathom-currentRole") == "Admin"
  }

  const saveUser = async () => {
    setSaveError('');
    if (!isValidEmail(newUser.email)) {
      setSaveError('Please enter a valid email address.');
      return;
    }
    setSaveUserClicked(true);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      await apiClient.post(`{clientId}/users/add`, newUser);
      await fetchInvitesData(1);
      clearNewUser();
    } catch (error) {
      console.error("Error saving user:", error);
      setSaveError('Failed to save user. Please try again.');
    } finally {
      setSaveUserClicked(false);
    }

  };

  const removeUser = async () => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    await apiClient.post(`{clientId}/users/remove`, {
      id: deleteUser.id,
      name: deleteUser.name,
      email: deleteUser.email,
    });
    fetchUsersData(1);
    setConfirmDeleteOpen(false);
  };

  const showDeleteConfirm = (user: any) => {
    setDeleteUser(user);
    setConfirmDeleteOpen(true);
  };
  const clearNewUser = () => {
    setModalOpen(false);
    setNewUser({
      name: "",
      email: "",
      UserFathomClientRole: "viewer",
    });
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    if (tabValue === "0") {
      fetchUsersData(newPage);
    } else {
      fetchInvitesData(newPage);
    }
  };

  

  const getInvites = () => {
    return invites.data.map((x: any) => (
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
        key={x.id}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid sm={7} item>
              <Typography variant="h6">{x.title}</Typography>
              <Typography variant="subtitle1">{x.userName}</Typography>
              <Typography variant="subtitle2">{x.userEmail}</Typography>
            </Grid>
            <Grid sm={3} item>
              <Typography sx={{ color: "#4126A6" }}>{x.clientRole}</Typography>
            </Grid>
            <Grid sm={2} item>
              <Typography sx={{ color: "#4126A6" }}>
               {x.accepted ? 'Accepted on ' + Moment(x.acceptedOnUtc).format("d MMM yyyy") : (x.declinedOnUtc > '0001-01-01T00:00:00' ? 'Declined on ' + Moment(x.declinedOnUtc).format("d MMM yyyy") : 'Pending')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    ));
  };

  const getUsers = () => {
    return users.data.map((x: any) => (
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
        key={x.id}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid sm={7} item>
              <Typography variant="h6">{x.title}</Typography>
              <Typography variant="subtitle1">{x.name}</Typography>
              <Typography variant="subtitle2">{x.email}</Typography>
            </Grid>
            <Grid sm={3} item>
              <Typography sx={{ color: "#4126A6" }}>{x.userFathomClientRole}</Typography>
            </Grid>
            <Grid sm={2} item>
              <Button variant="text" onClick={() => showDeleteConfirm(x)}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    ));
  };

  const getModal = () => {
    return (
      <Modal
        sx={style}
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add new user
          </Typography>
          <hr />
          <InputLabel id="name-text-label">Name</InputLabel>
          <TextField
            id="outlined-basic"
            fullWidth
            variant="outlined"
            value={newUser.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewUser({ ...newUser, name: event.target.value });
            }}
          />
          <br />
          <br />
          <InputLabel id="email-text-label">Email</InputLabel>
          <TextField
            id="outlined-basic"
            fullWidth
            variant="outlined"
            value={newUser.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewUser({ ...newUser, email: event.target.value });
            }}
          />
          <br />
          <br />
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            sx={{ width: "100%" }}
            labelId="role-select-label"
            id="role-select"
            value={newUser.UserFathomClientRole}
            label="Role"
            onChange={(mode: SelectChangeEvent) => {
              setNewUser({
                ...newUser,
                UserFathomClientRole: mode.target.value,
              });
            }}
          >
            <MenuItem value={"Viewer"}>Viewer</MenuItem>
            <MenuItem value={"Admin"}>Admin</MenuItem>
          </Select>
            <div className="error-message-area">
              {saveError && (
                <Typography color="error" variant="body2">
                  {saveError}
                </Typography>
              )}
            </div>
          <hr />
            <div className="bottom_buttons">
              <Button
                variant="contained"
                disabled={saveUserClicked}
                onClick={() => saveUser()}
              >
                {saveUserClicked ? 'Saving...' : 'Save'}
              </Button>

              <Button
                style={{ marginLeft: "auto" }}
                variant="outlined"
                onClick={() => clearNewUser()}
              >
                Close
              </Button>
            </div>
          </Container>
        </Modal>
      );
    };

  return (
    <Container>
      {getModal()}
      <ConfirmDialog
        title="Delete User"
        content="Are you sure you want to delete this user?"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        confirmAction={removeUser}
      />
      <Container>
        <Toolbar />
        <Grid item xs={12}>
          <br />
          <Container>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="Module info"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab key={0} label="users" wrapped value={"0"} />
                  <Tab key={1} label="invites" wrapped value={"1"} />
                </TabList>
              </Box>
              <TabPanel key={0} value={"0"}>
                <Button variant="outlined" onClick={() => inviteUser()} sx={{ float: 'right' }}>
                  Invite
                </Button>
                <br />
                <br />
                {loading && users.data.length === 0 && (
                  <LoadingBar />
                )}
                <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
                {!loading && users.paging && (
                <Pagination
                    count={Math.max(1, Math.ceil((users.paging.total || 0) / (users.paging.pageSize || 1)))}
                    page={users.paging.page || 1}
                    onChange={handleChangePage}
                    color="primary"
                    variant="outlined"
                    showFirstButton showLastButton
                  />
                )}
                </Stack>
                {users.data.length === 0 && !loading && (
                  <Paper>
                    <Container>
                      <br />
                      <Typography variant="h6">Add your first user</Typography>
                      <br />
                    </Container>
                  </Paper>
                )}
                {getUsers()}
              </TabPanel>
                <TabPanel key={1} value={"1"}>
                  {loadingInvites && invites.data.length === 0 && (
                    <LoadingBar />
                  )}
                  <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
                  {!loading && invites.paging && (
                    <Pagination
                      count={Math.max(1, Math.ceil((invites.paging.total || 0) / (invites.paging.pageSize || 1)))}
                      page={invites.paging.page || 1}
                      onChange={handleChangePage}
                      color="primary"
                      variant="outlined"
                      showFirstButton showLastButton
                    />
                  )}
                  </Stack>
                  {invites.data.length === 0 && !loadingInvites && (
                    <Paper>
                      <Container>
                        <br />
                        <Typography variant="h6">No invites yet</Typography>
                        <br />
                      </Container>
                    </Paper>
                  )}
                  {getInvites()}
                </TabPanel>
            </TabContext>
          </Container>
          <br />
          <br />
        </Grid>
      </Container>
    </Container>
  );
}
