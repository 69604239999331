import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Container,
    IconButton,
    Select,
    MenuItem,
    Radio,
    Divider,
    Tooltip,
    Switch,
    CircularProgress,
    Alert,
} from '@mui/material';
import {
    TextFields as TextFieldsIcon,
    Star as StarIcon,
    RadioButtonChecked as RadioIcon,
    Score as ScoreIcon,
    ThumbsUpDown as OpinionIcon,
    FormatListNumbered as RankingIcon,
    PostAdd as PageBreakIcon,
    Delete as DeleteIcon,
    ContentCopy as DuplicateIcon,
    Add as AddIcon,
    MoreVert as MoreIcon,
    DragIndicator as DragHandleIcon,
    Save as SaveIcon,
    AutoFixHigh as AIIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    MeasuringStrategy,
    defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import FathomClient from '@api/fathomapi';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '@einhorn/breadcrumb/breadcrumb';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface SurveyQuestion {
    type: string;
    title: string;
    choices?: string[];
    rangeMin?: number;
    rangeMax?: number;
    required?: boolean;
    askFollowUp?: boolean;
}

interface SurveyPage {
    title: string;
    description?: string;
    elements: SurveyQuestion[];
}

interface Survey {
    title: string;
    description: string;
    purpose?: string;
    pages: SurveyPage[];
    surveyDateUtc?: string;
}

interface QuestionTypeConfig {
    type: string;
    label: string;
    icon: JSX.Element;
    initialState?: Partial<SurveyQuestion>;
}

interface SortableQuestionProps {
    id: string;
    pageIndex: number;
    questionIndex: number;
    question: SurveyQuestion;
    questionTypes: QuestionTypeConfig[];
    onQuestionChange: (pageIndex: number, questionIndex: number, updates: Partial<SurveyQuestion>) => void;
    onQuestionTypeChange: (pageIndex: number, questionIndex: number, newType: string) => void;
    onDuplicateQuestion: (pageIndex: number, questionIndex: number) => void;
    onDeleteClick: (type: 'page' | 'question', pageIndex: number, questionIndex?: number) => void;
}

// Add this new component right before QuestionContent component
const ChoiceOption = React.memo(({
    choice,
    index,
    pageIndex,
    questionIndex,
    questionChoices,
    onQuestionChange
}: {
    choice: string,
    index: number,
    pageIndex: number,
    questionIndex: number,
    questionChoices: string[] | undefined,
    onQuestionChange: (pageIndex: number, questionIndex: number, updates: Partial<SurveyQuestion>) => void
}) => {
    // Now the hooks are at the top level of a component
    const [localChoice, setLocalChoice] = useState(choice);

    // Create a debounced update function for this specific choice
    const debouncedChoiceUpdate = useCallback(
        debounce((value: string) => {
            const newChoices = [...(questionChoices || [])];
            newChoices[index] = value;
            onQuestionChange(pageIndex, questionIndex, { choices: newChoices });
        }, 300),
        [pageIndex, questionIndex, questionChoices, index, onQuestionChange]
    );

    // Update local state when choice prop changes
    useEffect(() => {
        setLocalChoice(choice);
    }, [choice]);

    // Handle local choice change
    const handleChoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalChoice(newValue);
        debouncedChoiceUpdate(newValue);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                gap: 1
            }}
        >
            <Radio disabled />
            <TextField
                fullWidth
                variant="standard"
                placeholder={`Option ${index + 1}`}
                value={localChoice}
                onChange={handleChoiceChange}
                sx={{
                    '& .MuiInput-root': {
                        '&:before, &:after': {
                            display: 'none'
                        }
                    }
                }}
            />
            <IconButton
                size="small"
                onClick={() => {
                    const newChoices = questionChoices?.filter((_, i) => i !== index);
                    onQuestionChange(pageIndex, questionIndex, { choices: newChoices });
                }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Box>
    );
});

ChoiceOption.displayName = 'ChoiceOption';

// Create a separate memoized component for the question content
const QuestionContent = React.memo(({
    question,
    questionTypes,
    pageIndex,
    questionIndex,
    onQuestionChange,
    onQuestionTypeChange,
    onDuplicateQuestion,
    onDeleteClick,
    isDragging,
    dragHandleProps
}: SortableQuestionProps & {
    isDragging: boolean,
    dragHandleProps?: {
        attributes?: any,
        listeners?: any
    }
}) => {
    // Add state handlers for local state to prevent re-renders
    const [localTitle, setLocalTitle] = useState(question.title);

    // Use debounce to reduce state updates
    const debouncedTitleUpdate = useCallback(
        debounce((value: string) => {
            onQuestionChange(pageIndex, questionIndex, { title: value });
        }, 300),
        [pageIndex, questionIndex, onQuestionChange]
    );

    // Handle local title change
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalTitle(newValue);
        debouncedTitleUpdate(newValue);
    };

    // Update local state when the question prop changes
    useEffect(() => {
        setLocalTitle(question.title);
    }, [question.title]);

    return (
        <Paper
            sx={{
                p: 0,
                mb: 2,
                bgcolor: 'background.paper',
                borderRadius: 1,
                border: '1px solid',
                borderColor: isDragging ? 'primary.main' : 'divider',
                '&:hover': {
                    borderColor: 'primary.main',
                },
                cursor: isDragging ? 'grabbing' : 'default',
                width: '100%', // Ensure the paper maintains full width
                boxSizing: 'border-box'
            }}
            elevation={0}
        >
            <Box sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                    <Box
                        {...(dragHandleProps?.attributes || {})}
                        {...(dragHandleProps?.listeners || {})}
                        sx={{
                            cursor: isDragging ? 'grabbing' : 'grab',
                            color: isDragging ? 'primary.main' : 'action.active',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 1,
                            borderRadius: 1,
                            backgroundColor: isDragging ? 'action.selected' : 'transparent',
                            '&:hover': {
                                backgroundColor: 'action.hover'
                            },
                            minWidth: '24px', // Ensure consistent size
                            minHeight: '24px'
                        }}
                    >
                        <DragHandleIcon />
                    </Box>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Question"
                        value={localTitle}
                        onChange={handleTitleChange}
                        multiline
                        sx={{
                            '& .MuiInput-root': {
                                fontSize: '1rem',
                                '&:before, &:after': {
                                    display: 'none'
                                },
                                '& textarea': {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'pre-wrap',
                                    minHeight: '24px'
                                }
                            },
                            flex: 1
                        }}
                    />
                    <Select
                        value={question.type}
                        onChange={(e) => onQuestionTypeChange(pageIndex, questionIndex, e.target.value)}
                        variant="standard"
                        sx={{
                            minWidth: 150,
                            '&:before, &:after': {
                                display: 'none'
                            }
                        }}
                    >
                        {questionTypes.map((type) => (
                            <MenuItem key={type.type} value={type.type}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {type.icon}
                                    <Typography>{type.label}</Typography>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                {(question.type === 'multi' || question.type === 'ranking') && (
                    <Box sx={{ pl: 4 }}>
                        {(question.choices || []).map((choice, index) => (
                            <ChoiceOption
                                key={index}
                                choice={choice}
                                index={index}
                                pageIndex={pageIndex}
                                questionIndex={questionIndex}
                                questionChoices={question.choices}
                                onQuestionChange={onQuestionChange}
                            />
                        ))}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                pl: 4,
                                mt: 1,
                                color: 'primary.main',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                const newChoices = [...(question.choices || []), ''];
                                onQuestionChange(pageIndex, questionIndex, { choices: newChoices });
                            }}
                        >
                            <Button
                                startIcon={<AddIcon />}
                                variant="text"
                            >
                                Add option
                            </Button>
                        </Box>
                    </Box>
                )}

                {question.type === 'rating' && (
                    <Box sx={{ pl: 4 }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }}>
                                <Typography variant="body2" color="text.secondary">Scale:</Typography>
                                <Select
                                    value={question.rangeMax || 5}
                                    onChange={(e) => onQuestionChange(pageIndex, questionIndex, {
                                        rangeMin: 1,
                                        rangeMax: Number(e.target.value)
                                    })}
                                    variant="standard"
                                    size="small"
                                    sx={{ minWidth: 100 }}
                                >
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 0.5
                            }}>

                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${question.rangeMax || 5}, 1fr)`,
                                    width: question.rangeMax === 10 ? '91.5%' : '46%',
                                    mt: 0.5
                                }}>
                                    {Array.from({ length: question.rangeMax || 5 }, (_, i) => (
                                        <Box
                                            key={i}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: 0.5
                                            }}
                                        >
                                            <Radio
                                                disabled
                                                size="small"
                                                sx={{
                                                    padding: 0.5,
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '1.25rem'
                                                    }
                                                }}
                                            />
                                            <Typography
                                                variant="caption"
                                                color="text.secondary"
                                                sx={{ fontSize: '0.75rem' }}
                                            >
                                                {i + 1}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}

                {question.type === 'nps' && (
                    <Box sx={{ pl: 4 }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    maxWidth: 660
                                }}>
                                    {Array.from({ length: 11 }, (_, i) => (
                                        <Box
                                            key={i}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: 0.5
                                            }}
                                        >
                                            <Radio
                                                disabled
                                                size="small"
                                                sx={{
                                                    padding: 0.5,
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '1.25rem'
                                                    }
                                                }}
                                            />
                                            <Typography
                                                variant="caption"
                                                color="text.secondary"
                                                sx={{ fontSize: '0.75rem' }}
                                            >
                                                {i}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    maxWidth: 660,
                                    px: 1,
                                    mt: 1
                                }}>
                                    <Typography variant="caption" color="text.secondary">
                                        Not at all likely
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        Extremely likely
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}

                {question.type === 'opinion' && (
                    <Box sx={{ pl: 4 }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                {[
                                    'Strongly disagree',
                                    'Disagree',
                                    'Neutral',
                                    'Agree',
                                    'Strongly agree'
                                ].map((choice, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }}
                                    >
                                        <Radio
                                            disabled
                                            checked={false}
                                            size="small"
                                        />
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                            sx={{
                                                fontSize: '0.875rem',
                                                userSelect: 'none'
                                            }}
                                        >
                                            {choice}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>

            <Divider />

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
                py: 1
            }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Duplicate this question" placement="top">
                        <IconButton
                            size="small"
                            onClick={() => onDuplicateQuestion(pageIndex, questionIndex)}
                        >
                            <DuplicateIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete this question" placement="top">
                        <IconButton
                            size="small"
                            onClick={() => onDeleteClick('question', pageIndex, questionIndex)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    {question.type === 'open' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Tooltip title="When enabled, Fathom will automatically ask relevant follow-up questions based on the respondent's answer to gather more detailed insights" placement="top">
                                <Typography variant="body2" color="text.secondary">Ask follow-up</Typography>
                            </Tooltip>
                            <Switch
                                size="small"
                                checked={question.askFollowUp || false}
                                onChange={(e) => onQuestionChange(pageIndex, questionIndex, { askFollowUp: e.target.checked })}
                            />
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" color="text.secondary">Required</Typography>
                        <Switch
                            size="small"
                            checked={question.required || false}
                            onChange={(e) => onQuestionChange(pageIndex, questionIndex, { required: e.target.checked })}
                        />
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
});

QuestionContent.displayName = 'QuestionContent';

const SortableQuestion = React.memo(({
    id,
    pageIndex,
    questionIndex,
    question,
    questionTypes,
    onQuestionChange,
    onQuestionTypeChange,
    onDuplicateQuestion,
    onDeleteClick,
}: SortableQuestionProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: id,
        data: {
            pageIndex,
            questionIndex
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.75 : 1,
        position: 'relative' as const,
        zIndex: isDragging ? 1000 : 1,
        touchAction: 'none' as const,
        boxShadow: isDragging ? '0 5px 10px rgba(0,0,0,0.15)' : 'none',
        width: '100%'
    };

    return (
        <div ref={setNodeRef} style={style}>
            <QuestionContent
                id={id}
                pageIndex={pageIndex}
                questionIndex={questionIndex}
                question={question}
                questionTypes={questionTypes}
                onQuestionChange={onQuestionChange}
                onQuestionTypeChange={onQuestionTypeChange}
                onDuplicateQuestion={onDuplicateQuestion}
                onDeleteClick={onDeleteClick}
                isDragging={isDragging}
                dragHandleProps={{ attributes, listeners }}
            />
        </div>
    );
}, (prevProps, nextProps) => {
    // Simpler equality check for better performance
    if (prevProps.id !== nextProps.id) return false;

    const prevQ = prevProps.question;
    const nextQ = nextProps.question;

    return (
        prevQ.type === nextQ.type &&
        prevQ.required === nextQ.required &&
        prevQ.askFollowUp === nextQ.askFollowUp &&
        prevQ.rangeMin === nextQ.rangeMin &&
        prevQ.rangeMax === nextQ.rangeMax
    );
});

// Add display name to fix ESLint warning
SortableQuestion.displayName = 'SortableQuestion';

// Create components for the survey fields
const SurveyTitleField = React.memo(({
    title,
    onTitleChange
}: {
    title: string,
    onTitleChange: (value: string) => void
}) => {
    const [localTitle, setLocalTitle] = useState(title);

    const debouncedTitleUpdate = useCallback(
        debounce((value: string) => {
            onTitleChange(value);
        }, 300),
        [onTitleChange]
    );

    useEffect(() => {
        setLocalTitle(title);
    }, [title]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalTitle(newValue);
        debouncedTitleUpdate(newValue);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            placeholder="Survey Title"
            value={localTitle}
            onChange={handleChange}
            sx={{
                mb: 2,
                '& .MuiInput-root': {
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    '&:before': {
                        borderColor: 'transparent'
                    }
                }
            }}
        />
    );
});

SurveyTitleField.displayName = 'SurveyTitleField';

const SurveyDescriptionField = React.memo(({
    description,
    onDescriptionChange
}: {
    description: string,
    onDescriptionChange: (value: string) => void
}) => {
    const [localDesc, setLocalDesc] = useState(description);

    const debouncedDescUpdate = useCallback(
        debounce((value: string) => {
            onDescriptionChange(value);
        }, 300),
        [onDescriptionChange]
    );

    useEffect(() => {
        setLocalDesc(description);
    }, [description]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalDesc(newValue);
        debouncedDescUpdate(newValue);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            placeholder="Survey Description (visible to respondents)"
            multiline
            value={localDesc}
            onChange={handleChange}
            sx={{
                mb: 3,
                '& .MuiInput-root': {
                    '&:before': {
                        borderColor: 'transparent'
                    }
                }
            }}
        />
    );
});

SurveyDescriptionField.displayName = 'SurveyDescriptionField';

const SurveyPurposeField = React.memo(({
    purpose,
    onPurposeChange
}: {
    purpose: string,
    onPurposeChange: (value: string) => void
}) => {
    const [localPurpose, setLocalPurpose] = useState(purpose);

    const debouncedPurposeUpdate = useCallback(
        debounce((value: string) => {
            onPurposeChange(value);
        }, 300),
        [onPurposeChange]
    );

    useEffect(() => {
        setLocalPurpose(purpose);
    }, [purpose]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalPurpose(newValue);
        debouncedPurposeUpdate(newValue);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            placeholder="e.g., This is a survey to gather feedback on expectations for an upcoming leadership development program workshop"
            multiline
            minRows={3}
            value={localPurpose}
            onChange={handleChange}
            size="small"
        />
    );
});

SurveyPurposeField.displayName = 'SurveyPurposeField';

const PageTitleField = React.memo(({
    title,
    pageIndex,
    onTitleChange
}: {
    title: string,
    pageIndex: number,
    onTitleChange: (pageIndex: number, value: string) => void
}) => {
    const [localTitle, setLocalTitle] = useState(title);

    const debouncedTitleUpdate = useCallback(
        debounce((value: string) => {
            onTitleChange(pageIndex, value);
        }, 300),
        [pageIndex, onTitleChange]
    );

    useEffect(() => {
        setLocalTitle(title);
    }, [title]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalTitle(newValue);
        debouncedTitleUpdate(newValue);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            placeholder="Page Title"
            value={localTitle}
            onChange={handleChange}
            sx={{
                mb: 2,
                '& .MuiInput-root': {
                    fontSize: '1.25rem',
                    fontWeight: 500,
                    '&:before': {
                        borderColor: 'transparent'
                    }
                }
            }}
        />
    );
});

PageTitleField.displayName = 'PageTitleField';

const PageDescriptionField = React.memo(({
    description,
    pageIndex,
    survey,
    setSurvey
}: {
    description: string | undefined,
    pageIndex: number,
    survey: Survey,
    setSurvey: React.Dispatch<React.SetStateAction<Survey>>
}) => {
    const [localDesc, setLocalDesc] = useState(description || '');

    const debouncedDescUpdate = useCallback(
        debounce((value: string) => {
            const newSurvey = { ...survey };
            newSurvey.pages[pageIndex].description = value;
            setSurvey(newSurvey);
        }, 300),
        [pageIndex, survey, setSurvey]
    );

    useEffect(() => {
        setLocalDesc(description || '');
    }, [description]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setLocalDesc(newValue);
        debouncedDescUpdate(newValue);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            placeholder="Page Description (optional)"
            multiline
            value={localDesc}
            onChange={handleChange}
            sx={{
                '& .MuiInput-root': {
                    '&:before': {
                        borderColor: 'transparent'
                    }
                }
            }}
        />
    );
});

PageDescriptionField.displayName = 'PageDescriptionField';

const SurveyDateField = React.memo(({
    date,
    onDateChange
}: {
    date: string | undefined,
    onDateChange: (value: string | undefined) => void
}) => {
    const [localDate, setLocalDate] = useState<dayjs.Dayjs | null>(null);

    const debouncedDateUpdate = useCallback(
        debounce((value: string | undefined) => {
            onDateChange(value);
        }, 300),
        [onDateChange]
    );

    useEffect(() => {
        setLocalDate(date ? dayjs(date) : null);
    }, [date]);

    const handleDateChange = (newValue: dayjs.Dayjs | null) => {
        setLocalDate(newValue);
        // Only update if we have a valid date
        if (newValue && newValue.isValid()) {
            debouncedDateUpdate(newValue.format('YYYY-MM-DD'));
        } else {
            debouncedDateUpdate(undefined);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Survey Date"
                value={localDate}
                onChange={handleDateChange}
                slotProps={{ 
                    textField: { 
                        variant: 'standard',
                        fullWidth: true,
                        helperText: "When this survey should be conducted"
                    } 
                }}
                sx={{
                    mb: 3,
                    '& .MuiInput-root': {
                        '&:before': {
                            borderColor: 'transparent'
                        }
                    }
                }}
            />
        </LocalizationProvider>
    );
});

SurveyDateField.displayName = 'SurveyDateField';

export default function EditSurvey() {
    const { getAccessTokenSilently } = useAuth0();
    const { surveyId } = useParams();
    const [survey, setSurvey] = useState<Survey>({
        title: '',
        description: '',
        surveyDateUtc: '',
        purpose: '',
        pages: [{ title: 'Page 1', elements: [] }]
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [previewSurvey, setPreviewSurvey] = useState<Survey | null>(null);
    const [aiLoading, setAiLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loadSurveyStructure = async () => {
            if (!surveyId) return;

            try {
                setLoading(true);
                setError(null);

                const apiClient = new FathomClient(await getAccessTokenSilently());
                const { data } = await apiClient.get(
                    `{clientId}/surveys/${surveyId}/structure`
                );
                setSurvey(data);

            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load survey');
            } finally {
                setLoading(false);
            }
        };

        loadSurveyStructure();
    }, [surveyId]);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<{ type: 'page' | 'question', pageIndex: number, questionIndex?: number } | null>(null);
    const [editingQuestionIndex, setEditingQuestionIndex] = useState<number | null>(null);

    const questionTypes: QuestionTypeConfig[] = [
        {
            type: 'open',
            label: 'Open Text',
            icon: <TextFieldsIcon />
        },
        {
            type: 'rating',
            label: 'Rating',
            icon: <StarIcon />,
            initialState: { rangeMin: 1, rangeMax: 5 }
        },
        {
            type: 'multi',
            label: 'Multi Choice',
            icon: <RadioIcon />,
            initialState: { choices: [''] }
        },
        {
            type: 'nps',
            label: 'NPS Score',
            icon: <ScoreIcon />,
            initialState: { rangeMin: 0, rangeMax: 10 }
        },
        {
            type: 'opinion',
            label: 'Opinion Scale',
            icon: <OpinionIcon />
        },
        {
            type: 'ranking',
            label: 'Ranking',
            icon: <RankingIcon />,
            initialState: { choices: [''] }
        },
    ];

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event: DragEndEvent): void => {
        const { active, over } = event;

        if (!over || active.id === over.id) {
            return;
        }

        const activeId = String(active.id);
        const overId = String(over.id);

        const [activePageIndex, activeQuestionIndex] = activeId.split('-').map(Number);
        const [overPageIndex, overQuestionIndex] = overId.split('-').map(Number);

        setSurvey(prevSurvey => {
            const newSurvey = { ...prevSurvey };
            newSurvey.pages = [...newSurvey.pages];

            // Getting the question to be moved
            const sourcePageElements = [...newSurvey.pages[activePageIndex].elements];
            const [movedQuestion] = sourcePageElements.splice(activeQuestionIndex, 1);

            // Update source page without the moved question
            newSurvey.pages[activePageIndex] = {
                ...newSurvey.pages[activePageIndex],
                elements: sourcePageElements
            };

            // If moving to a different page
            if (activePageIndex !== overPageIndex) {
                // Insert into the destination page
                const destPageElements = [...newSurvey.pages[overPageIndex].elements];
                destPageElements.splice(overQuestionIndex, 0, movedQuestion);

                newSurvey.pages[overPageIndex] = {
                    ...newSurvey.pages[overPageIndex],
                    elements: destPageElements
                };
            } else {
                // Same page - just reorder
                sourcePageElements.splice(overQuestionIndex, 0, movedQuestion);
                newSurvey.pages[activePageIndex].elements = sourcePageElements;
            }

            return newSurvey;
        });
    };

    const handleAddPageBreak = (afterPageIndex: number) => {
        const newSurvey = { ...survey };
        const newPage = {
            title: `Page ${newSurvey.pages.length + 1}`,
            elements: []
        };
        newSurvey.pages.splice(afterPageIndex + 1, 0, newPage);
        setSurvey(newSurvey);
    };

    const handlePageTitleChange = useCallback((pageIndex: number, newTitle: string) => {
        setSurvey(prevSurvey => {
            // Create a new object only for the parts of the state that are changing
            const newPages = [...prevSurvey.pages];
            const targetPage = { ...newPages[pageIndex], title: newTitle };
            newPages[pageIndex] = targetPage;

            return {
                ...prevSurvey,
                pages: newPages
            };
        });
    }, []);

    const handleDeleteClick = (type: 'page' | 'question', pageIndex: number, questionIndex?: number) => {
        if (type === 'page' &&
            survey.pages[pageIndex].elements.length === 0 &&
            (!survey.pages[pageIndex].title || survey.pages[pageIndex].title === `Page ${pageIndex + 1}`)) {

            const newSurvey = { ...survey };
            if (newSurvey.pages.length === 1) return;
            newSurvey.pages = newSurvey.pages.filter((_, index) => index !== pageIndex);
            setSurvey(newSurvey);
            return;
        }

        if (type === 'question' && questionIndex !== undefined) {
            const question = survey.pages[pageIndex].elements[questionIndex];
            const isUnmodified = (
                !question.title &&
                !question.required &&
                (question.type === 'open' ||
                    (question.type === 'multi' && (!question.choices || question.choices.every(c => !c))) ||
                    (question.type === 'ranking' && (!question.choices || question.choices.every(c => !c)))
                )
            );

            if (isUnmodified) {
                const newSurvey = { ...survey };
                newSurvey.pages[pageIndex].elements =
                    newSurvey.pages[pageIndex].elements.filter((_, index) => index !== questionIndex);
                setSurvey(newSurvey);
                return;
            }
        }

        setDeleteTarget({ type, pageIndex, questionIndex });
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (!deleteTarget) return;

        const newSurvey = { ...survey };
        if (deleteTarget.type === 'page') {
            if (newSurvey.pages.length === 1) return;
            newSurvey.pages = newSurvey.pages.filter((_, index) => index !== deleteTarget.pageIndex);
        } else if (deleteTarget.type === 'question' && deleteTarget.questionIndex !== undefined) {
            newSurvey.pages[deleteTarget.pageIndex].elements =
                newSurvey.pages[deleteTarget.pageIndex].elements.filter((_, index) => index !== deleteTarget.questionIndex);
        }

        setSurvey(newSurvey);
        setDeleteConfirmOpen(false);
        setDeleteTarget(null);
    };

    const handleAddNewQuestion = (pageIndex: number) => {
        const newSurvey = { ...survey };
        newSurvey.pages[pageIndex].elements.push({
            type: 'open',
            title: '',
            required: false
        });
        setSurvey(newSurvey);
        setEditingQuestionIndex(newSurvey.pages[pageIndex].elements.length - 1);
    };

    const handleQuestionChange = React.useCallback((pageIndex: number, questionIndex: number, updates: Partial<SurveyQuestion>) => {
        setSurvey(prevSurvey => {
            // Create a new object only for the parts of the state that are changing
            const newPages = [...prevSurvey.pages];
            const targetPage = { ...newPages[pageIndex] };
            newPages[pageIndex] = targetPage;

            const targetElements = [...targetPage.elements];
            targetPage.elements = targetElements;

            targetElements[questionIndex] = {
                ...targetElements[questionIndex],
                ...updates
            };

            return {
                ...prevSurvey,
                pages: newPages
            };
        });
    }, []);

    const handleQuestionTypeChange = React.useCallback((pageIndex: number, questionIndex: number, newType: string) => {
        const questionConfig = questionTypes.find(qt => qt.type === newType);
        setSurvey(prevSurvey => {
            // Create a new object only for the parts of the state that are changing
            const newPages = [...prevSurvey.pages];
            const targetPage = { ...newPages[pageIndex] };
            newPages[pageIndex] = targetPage;

            const targetElements = [...targetPage.elements];
            targetPage.elements = targetElements;

            targetElements[questionIndex] = {
                ...targetElements[questionIndex],
                type: newType,
                ...questionConfig?.initialState
            };

            return {
                ...prevSurvey,
                pages: newPages
            };
        });
    }, [questionTypes]);

    const handleDuplicateQuestion = React.useCallback((pageIndex: number, questionIndex: number) => {
        setSurvey(prevSurvey => {
            const newSurvey = { ...prevSurvey };
            newSurvey.pages = [...newSurvey.pages];
            newSurvey.pages[pageIndex] = { ...newSurvey.pages[pageIndex] };
            newSurvey.pages[pageIndex].elements = [...newSurvey.pages[pageIndex].elements];
            const questionToDuplicate = { ...newSurvey.pages[pageIndex].elements[questionIndex] };
            questionToDuplicate.title = `${questionToDuplicate.title} (Copy)`;
            newSurvey.pages[pageIndex].elements.splice(questionIndex + 1, 0, questionToDuplicate);
            return newSurvey;
        });
    }, []);

    const handleAIBuilder = async () => {
        try {
            setAiLoading(true);
            setError(null);

            // Store current survey state before AI changes
            setPreviewSurvey({ ...survey });

            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.post(
                `{clientId}/surveys/builder`,
                {
                    title: survey.title,
                    description: survey.description,
                    purpose: survey.purpose
                }
            );
            data.title = survey.title;
            data.description = survey.description;
            data.purpose = survey.purpose;
            setSurvey(data);
        } catch (err: any) {
            console.log(err);
            setError(err.response.data.ErrorMessage);
            setSurvey({ ...survey });
            setPreviewSurvey(null);
        } finally {
            setAiLoading(false);
        }
    };

    const handleDiscardAIChanges = () => {
        if (!previewSurvey) return;
        setSurvey({ ...previewSurvey });
        setPreviewSurvey(null);
        setError(null);
    };

    const handleSaveAIChanges = () => {
        setPreviewSurvey(null);
    };

    const navigateToHome = () => {
        navigate('/surveys');
    };

    const handlePublish = async () => {


        try {
            setSaving(true);
            setError(null);

            const apiClient = new FathomClient(await getAccessTokenSilently());
            if (!surveyId) {
                const { data } = await apiClient.post(
                    `{clientId}/surveys`,
                    {
                        Title: survey.title,
                        Description: survey.description,
                        SurveyDateUtc: survey.surveyDateUtc
                    }
                );

                await apiClient.put(
                    `{clientId}/surveys/${data.id}/structure`,
                    survey
                );

                navigate(`/surveys/${data.id}/overview`);
            }
            else {
                await apiClient.put(
                    `{clientId}/surveys/${surveyId}/structure`,
                    survey
                );

                navigate(`/surveys/${surveyId}/overview`);
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to save survey structure');
        } finally {
            setSaving(false);
        }
    };

    const canPublish = survey.title &&
        survey.pages.some(page =>
            page.elements.length > 0 &&
            page.elements.some(element => element.title.trim() !== '')
        );

    if (loading) {
        return (
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress />
            </Container>
        );
    }


    return (
        <Container maxWidth="md" sx={{
            py: 4,
            touchAction: 'pan-x pan-y' // Allow scrolling but not interfere with drag
        }}>
            {aiLoading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1200,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2
                    }}
                >
                    <CircularProgress size={40} />
                    <Typography variant="h6" color="text.secondary">
                        Fathom is generating your survey...
                    </Typography>
                </Box>
            )}

            <BreadCrumb buttonFunc={navigateToHome} buttonText={"Surveys"} pageText={"Edit Survey"} /><br />
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Tooltip title={!survey.purpose ? "Please fill out the survey purpose to use the AI Builder" : ""}>
                        <span>
                            <Button
                                variant="outlined"
                                startIcon={<AIIcon />}
                                onClick={handleAIBuilder}
                                disabled={aiLoading || !survey.purpose}
                            >
                                {aiLoading ? 'Generating...' : 'AI Builder'}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {previewSurvey && (
                        <>
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<CloseIcon />}
                                onClick={handleDiscardAIChanges}
                            >
                                Discard Changes
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleSaveAIChanges}
                            >
                                Save AI Changes
                            </Button>
                        </>
                    )}
                    <Tooltip title={!canPublish ?
                        "Survey must have a name and at least one question with a title" :
                        ""}
                    >
                        <span>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handlePublish}
                                disabled={!canPublish || saving || previewSurvey !== null}
                            >
                                {saving ? 'Saving...' : 'Save'}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <Box>
                <Paper
                    elevation={0}
                    sx={{
                        p: 3,
                        mb: 2,
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1
                    }}
                >
                    <SurveyTitleField
                        title={survey.title}
                        onTitleChange={(value) => setSurvey(prev => ({ ...prev, title: value }))}
                    />

                    <SurveyDescriptionField
                        description={survey.description}
                        onDescriptionChange={(value) => setSurvey(prev => ({ ...prev, description: value }))}
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
                        <SurveyDateField
                            date={survey.surveyDateUtc}
                            onDateChange={(value) => setSurvey(prev => ({ ...prev, surveyDateUtc: value }))}
                        />
                    </Box>

                    <Divider sx={{ mb: 3 }} />
                    {error && (
                        <Container sx={{ py: 4 }}>
                            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
                                {error}
                            </Alert>
                        </Container>)
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500 }}>
                            Survey Purpose
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            Internal notes about the purpose of this survey (not visible to respondents)
                        </Typography>
                        <SurveyPurposeField
                            purpose={survey.purpose || ''}
                            onPurposeChange={(value) => setSurvey(prev => ({ ...prev, purpose: value }))}
                        />
                    </Box>
                </Paper>

                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    autoScroll={{
                        threshold: { x: 0.2, y: 0.2 },
                        acceleration: 15,
                    }}
                    measuring={{
                        droppable: {
                            strategy: MeasuringStrategy.Always
                        }
                    }}
                >
                    <SortableContext
                        items={survey.pages.flatMap((page, pageIndex) =>
                            page.elements.map((_, elementIndex) => `${pageIndex}-${elementIndex}`)
                        )}
                        strategy={verticalListSortingStrategy}
                    >
                        {survey.pages.map((page, pageIndex) => (
                            <Box key={pageIndex}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 3,
                                        mb: 3,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        bgcolor: 'grey.50'
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 2
                                    }}>
                                        <PageTitleField
                                            title={page.title}
                                            pageIndex={pageIndex}
                                            onTitleChange={handlePageTitleChange}
                                        />
                                        <IconButton
                                            color="error"
                                            disabled={survey.pages.length === 1}
                                            onClick={() => handleDeleteClick('page', pageIndex)}
                                            size="small"
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>

                                    <PageDescriptionField
                                        description={page.description}
                                        pageIndex={pageIndex}
                                        survey={survey}
                                        setSurvey={setSurvey}
                                    />
                                </Paper>

                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '100%'
                                    }}
                                >
                                    {page.elements.map((element, elementIndex) => (
                                        <SortableQuestion
                                            key={`${pageIndex}-${elementIndex}`}
                                            id={`${pageIndex}-${elementIndex}`}
                                            pageIndex={pageIndex}
                                            questionIndex={elementIndex}
                                            question={element}
                                            questionTypes={questionTypes}
                                            onQuestionChange={handleQuestionChange}
                                            onQuestionTypeChange={handleQuestionTypeChange}
                                            onDuplicateQuestion={handleDuplicateQuestion}
                                            onDeleteClick={handleDeleteClick}
                                        />
                                    ))}
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddNewQuestion(pageIndex)}
                                        variant="text"
                                    >
                                        Add Question
                                    </Button>
                                    <Button
                                        startIcon={<PageBreakIcon />}
                                        onClick={() => handleAddPageBreak(pageIndex)}
                                        variant="text"
                                    >
                                        Add Page
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </SortableContext>
                </DndContext>
            </Box>

            <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
            >
                <DialogTitle>
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {deleteTarget?.type === 'page'
                            ? 'Are you sure you want to delete this page and all its questions?'
                            : 'Are you sure you want to delete this question?'}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}