import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { Paper, Container, Grid, Typography, LinearProgress, IconButton, Menu, MenuItem, Tooltip, Alert } from "@mui/material";
import { PagedResult } from "app/types";
import { useEffect, useState } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import Moment from "moment";
import "./reportsList.css";
import SpreadSheetParse from "./create/dataupload/spreadsheetparse";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from "react";
import ConfirmDialog from "@einhorn/confirmDialog";
import ReportEdit from "./create/edit";
import LoadingBar from "@einhorn/loadingBar";
export default function ReportList({ programId, setFirstReport, currentReportId, setApiError }: any) {

    const { getAccessTokenSilently } = useAuth0();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const [reports, setReports] = useState({
        data: [],
        paging: {
            length: 0,
            pageSize: 0,
            page: 0,
            total: 0
        }
    } as PagedResult<any>);
    const [loading, setLoading] = useState(true);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [reportId, setReportId] = useState(currentReportId);
    const [reportEditId, setEditReportId] = useState("");


    const loadReports = async (searchTerm: string, page: number, triggerIdUpdate: boolean = true) => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports?search=` + searchTerm + "&page=" + page);
        setReports(data);

        // Modified condition to properly set initial report
        if (data.data.length > 0 && !currentReportId && triggerIdUpdate) {
            setFirstReport(data.data[0].id, false, "reports");
            setReportId(data.data[0].id);
        }

        // Check for any syncing reports and continue polling if found
        if (data.data.some((report: any) => syncing(report))) {
            setTimeout(() => {
                loadReports(searchTerm, page, false);
            }, 5000);
        }

        setLoading(false);
    }

    const successSync = (report: any) => {
        return report.status == "Complete";
    }

    const syncing = (report: any) => {
        var isSyncing = report.status !== "Complete" && report.status.indexOf("Error") === -1;
        return isSyncing;
    }

    useEffect(() => {
        const fetchData = async () => {
            await loadReports("", 1);
        };
        setLoading(true);
        fetchData()
            .catch(console.error);
    }, [programId]);

    const statusMap = (status: string) => {
        if (status == "QuestionAnalysis") {
            return "Analysing questions"
        }

        if (status == "OutcomeAnalysis") {
            return "Analysing outcome"
        }

        if (status == "ReportGen") {
            return "Generating final report"
        }

        if (status == "BatchError") {
            return "Bacthing Error"
        }

        if (status == "IndexError") {
            return "Indexing error"
        }

        if (status == "QuestionError") {
            return "Question Analysis Error"
        }

        if (status == "OutcomeError") {
            return "Outcome Analysis Error"
        }

        if (status == "ModuleError") {
            return "Module Analysis Error"
        }

        if (status == "ReportError") {
            return "Report Generation Error"
        }

        return status;
    }

    const viewReport = (reportId: string) => {
        setFirstReport(reportId, true, "reports")
        setReportId(reportId);

        // Update URL without engagement ID
        let base = window.location.href;
        var index = base.indexOf("?engagementId=");
        var updated = base.slice(0, (index < 0 ? base.length : index));
        window.history.pushState("", "", updated);
    }

    const getClassName = (report: any) => {
        if (report.id == reportId) {
            return "reportBoxActive"
        }

        if (report.status == "Complete") {
            return "reportBox"
        }

        if (report.status.indexOf("Error") > -1) {
            return "reportBoxError"
        }

        return "reportBoxInProgress";
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        setEditReportId(id)
        setAnchorEl(event.currentTarget);
    };
    const handleEditClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const deleteReport = async () => {
        if (reportEditId !== "") {
            setLoading(true);
            try {
                const apiClient = new FathomClient(await getAccessTokenSilently());
                await apiClient.delete(
                    "{clientId}/programs/" + programId + "/reports/" + reportEditId
                );

                // Close dialogs
                setConfirmDeleteOpen(false);
                setAnchorEl(null);

                // If we're deleting the currently selected report, clear the reportId
                if (reportId === reportEditId) {
                    setReportId("");
                }

                // Reload reports and select first one if needed
                const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports?search=&page=1`);
                setReports(data);

                // If we deleted the current report, select the first one
                if (reportId === reportEditId && data.data.length > 0) {
                    setFirstReport(data.data[0].id, false, "reports");
                    setReportId(data.data[0].id);
                }
            } catch (error) {
                console.error("Error deleting report:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const getReports = () => {
        return reports.data.map((x: any) => (
            <Paper sx={{ marginBottom: "25px" }} className={(getClassName(x))} key={x.id} >
                <Container onClick={() => viewReport(x.id)}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid sm={2} item>
                            <DescriptionIcon sx={{ mt: "-20px" }} fontSize="large" />
                        </Grid>
                        <Grid sm={8} item sx={{ mt: "-18px" }}>
                            <Tooltip title={x.title}>
                                <Typography className="reportTitle">{x.title}</Typography>
                            </Tooltip>
                            {successSync(x) && <Typography sx={{ fontSize: "12px" }}>{Moment(x.reportDateUtc).format("D/MM/YY")}</Typography>}
                            {syncing(x) && <Typography sx={{ fontSize: "12px" }}>{statusMap(x.status)}</Typography>}
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Container>
                <IconButton aria-label="open" sx={{ mt: "-150px", ml: "230px" }} onClick={(ev: any) => handleEditClick(ev, x.id)}>
                    <MoreHorizIcon fontSize="small" />
                </IconButton>

            </Paper>
        ));
    };

    return (<Container>
        <ConfirmDialog
            title="Delete report"
            content="Are you sure you want to delete this report?"
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            confirmAction={deleteReport}
        />

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleEditClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            disableScrollLock={true}
        >
            <MenuItem onClick={() => {
                setEditDrawerOpen(true)
                setAnchorEl(null);
            }
            }>Edit</MenuItem>
            <MenuItem onClick={() => setConfirmDeleteOpen(true)}>Delete</MenuItem>
        </Menu>

        {loading && reports.paging.length == 0 &&
            <LoadingBar />
        }

        {reports.paging.length == 0 && !loading && (
            <Container sx={{ marginBottom: "25px", ml: "-25px" }}>
                <br />
                <Typography variant="h6">
                    No reports found
                </Typography>
                <br />
            </Container>
        )}
        {getReports()}


        <SpreadSheetParse loadReports={loadReports} setApiError={setApiError} />


        {reportEditId && <ReportEdit
            loadReports={loadReports}
            editDrawerOpen={editDrawerOpen}
            setEditDrawerOpen={setEditDrawerOpen}
            reportId={reportEditId}
            programId={programId} />}
        <br />
    </Container>);

}   