import { LinearProgress, Paper } from "@mui/material";

export default function LoadingBar() {
    return (
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px", border: 'none' }} >
            <LinearProgress color="secondary" />
            <br />
            <br />
            <br />
        </Paper>
    )
}