import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleAuth = async () => {
      // Only redirect if not loading and not authenticated
      if (!isLoading && !isAuthenticated && !searchParams.get("code")) {
        await loginWithRedirect();
      } else if (isAuthenticated) {
        navigate("/programs/");
      }
    };

    handleAuth();
  }, [isLoading, isAuthenticated]);

  // Show loading or empty state while authentication is in progress
  return (
    <div>
      {isLoading && <div>Loading...</div>}
    </div>
  );
};

export default LoginPage;
