import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Grid
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Title from "@einhorn/title";

interface ProgramSetupProps {
  value: any;
  setValue: (value: any) => void;
}

const ProgramSetup: React.FC<ProgramSetupProps> = ({ value, setValue }) => {
  const handleChange = (field: string, newValue: any) => {
    setValue({
      ...value,
      [field]: newValue
    });
  };

  const handleStartDateChange = (newValue: any) => {
    // Ensure we're working with a valid dayjs object
    const validDate = newValue && dayjs(newValue).isValid() ? dayjs(newValue) : null;
    handleChange('startDateUtc', validDate);
  };

  const handleEndDateChange = (newValue: any) => {
    // Ensure we're working with a valid dayjs object
    const validDate = newValue && dayjs(newValue).isValid() ? dayjs(newValue) : null;
    handleChange('endDateUtc', validDate);
  };

  // Ensure dates are valid dayjs objects
  const startDate = value.startDateUtc && dayjs(value.startDateUtc).isValid() 
    ? dayjs(value.startDateUtc) 
    : null;
    
  const endDate = value.endDateUtc && dayjs(value.endDateUtc).isValid() 
    ? dayjs(value.endDateUtc) 
    : null;

  return (
    <Box>
      <Title>Basic Program Information</Title>
      <Typography variant="subtitle1" gutterBottom>
        Configure the basic details of your program to help participants and administrators understand its purpose.
      </Typography>
      
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Program Name"
            value={value.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
            variant="standard"
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={value.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
            variant="standard"
            helperText="Provide a short description of the program's goals and content"
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              slotProps={{ textField: { variant: 'standard', fullWidth: true, required: true } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              slotProps={{ 
                textField: { 
                  variant: 'standard', 
                  fullWidth: true,
                  helperText: "This will automatically adjust touchpoints timeline",
                  required: true
                } 
              }}
              minDate={startDate ? startDate.add(1, 'day') : undefined}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgramSetup;