import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import { InsightChartData } from "../insightTypes";

// Initialize modules
HC_more(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);

export default function OpinionInsightsChart({ insight }: any) {

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getOpinionChartOptions(insight)} />

  )
}


function getOpinionChartOptions(insight: InsightChartData): Highcharts.Options {

  const { data, title, description } = insight;

  return {
    colors: ['#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '#5090B7', '#39C1C1', '#3AD7B0', '#8266E1', '#003f5c', '#'],
    title: {
      text: "",
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: null
      },
      labels: { enabled: false },
      height: 2,
    },
    xAxis: {
      categories: data.categories,
      reversedStacks: false,
      labels: {
        style: {
          width: 110,
          textOverflow: "ellipsis",
        }
      },
    },
    credits: {
      enabled: false
    },
    
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: '{point.y:,.1f} %'
        },
      },

      bar: {
        groupPadding: 2,
        pointPadding: 0.6,
      }
    },
    tooltip: {
      valueSuffix: '%',
  },
    series: GetOrderedOpinionKeys(data.series).map((key: string) => {
      return {
        name: key,
        type: "bar",
        data: data.series[key],
      };
    }),
  };
}

function GetOrderedOpinionKeys(multi: Record<string, number[]>) {
  const order = [
    "not applicable",
    "not confident at all",
    "not confident",
    "a little confident",
    "somewhat confident",
    "confident",
    "quite confident",
    "very confident",
    "strongly disagree",
    "disagree",
    "neutral",
    "neither agree nor disagree",
    "agree",
    "strongly agree",
    "never",
    "rarely",
    "sometimes",
    "often",
    "always",
    "very dissatisfied",
    "dissatisfied",
    "neutral",
    "neither satisfied nor dissatisfied",
    "satisfied",
    "very satisfied",
    "not improved",
    "slightly improved",
    "noticeably improved",
    "significantly improved",
    "transformed"   
  ];
  return Object.keys(multi).filter((z) => z !== "").sort(function (a, b) {
    return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase());
  });
}