import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Typography from '@mui/material/Typography';
import QuestionItem from './questionItem';
import SortableQuestionItem from './sortableQuestionItem';
import { Question, Structure } from '../types';
import EasyEdit, {Types} from 'react-easy-edit';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import "./questionHeader.css";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

type BoardSectionProps = {
  id: string;
  title: string;
  questions: Question[];
  setFormState: any;
  formState: any;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  isFirst?: boolean;
  isLast?: boolean;
};

const QuestionHeader = ({ 
  id, 
  title, 
  questions, 
  setFormState, 
  formState,
  onMoveUp,
  onMoveDown,
  isFirst,
  isLast
}: BoardSectionProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSpecialHeader = id === "New question" || id === "Unmapped";
  
  const { setNodeRef: setDroppableRef } = useDroppable({
    id: id,
  });

  const save = (value:string) => {
    let newMap = formState.posMap as Map<string, string>;
    newMap.forEach((currentHeader: string, key: string) => {
      if(currentHeader == title){
        newMap.set(key, value);
      }
    });

    let struct = formState.surveyStructure?.structure as Structure[];
    for (let index = 0; index < struct.length; index++) {
      const element = struct[index];
      if(element.questionHeader == title){
        element.questionHeader = value;
      }
      struct[index] = element;
    }

    formState.surveyStructure.structure = struct;

    setFormState({
      unmappedQuestions: formState.unmappedQuestions,
      posMap: newMap,
      surveyStructure: formState.surveyStructure
    });
  }

  return (
    <Box 
      className="questionBox"
      sx={{ 
        padding: 2,
        position: 'relative',
        '&:hover .move-buttons': {
          opacity: 1
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ 
        position: 'absolute',
        right: -5,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: isHovered && !isSpecialHeader ? 1 : 0,
        transition: 'opacity 0.2s',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
      }} className="move-buttons">
        <IconButton 
          onClick={onMoveUp}
          disabled={isFirst}
          sx={{
            backgroundColor: 'transparent',

          }}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton 
          onClick={onMoveDown}
          disabled={isLast}
          sx={{
            backgroundColor: 'transparent',

          }}
        >
          <ArrowDownward />
        </IconButton>
      </div>

      <Typography sx={{ mb: 2 }}>
        {(title == "Unmapped" || title == "New question") ? title :
          <div style={{ cursor: 'text' }}>
            <EasyEdit
              type={Types.TEXT}
              onSave={save}
              saveButtonLabel={<DoneIcon/>}
              cancelButtonLabel={<CloseIcon/>}
              value={title}     
            />
          </div>
        }
      </Typography>
      <SortableContext
        id={id}
        items={questions}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setDroppableRef} style={{minHeight: '50px', minWidth: '100px'}}>
          <Grid container>
            {questions.map((question) => (
              <Grid item xs={12} key={question.id}>
                <Box sx={{ mb: 2, mr: 2, opacity: question.id == "unmapped" ? 0 : 1 }}>
                  <SortableQuestionItem id={question.id}>
                    <QuestionItem question={question} />
                  </SortableQuestionItem>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </SortableContext>
    </Box>
  );
};

export default QuestionHeader;
