import { Box, CircularProgress, Typography } from "@mui/material";
import NpsInsightsChart from "components/insights/charts/npsInsightsChart";
import OpinionInsightsChart from "components/insights/charts/opinionInsightsChart";
import RatingInsightsChart from "components/insights/charts/ratingInsightsChart";
import OpinionSummaryInsightsChart from "components/insights/charts/opinionSummaryInsightsChart";
import Highcharts from "highcharts";
import { ChartDataPoint } from 'components/insights/insightTypes';
import MultiInsightsChart from "components/insights/charts/multiInsightsChart";

interface GroupedChartProps {
    insightsChart: ChartDataPoint;
    loading: boolean;
    chartQueryData: any;
}

export default function GroupedChart({ insightsChart, loading, chartQueryData }: GroupedChartProps) {
    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <CircularProgress />
            </Box>
        );
    }

    if (!chartQueryData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <Typography color="text.secondary">No data available</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ 
            flex: 1, 
            minHeight: 0,
        }}>
            {insightsChart.filter.type.toLowerCase() === "rating" && (
                <RatingInsightsChart
                    highcharts={Highcharts}
                    insight={chartQueryData}
                />
            )}
            {insightsChart.filter.type.toLowerCase() === "opinion" && (
                <OpinionInsightsChart
                    highcharts={Highcharts}
                    insight={chartQueryData}
                />
            )}
            {insightsChart.filter.type.toLowerCase() === "nps" && (
                <NpsInsightsChart
                    highcharts={Highcharts}
                    insight={chartQueryData}
                />
            )}
            {insightsChart.filter.type.toLowerCase() === "opinionsummary" && (
                <OpinionSummaryInsightsChart
                    highcharts={Highcharts}
                    insight={chartQueryData}
                />
            )}
            {insightsChart.filter.type.toLowerCase() === "multi" && (
                <MultiInsightsChart
                    highcharts={Highcharts}
                    insight={chartQueryData}
                />
            )}


        </Box>
    );
} 