import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import TopWinsTile from "./tiles/topwins";
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import TopReccsTile from "./tiles/topreccs";
import ViewFullReport from "./reportfull";

export default function ViewProgramSummary({ programId }: any) {

    const { getAccessTokenSilently } = useAuth0();
    const [programSummary, setProgramSummary] = useState({} as any);
    const [loading, setLoading] = useState(true);

    const loadProgramSummary = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/summary`);
        setProgramSummary(data);
        setLoading(false);
    }

    useEffect(() => {
        loadProgramSummary()
            .catch(console.error);
    }, []);


    return (
        <Container maxWidth="xl">
            {loading && !programSummary && (
                <Container >
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" minHeight="200px">
                        <CircularProgress />
                        <br />
                    </Box>
                </Container>
            )}
            {!loading && <>
                <br />
                <br />

                {programSummary.status == "NotGenerated" &&
                    <Grid item xs={12}>
                        <Container sx={{ marginBottom: "25px", marginTop: "20px" }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <Typography variant="h6">
                                    Program summary will generate after your first report is uploaded
                                </Typography>
                            </Box>
                        </Container>
                    </Grid>
                }
                {programSummary.status != "NotGenerated" &&
                <>
                    <Container maxWidth="xl">
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <TopWinsTile topWins={programSummary.topWins} />
                            </Grid>
                            <Grid item xs={6}>
                                <TopReccsTile topreccs={programSummary.overallRecommendations} />
                            </Grid>
                        </Grid>
                    </Container >

                    <br />
                    <br />
                    <br />
                    <Container maxWidth="xl"><h5>Outcome analysis</h5></Container>

                    <ViewFullReport programSummary={programSummary} />
                </> 
                }
            </>}
        </Container>
    )
}