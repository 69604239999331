import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Paper,
  Typography,
  IconButton,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';
import FathomClient from "@api/fathomapi";
import ReactMarkdown from "react-markdown";
import ConfirmDialog from '@einhorn/confirmDialog';
import {
  ChatInstance,
  Message,
  ChatHistory,
  FilteredProgramReportQuestions
} from './types';
import remarkGfm from 'remark-gfm'
import Cookies from "universal-cookie";
import LoadingBar from "@einhorn/loadingBar";

const cookies = new Cookies();

const DRAWER_WIDTH = 240;

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  position: 'relative',
  overflow: 'hidden',
  height: '100vh',
  '& .MuiDrawer-paper': {
    position: 'relative'
  }
}));

const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isUser',
})<{ isUser?: boolean }>(({ theme, isUser }) => ({
  padding: theme.spacing(2),
  maxWidth: '70%',
  backgroundColor: isUser ? theme.palette.primary.main : "transparent",
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  alignSelf: isUser ? 'flex-end' : 'flex-center',
  borderRadius: theme.spacing(2),
  margin: theme.spacing(1),
  overflow: 'wrap',
  wordBreak: 'break-word',
}));

const PulsingMessageBubble = styled(MessageBubble)(({ theme }) => ({
  '@keyframes pulse': {
    '0%': {
      opacity: 0.6,
    },
    '50%': {
      opacity: 0.3,
    },
    '100%': {
      opacity: 0.6,
    },
  },
  animation: 'pulse 3s ease-in-out infinite',
}));

const MessagesContainer = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
});

const ChatHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  minHeight: 'fit-content',
  width: '100%',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '& .delete-button': {
    display: 'none',
    padding: 4,
    marginRight: theme.spacing(1),
  },
  '&:hover .delete-button': {
    display: 'block',
  },
}));


export default function ViewChat() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const [chatInstance, setChatInstance] = useState<ChatInstance>({
    chatId: '',
    history: [],
    name: '',
    dataFilter: {
      reportIdFilter: [],
      programIdFilter: [],
      questionIdFilter: [],
      clientId: ''
    }
  });
  const [input, setInput] = useState('');
  const [isNewChatDialogOpen, setIsNewChatDialogOpen] = useState(false);
  const [programData, setProgramData] = useState<FilteredProgramReportQuestions>({ programs: [] });
  const [selectedProgram, setSelectedProgram] = useState<string[]>([]);
  const [selectedReport, setSelectedReport] = useState<string[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [newChatName, setNewChatName] = useState('');
  const [chatHistories, setChatHistories] = useState<ChatHistory[]>([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [suggestTopics, setSuggestTopics] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    fetchChatHistories();
  }, []);

  const fetchFilterOptions = async () => {
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.post(`{clientId}/chat/options`, {});
      setProgramData(response.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const fetchChatHistories = async () => {
    setIsHistoryLoading(true);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.get(`{clientId}/chat`);
      setChatHistories(response.data.data);
    } catch (error) {
      console.error('Error fetching chat histories:', error);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  const loadChatHistory = async (chatId: string) => {
    try {
      setIsLoading(true);
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.get(`{clientId}/chat/${chatId}`);
      setCurrentChatId(chatId);
      setChatInstance(response.data);
    } catch (error) {
      console.error('Error loading chat history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProgramChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const programs = typeof value === 'string' ? [value] : value;
    setSelectedProgram(programs);
    setSelectedReport([]);
    setSelectedQuestions([]);
  };

  const handleReportChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const reports = typeof value === 'string' ? [value] : value;
    setSelectedReport(reports);
    setSelectedQuestions([]);
  };

  const handleQuestionsChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedQuestions(typeof event.target.value === 'string' ? [event.target.value] : event.target.value);
  };

  const handleConfirmNewChat = async () => {
    try {
      setIsSubmitting(true);
      const apiClient = new FathomClient(await getAccessTokenSilently());
      let clientId = cookies.get("x-fathom-clientId")

      const response = await apiClient.post(`{clientId}/chat`, {
        name: newChatName || 'New Chat',
        dataFilter: {
          programIdFilter: selectedProgram,
          reportIdFilter: selectedReport,
          questionIdFilter: selectedQuestions,
          clientId: clientId
        },
        suggestTopics: suggestTopics
      });

      // Clear all filters and reset suggestTopics
      setSelectedProgram([]);
      setSelectedReport([]);
      setSelectedQuestions([]);
      setNewChatName('');
      setSuggestTopics(false);

      // Load the new chat
      await fetchChatHistories();
      await loadChatHistory(response.data.chatId);
      
      setIsNewChatDialogOpen(false);
    } catch (error) {
      console.error('Error creating new chat:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || !currentChatId) return;

    setIsMessageLoading(true);

    const userMessage: Message = {
      userMessage: true,
      message: input,
      timestamp: null,
      status: null
    };

    const pendingMessage: Message = {
      userMessage: false,
      message: "...",
      timestamp: null,
      status: 3
    };

    // Update chat instance immediately with user message and pending response
    setChatInstance(prev => ({
      ...prev,
      history: [...prev.history, userMessage, pendingMessage]
    }));

    setInput('');

    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.post(
        `{clientId}/chat/${currentChatId}/message`,
        { message: input }
      );

      setChatInstance(response.data);
    } catch (error) {
      console.error('Error sending message:', error);
      // Remove pending message and add error message
      setChatInstance(prev => ({
        ...prev,
        history: [...prev.history.filter(m => m.status !== 1), {
          userMessage: false,
          message: "Sorry, there was an error processing your message.",
          timestamp: null,
          status: null
        }]
      }));
    } finally {
      setIsMessageLoading(false);
    }
  };

  const handleDeleteChat = async () => {
    if (!chatToDelete) return;

    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      await apiClient.delete(`{clientId}/chat/${chatToDelete}`);

      if (currentChatId === chatToDelete) {
        setCurrentChatId(null);
        setChatInstance({
          chatId: '',
          history: [],
          name: '',
          dataFilter: {
            reportIdFilter: [],
            programIdFilter: [],
            questionIdFilter: [],
            clientId: ''
          }
        });
      }

      await fetchChatHistories();
      setConfirmDeleteOpen(false);
      setChatToDelete(null);
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  return (
    <ChatContainer>
      <Drawer
        variant="permanent"
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          position: 'relative',
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            position: 'relative',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box sx={{ 
          p: 2, 
          borderBottom: 1, 
          borderColor: 'divider',
          backgroundColor: 'background.paper',
        }}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            onClick={() => setIsNewChatDialogOpen(true)}
          >
            New Chat
          </Button>
        </Box>
        <List sx={{ flex: 1, overflow: 'auto' }}>
          {isHistoryLoading ? (
            <LoadingBar />
          ) : (
            chatHistories.map(chat => (
              <StyledListItem
                key={chat.chatId}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setChatToDelete(chat.chatId);
                      setConfirmDeleteOpen(true);
                    }}
                    className="delete-button"
                    size="small"
                    sx={{
                      borderRadius: '50%',
                      width: 28,
                      height: 28,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemButton
                  selected={currentChatId === chat.chatId}
                  onClick={() => loadChatHistory(chat.chatId)}
                >
                  <ChatBubbleOutlineIcon sx={{ mr: 1 }} />
                  <Box sx={{ overflow: 'hidden' }}>
                    <Typography noWrap variant="body2">
                      {chat.name}
                    </Typography>
                  </Box>
                </ListItemButton>
              </StyledListItem>
            ))
          )}
        </List>
      </Drawer>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? 'none' : 'auto'
        }}
      >
        {currentChatId ? (
          <>
            <ChatHeader>
              <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                {chatInstance.name}
              </Typography>
              <Box sx={{
                display: 'flex',
                gap: 1,
                flexWrap: 'wrap',
                '& .MuiTypography-root': {
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap'
                }
              }}>
                {chatInstance.dataFilter.reportIdFilter.length > 0 && (
                  <Typography variant="body2" color="text.secondary">
                    Reports: {chatInstance.dataFilter.reportIdFilter.join(', ')}
                  </Typography>
                )}
                {chatInstance.dataFilter.programIdFilter.length > 0 && (
                  <Typography variant="body2" color="text.secondary">
                    Programs: {chatInstance.dataFilter.programIdFilter.join(', ')}
                  </Typography>
                )}
                {chatInstance.dataFilter.questionIdFilter.length > 0 && (
                  <Typography variant="body2" color="text.secondary">
                    Questions: {chatInstance.dataFilter.questionIdFilter.join(', ')}
                  </Typography>
                )}
              </Box>
            </ChatHeader>
            <MessagesContainer>
              {chatInstance.history.map((message, index) => (
                message.status === 3 ? (
                  <PulsingMessageBubble
                    key={index}
                    isUser={message.userMessage}
                    elevation={1}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {isMessageLoading ? (
                        <LoadingBar />
                      ) : (
                        <CircularProgress size={16} />
                      )}
                      <Typography>Thinking...</Typography>
                    </Box>
                  </PulsingMessageBubble>
                ) : (
                  <MessageBubble
                    key={index}
                    isUser={message.userMessage}
                    elevation={1}
                  >
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.message}</ReactMarkdown>
                  </MessageBubble>
                )
              ))}
              <div ref={messagesEndRef} />
            </MessagesContainer>

            <Box
              component="form"
              onSubmit={sendMessage}
              sx={{
                p: 2,
                borderTop: 1,
                borderColor: 'divider',
                display: 'flex',
                gap: 2,
              }}
            >
              <TextField
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }}
                placeholder="Type your message..."
                disabled={isLoading}
                variant="outlined"
                size="small"
                multiline
                maxRows={4}
              />
              <IconButton
                type="submit"
                disabled={isLoading}
                color="primary"
                sx={{ p: '10px' }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              color: 'text.secondary'
            }}
          >
            <Typography variant="h6">
              Select a chat to start messaging
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog
        open={isNewChatDialogOpen}
        onClose={() => chatHistories.length > 0 && setIsNewChatDialogOpen(false)}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown={chatHistories.length === 0}
      >
        <DialogTitle>Create New Chat</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Chat Name"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              fullWidth
              autoFocus
              margin="dense"
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Programs</InputLabel>
              <Select
                multiple
                value={selectedProgram}
                onChange={handleProgramChange}
                label="Programs"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((programId) => {
                      const program = programData.programs.find(p => p.programId === programId);
                      return program ? (
                        <Chip 
                          key={programId}
                          label={program.programTitle} 
                          color="default"
                          size="small"
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {programData.programs.map((program) => (
                  <MenuItem key={program.programId} value={program.programId}>
                    {program.programTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense" disabled={selectedProgram.length === 0}>
              <InputLabel>Reports</InputLabel>
              <Select
                multiple
                value={selectedReport}
                onChange={handleReportChange}
                label="Reports"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((reportId) => {
                      const report = selectedProgram.flatMap(progId =>
                        programData.programs
                          .find(p => p.programId === progId)
                          ?.reports || []
                      ).find(r => r.reportId === reportId);
                      return report ? (
                        <Chip 
                          key={reportId}
                          label={report.title} 
                          color="secondary"
                          size="small"
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {selectedProgram.flatMap(progId =>
                  programData.programs
                    .find(p => p.programId === progId)
                    ?.reports || []
                ).map((report) => (
                  <MenuItem key={report.reportId} value={report.reportId}>
                    {report.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={suggestTopics}
                      onChange={(e) => setSuggestTopics(e.target.checked)}
                      disabled={isSubmitting}
                    />
                  }
                  label="Suggest topics based on selected data"
                />
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setIsNewChatDialogOpen(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmNewChat}
            variant="contained"
            disabled={!newChatName.trim() || isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : undefined}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title="Delete Chat"
        content="Are you sure you want to delete this chat? This action cannot be undone."
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        confirmAction={handleDeleteChat}
      />
    </ChatContainer>
  );
};
