import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Title from "@einhorn/title";

// Types for TypeScript
interface Touchpoint {
  id: string;
  name: string;
  date: any; // dayjs date object
  stage: 'pre' | 'mid' | 'end' | 'post' | 'custom';
  description?: string; // Optional description field
}

interface TouchpointsTimelineProps {
  value: any;
  setValue: (value: any) => void;
}

// Create a context to pass down the touchpoint count
const TouchpointsCountContext = React.createContext<number>(0);

// Touchpoint component
interface TouchpointItemProps {
  touchpoint: Touchpoint;
  index: number;
  handleTouchpointChange: (index: number, field: keyof Touchpoint, newValue: any) => void;
  removeTouchpoint: (index: number) => void;
}

function TouchpointItem({ 
  touchpoint, 
  index, 
  handleTouchpointChange, 
  removeTouchpoint, 
}: TouchpointItemProps & { totalTouchpoints?: number }) {
  // Determine if this touchpoint should be positioned on the left or right
  const isLeft = index % 2 === 0;
  const [hover, setHover] = React.useState(false);
  const touchpointsCount = React.useContext(TouchpointsCountContext);
  const canDelete = touchpointsCount > 1;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: isLeft ? 'flex-start' : 'flex-end',
        position: 'relative',
        mb: 4,
      }}
    >
      <Paper 
        elevation={2} 
        sx={{ 
          p: 2,
          width: '48%',
          borderLeft: !isLeft ? '4px solid' : 'none',
          borderRight: isLeft ? '4px solid' : 'none',
          borderColor: theme => {
            switch(touchpoint.stage) {
              case 'pre': return theme.palette.info.main;
              case 'mid': return theme.palette.warning.main;
              case 'end': return theme.palette.success.main;
              case 'post': return theme.palette.secondary.main;
              default: return theme.palette.primary.main;
            }
          },
          marginLeft: isLeft ? '4px' : 'none',
          marginRight: isLeft ? 'none' : '4px',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            [isLeft ? 'right' : 'left']: -20,
            width: '20px',
            height: '4px',
            backgroundColor: theme => {
              switch(touchpoint.stage) {
                case 'pre': return theme.palette.info.main;
                case 'mid': return theme.palette.warning.main;
                case 'end': return theme.palette.success.main;
                case 'post': return theme.palette.secondary.main;
                default: return theme.palette.primary.main;
              }
            },
            transform: 'translateY(-50%)',
          }
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Grid container spacing={2} 
          sx={{ 
            marginLeft: isLeft ? 'none' : '2px',
            marginRight: isLeft ? '2px' : 'none',
          }}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              label="Touchpoint Name"
              value={touchpoint.name}
              onChange={(e) => handleTouchpointChange(index, 'name', e.target.value)}
              variant="standard"
            />
          </Grid>
          <Grid item xs={2}>
            {canDelete && (
              <IconButton 
                color="error"
                onClick={() => removeTouchpoint(index)}
                sx={{ 
                  float: 'right',
                  opacity: hover ? 1 : 0,
                  transition: 'opacity 0.2s ease-in-out'
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={touchpoint.date}
                onChange={(newDate) => handleTouchpointChange(index, 'date', newDate)}
                format="DD/MM/YYYY"
                slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              value={touchpoint.description || ''}
              onChange={(e) => handleTouchpointChange(index, 'description', e.target.value)}
              variant="standard"
              multiline
              rows={2}
              placeholder="Add details about this touchpoint..."
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              {touchpoint.stage === 'pre' && 'Pre-program assessment'}
              {touchpoint.stage === 'mid' && 'Mid-program check-in'}
              {touchpoint.stage === 'end' && 'Program completion'}
              {touchpoint.stage === 'post' && 'Post-program follow-up'}
              {touchpoint.stage === 'custom' && 'Custom touchpoint'}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default function TouchpointsTimeline({
  value,
  setValue,
}: {
  value: any;
  setValue: any;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTouchpoint, setNewTouchpoint] = useState({
    name: "",
    date: dayjs(),
    stage: "custom",
    description: ""
  });

  const handleTouchpointChange = (index: number, field: keyof Touchpoint, newValue: any) => {
    const updatedTouchpoints = [...value.touchpoints];
    updatedTouchpoints[index] = {
      ...updatedTouchpoints[index],
      [field]: newValue
    };
    setValue({ ...value, touchpoints: updatedTouchpoints });
  };

  const handleRemoveTouchpoint = (index: number) => {
    
    const updatedTouchpoints = value.touchpoints.filter((_ : any, i: number) => i !== index);
    setValue({ ...value, touchpoints: updatedTouchpoints });
  };

  const handleAddTouchpoint = () => {
    // Validate new touchpoint data
    if (!newTouchpoint.name.trim() || !newTouchpoint.date) {
      return; // Don't add if name or date is missing
    }
    
    // Ensure we have a valid dayjs object
    const validDate = newTouchpoint.date && dayjs(newTouchpoint.date).isValid() 
      ? dayjs(newTouchpoint.date) 
      : dayjs();
      
    const touchpointToAdd = {
      ...newTouchpoint,
      id: `touchpoint-custom-${Date.now()}`,
      date: validDate
    };
    
    setValue({
      ...value,
      touchpoints: [...value.touchpoints, touchpointToAdd]
    });
    
    // Reset form and close dialog
    setNewTouchpoint({
      name: "",
      date: dayjs(),
      stage: "custom",
      description: ""
    });
    setDialogOpen(false);
  };

  // Sort touchpoints by date
  const sortedTouchpoints = [...value.touchpoints].sort((a, b) => {
    const dateA = dayjs(a.date);
    const dateB = dayjs(b.date);
    return dateA.isBefore(dateB) ? -1 : 1;
  });

  return (
    <>
      <Title>Program Touchpoints</Title>
      <Typography variant="subtitle1" gutterBottom>
        These are the key moments for data collection throughout your program. You can modify dates and add custom touchpoints.
      </Typography>
      
      <Box sx={{ mt: 3, position: 'relative' }}>
        {/* Timeline vertical line */}
        <Box sx={{ 
          position: 'absolute', 
          left: '50%', 
          top: 0, 
          bottom: 0, 
          width: '4px', 
          bgcolor: 'divider',
          transform: 'translateX(-50%)',
          zIndex: 0
        }} />
        
        <TouchpointsCountContext.Provider value={sortedTouchpoints.length}>
          {sortedTouchpoints.map((touchpoint: any, index: number) => (
            <TouchpointItem
              key={touchpoint.id || index}
              touchpoint={touchpoint}
              index={index}
              handleTouchpointChange={handleTouchpointChange}
              removeTouchpoint={handleRemoveTouchpoint}
            />
          ))}
        </TouchpointsCountContext.Provider>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, position: 'relative', zIndex: 1 }}>
          <Button 
            variant="outlined" 
            startIcon={<AddIcon />} 
            onClick={() => setDialogOpen(true)}
          >
            Add Custom Touchpoint
          </Button>
        </Box>
      </Box>
      
      {/* Add Touchpoint Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add Custom Touchpoint</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Touchpoint Name"
                  value={newTouchpoint.name}
                  onChange={(e) => setNewTouchpoint({...newTouchpoint, name: e.target.value})}
                  required
                />
              </Grid>
              
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={newTouchpoint.date && dayjs(newTouchpoint.date).isValid() ? dayjs(newTouchpoint.date) : null}
                    onChange={(newDate) => setNewTouchpoint({...newTouchpoint, date: newDate || dayjs() })}
                    slotProps={{ textField: { fullWidth: true, required: true } }}
                  />
                </LocalizationProvider>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  value={newTouchpoint.description}
                  onChange={(e) => setNewTouchpoint({...newTouchpoint, description: e.target.value})}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddTouchpoint} variant="contained">Add Touchpoint</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
