import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography, Paper, Grid, Tooltip } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Title from "@einhorn/title";
import { PagedResult, SurveyDto } from "app/types";
import { useNavigate } from "react-router-dom";

interface ProgramSurveysProps {
  programId?: string;
  program?: any;
}


export default function ProgramSurveys({ programId, program }: ProgramSurveysProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [surveys, setSurveys] = useState({
    data: [],
    paging: {
        length: 0,
        pageSize: 0,
        page: 0,
        total: 0
    }
} as PagedResult<SurveyDto>);


  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed': return 'success.main';
      case 'active': return 'primary.main';
      case 'upcoming': return 'grey.400';
      default: return 'grey.400';
    }
  };

  // Sort surveys by date (ascending)
  const sortedSurveys = [...(surveys.data || [])].sort((a, b) => 
    new Date(a.surveyDateUtc).getTime() - new Date(b.surveyDateUtc).getTime()
  );

  useEffect(() => {
    loadSurveys("", 1);
}, [program]);

  const loadSurveys = async (searchTerm: string, pageNumber: number) => {
    try {
        setLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(
            `{clientId}/surveys/program/${programId}`
        );
        setSurveys(data);
    } catch (error) {
        console.error('Error loading surveys:', error);
    } finally {
        setLoading(false);
    }
};

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleSurveyClick = (surveyId: string) => {
    navigate(`/surveys/${surveyId}/create`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Title>Program Surveys</Title>
      
      <Paper elevation={0} sx={{ p: 3, mb: 4, overflowX: 'auto' }}>

        
        {loading ? (
          <Typography>Loading touchpoints...</Typography>
        ) : sortedSurveys.length === 0 ? (
          <Typography>No touchpoints found for this program.</Typography>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            mt: 5,
            mb: 3,
            minWidth: '800px',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              height: '4px',
              backgroundColor: 'grey.200',
              top: '72px',
              left: '30px',
              right: '30px',
              zIndex: 0,
              borderRadius: '2px'
            }
          }}>
            {sortedSurveys.map((survey, index) => (
              <Box 
                key={survey.surveyId} 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  width: `${100 / sortedSurveys.length}%`,
                  zIndex: 1,
                  transition: 'transform 0.2s ease-in-out',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)'
                  }
                }}
                onClick={() => handleSurveyClick(survey.surveyId)}
              >
                {/* Date - moved below dot */}
                
                {/* Dot */}
                <Box sx={{ 
                  bgcolor: getStatusColor(survey.surveyStatus),
                  borderRadius: '50%',
                  width: 56,
                  height: 56,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2,
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                    transform: 'scale(1.05)'
                  }
                }}>
                  <AssessmentIcon sx={{ color: 'white', fontSize: 28 }} />
                </Box>
                
                {/* Date */}
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ 
                    mb: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontWeight: 500
                  }}
                >
                  <span>{new Date(survey.surveyDateUtc).toLocaleDateString('en-US', { month: 'short' })}</span>
                  <span>{new Date(survey.surveyDateUtc).toLocaleDateString('en-US', { day: 'numeric' })},</span>
                  <span>{new Date(survey.surveyDateUtc).toLocaleDateString('en-US', { year: 'numeric' })}</span>
                </Typography>
                
                {/* Info */}
                <Box sx={{ 
                  textAlign: 'center', 
                  maxWidth: '160px',
                  width: '100%',
                  backgroundColor: 'background.paper',
                  p: 1,
                  borderRadius: 1,
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  transition: 'box-shadow 0.2s ease',
                  '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  <Tooltip title={survey.name} placement="top">
                    <Typography 
                      variant="body1" 
                      fontWeight="medium" 
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%'
                      }}
                    >
                      {survey.name}
                    </Typography>
                  </Tooltip>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: survey.surveyStatus === 'active' ? 'primary.main' : 
                             survey.surveyStatus === 'completed' ? 'success.main' : 'text.secondary',
                      fontWeight: survey.surveyStatus === 'active' ? 600 : 400
                    }}
                  >
                    {survey.surveyStatus === 'upcoming' 
                      ? 'Upcoming' 
                      : `${survey.responseCount} responses`}
                  </Typography>
                </Box>
                
                {/* Add connecting line for visual aid */}
                {index < sortedSurveys.length - 1 && (
                  <Box sx={{
                    position: 'absolute',
                    height: '4px',
                    width: '100%',
                    right: '-50%',
                    top: '72px',
                    zIndex: 0
                  }} />
                )}
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    </Box>
  );
} 