import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import { InsightChartData } from "../insightTypes";

// Initialize modules
HC_more(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);

export default function OpinionSummaryInsightsChart({ insight }: any) {

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={getChartOptions(insight)} />

    )
}


const getChartOptions = (insight: InsightChartData): Highcharts.Options => {
    const { data, title, description } = insight;
    let i = -1;
    return {
        colors: ['#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '#5090B7', '#39C1C1', '#3AD7B0', '#8266E1', '#003f5c', '#'],
        chart: {
            type: 'column',
        },
        title: {
            text: "",
            align: 'left',
        },
        subtitle: {
            text: description,
            align: 'left',
        },
        xAxis: {
            categories: Object.keys(data.series),
            crosshair: true
        },
        credits: {
            enabled: false
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.y:,.1f} %'
                }
            },
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: GetSeries(data)
    }
};
function GetSeries(data: { categories: string[]; series: { [key: string]: number[]; }; }): Highcharts.SeriesOptionsType[] {

    let series = [];
    for (let i = 0; i < data.categories.length; i++) {
        series.push({
            name: data.categories[i],
            data: Object.entries(data.series).map(([key, values]) => values[i]),
            type: 'column' as const
        })
    }

    return series;
}
