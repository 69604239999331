import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    Container,
    Divider,
    CircularProgress,
    Chip,
    Card,
    CardContent,
    Alert,
    Breadcrumbs,
    Link
} from '@mui/material';
import {
    ArrowBack as ArrowBackIcon,
    Person as PersonIcon,
    Event as EventIcon,
    Schedule as ScheduleIcon
} from '@mui/icons-material';
import FathomClient from '@api/fathomapi';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';

interface Question {
    questionId: string;
    title: string;
    type: string;
    response: string;
}

interface PageResponse {
    pageId: string;
    name: string;
    elements: Question[];
}

interface SurveyResponse {
    participantId: string;
    participantName: string;
    participantEmail: string;
    completedDateUtc: string;
    startedDateUtc: string;
    pages: PageResponse[];
}

export default function SurveyResponse() {
    const { getAccessTokenSilently } = useAuth0();
    const { surveyId, participantId } = useParams();
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [responseData, setResponseData] = useState<SurveyResponse | null>(null);
    
    useEffect(() => {
        const fetchSurveyResponse = async () => {
            if (!surveyId || !participantId) return;
            
            try {
                setLoading(true);
                setError(null);
                
                const apiClient = new FathomClient(await getAccessTokenSilently());
                
                // Fetch survey response data
                const response = await apiClient.get(`{clientId}/surveys/${surveyId}/responses/${participantId}`);
                
                if (response.data && typeof response.data === 'object') {
                    setResponseData(response.data);
                } else {
                    throw new Error('Invalid response data format');
                }
                
            } catch (err) {
                console.error('Error fetching survey response data:', err);
                setError('Failed to load response data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        
        fetchSurveyResponse();
    }, [surveyId, participantId, getAccessTokenSilently]);
    
    // Format date and time
    const formatDateTime = (dateString: string) => {
        return dayjs(dateString).format('MMM D, YYYY [at] h:mm A');
    };
    
    // Calculate time taken to complete
    const calculateTimeTaken = () => {
        if (!responseData) return '';
        
        const started = dayjs(responseData.startedDateUtc);
        const completed = dayjs(responseData.completedDateUtc);
        const minutes = completed.diff(started, 'minute');
        
        if (minutes < 60) {
            return `${minutes} minutes`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
        }
    };
    
    // Render response based on question type
    const renderResponse = (question: Question) => {
        const questionType = question.type?.toLowerCase() || '';
        
        if (!question.response) {
            return <Typography color="text.secondary" variant="body2">No response provided</Typography>;
        }
        
        // For multiple choice questions (radio buttons)
        if (questionType === 'multi') {
            // Check if response is a comma-separated list and render as multiple chips
            if (question.response.includes(',')) {
                const options = question.response.split(',').map(item => item.trim());
                return (
                    <Box sx={{ mt: 1 }}>
                        {options.map((option, index) => (
                            <Chip 
                                key={index} 
                                label={option} 
                                variant="outlined" 
                                size="small"
                                sx={{ mr: 1, mb: 1 }}
                            />
                        ))}
                    </Box>
                );
            }
            
            // Single selection
            return (
                <Box sx={{ mt: 1 }}>
                    <Chip 
                        label={question.response} 
                        variant="outlined" 
                        size="small"
                        sx={{ mr: 1, mb: 1 }}
                    />
                </Box>
            );
        }
        
        // For rating questions
        if (questionType === 'rating') {
            return (
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" color="primary" sx={{ mr: 1 }}>
                        {question.response}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        / {5} {/* Default max rating */}
                    </Typography>
                </Box>
            );
        }
        
        // For NPS score
        if (questionType === 'nps') {
            const score = parseInt(question.response, 10);
            let color = 'primary.main';
            
            if (score >= 0 && score <= 6) {
                color = 'error.main'; // Detractors: 0-6
            } else if (score >= 7 && score <= 8) {
                color = 'warning.main'; // Passives: 7-8
            } else if (score >= 9 && score <= 10) {
                color = 'success.main'; // Promoters: 9-10
            }
            
            return (
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ mr: 1, color }}>
                        {question.response}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        / 10
                    </Typography>
                </Box>
            );
        }
        
        // For opinion scale
        if (questionType === 'opinion') {
            return (
                <Box sx={{ mt: 1 }}>
                    <Chip 
                        label={question.response} 
                        variant="outlined" 
                        size="small"
                        color="primary"
                        sx={{ mr: 1, mb: 1 }}
                    />
                </Box>
            );
        }
        
        // For ranking questions
        if (questionType === 'ranking') {
            // Handle ranking display - likely a numbered list of items
            const rankedItems = question.response.split(',').map(item => item.trim());
            
            return (
                <Box sx={{ mt: 1 }}>
                    {rankedItems.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    minWidth: '25px', 
                                    fontWeight: 'bold', 
                                    color: 'primary.main' 
                                }}
                            >
                                {index + 1}.
                            </Typography>
                            <Typography variant="body1">{item}</Typography>
                        </Box>
                    ))}
                </Box>
            );
        }
        
        // For open text responses (default)
        return (
            <Box sx={{ mt: 1, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="body1">{question.response}</Typography>
            </Box>
        );
    };
    
    if (loading) {
        return (
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh'
            }}>
                <CircularProgress />
            </Container>
        );
    }
    
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}
            
            {/* Navigation */}
            <Box sx={{ mb: 3 }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link 
                        underline="hover" 
                        color="inherit" 
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate('/surveys')}
                    >
                        Surveys
                    </Link>
                    <Link 
                        underline="hover" 
                        color="inherit" 
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/surveys/${surveyId}/overview`)}
                    >
                        Survey Details
                    </Link>
                    <Typography color="text.primary">Response Details</Typography>
                </Breadcrumbs>
                
                <Button 
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(`/surveys/${surveyId}/overview`)}
                    sx={{ mb: 2 }}
                >
                    Back to Survey
                </Button>
            </Box>
            
            {/* Response header */}
            <Paper sx={{ p: 3, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Response from {responseData?.participantName || 'Unknown Participant'}
                </Typography>
                
                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PersonIcon color="action" sx={{ mr: 1 }} />
                        <Typography variant="body1">
                            {responseData?.participantEmail || 'No email provided'}
                        </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <EventIcon color="action" sx={{ mr: 1 }} />
                        <Typography variant="body1">
                            Completed: {responseData?.completedDateUtc ? formatDateTime(responseData.completedDateUtc) : 'Unknown'}
                        </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ScheduleIcon color="action" sx={{ mr: 1 }} />
                        <Typography variant="body1">
                            Time taken: {calculateTimeTaken()}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            
            {/* Response content */}
            {responseData?.pages?.map((page, pageIndex) => (
                <Paper key={pageIndex} sx={{ p: 3, mb: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        {page.name || `Page ${pageIndex + 1}`}
                    </Typography>
                    
                    <Divider sx={{ mb: 3 }} />
                    
                    {page.elements?.map((question, questionIndex) => (
                        <Box key={questionIndex} sx={{ mb: 4 }}>
                            <Typography variant="h6" component="h3">
                                {question.title}
                            </Typography>
                            
                            {renderResponse(question)}
                        </Box>
                    ))}
                </Paper>
            ))}
            
            {(!responseData || !responseData.pages || responseData.pages.length === 0) && (
                <Alert severity="info" sx={{ mb: 3 }}>
                    No response data found for this participant.
                </Alert>
            )}
        </Container>
    );
} 