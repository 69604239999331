import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { enqueueSnackbar } from "notistack";
import Cookies from "universal-cookie";

const cookies = new Cookies();



interface ApiErrorResponse {
  StatusCode: number;
  ErrorMessage: string
}

function handleError(error: AxiosError, showError: boolean = true) {
  let errorMessage = 'An unexpected error occurred. Please try again later.';

  if (error.response) {
    const errorData = error.response.data as ApiErrorResponse;
    errorMessage = errorData.ErrorMessage;

    if (error.response.status === 401) {
      errorMessage = 'Authentication failed. Please log in again.';
    } else if (error.response.status === 403) {
      errorMessage = 'You do not have permission to perform this action.';
    } else if (error.response.status === 404) {
      errorMessage = 'The requested resource was not found.';
    } else if (error.response.status >= 500) {
      errorMessage = 'A server error occurred. Please try again later.';
    }

    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    errorMessage = 'No response received from the server. Please check your network connection.';
    console.log(error.request);
  } else {
    errorMessage = error.message || errorMessage;
    console.log('Error', error.message);
  }

  console.log(error.config);
  if(showError){
    enqueueSnackbar(errorMessage, {
      variant: "error",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });
  }

  return Promise.reject(errorMessage)
}

export default class FathomClient {

  
  private static interceptorsInitialized = false;
  private api: AxiosInstance;
  constructor(token: string, showError: boolean = true) {

    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    });
    
    this.api.defaults.withCredentials = true;

    this.api.defaults.headers.common['Authorization'] = "Bearer " + token;
    
    // Only set up interceptors once
    if (!FathomClient.interceptorsInitialized) {
      this.api.interceptors.request.use(
        (res) => { return res }, 
        (error) => handleError(error, showError)
      );
      this.api.interceptors.response.use(
        (res) => { return res }, 
        (error) => handleError(error, showError)
      );
      FathomClient.interceptorsInitialized = true;
    }
  }

  private replaceClientId(endpoint: string) {
    if (endpoint.search("{clientId}") > -1) {
      var clientId = cookies.get("x-fathom-clientId");
      endpoint = endpoint.replace("{clientId}", clientId);
    }

    return endpoint;
  }

  get(endpoint: string) {
    endpoint = this.replaceClientId(endpoint);
    return this.api.get(endpoint);
  }

  post(endpoint: string, data: any,) {
    endpoint = this.replaceClientId(endpoint);
    return this.api.post(endpoint, data);
  }

  put(endpoint: string, data: any) {
    endpoint = this.replaceClientId(endpoint);
    return this.api.put(endpoint, data);
  }

  delete(endpoint: string,) {
    endpoint = this.replaceClientId(endpoint);
    return this.api.delete(endpoint);
  }
}
