import { Container, Box, Grid, Typography, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { ChartDataPoint, ChartFilter } from "components/insights/insightTypes";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from "react";
import { Menu, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmDialog from "@einhorn/confirmDialog";
import GroupedChart from './charts/GroupedChart';
import NumberChart from './charts/NumberChart';
import TableChart from './charts/TableChart';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChartEditor from "../ChartEditor";

interface ViewInsightsChartsProps {
    boardId: string;
    insightsChart: ChartDataPoint;
    fetchData: (id: string) => void;
    onDelete?: () => void;
    chartId?: string;
    isSingleChart?: boolean;
    filter: ChartFilter;
}
export default function ViewInsightsCharts({
    boardId,
    insightsChart,
    fetchData,
    chartId,
    isSingleChart = false,
    filter,
    onDelete
}: ViewInsightsChartsProps) {
    const [newChartModalOpen, setNewChartModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editModalType, setEditModalType] = useState<'Number' | 'Group' | 'Table' | 'Media' | null>(null);
    const [editingDataPointId, setEditingDataPointId] = useState<string | null>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [chartQueryData, setChartQueryData] = useState<any>(null);
    const handleModalClose = () => {
        setNewChartModalOpen(false);
        setEditMode(false);
        setEditingDataPointId(null);
    };
    const { getAccessTokenSilently } = useAuth0();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedInsight, setSelectedInsight] = useState<string | null>(null);
    const [selectedInsightChart, setSelectedInsightChart] = useState<ChartDataPoint>(null as any as ChartDataPoint);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, insightId: string, insightChart: ChartDataPoint) => {
        setAnchorEl(event.currentTarget);
        setSelectedInsight(insightId);
        setSelectedInsightChart(insightChart);
    };

    const handleEditComplete = () => {
        setSelectedInsight(null);
        setSelectedInsightChart(null as any as ChartDataPoint)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const fetchChartData = async () => {
        if (!filter) return;
        if (filter.chartType === "Media") return;
        setLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const response = await apiClient.post(`{clientId}/insights/data/${filter.chartType.toLocaleLowerCase()}`, filter);
            setChartQueryData({
                data: response?.data.data
            });
        } catch (error) {
            console.error('Error fetching chart data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        setChartQueryData(null);
    }, [filter]);

    useEffect(() => {
        fetchChartData()
            .catch(console.error);
    }, [filter, getAccessTokenSilently]);

    const handleDelete = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        await apiClient.delete(`{clientId}/boards/${boardId}/insights/chart/${selectedInsight}`);
        if (fetchData) {
            fetchData(boardId);
        } else {
            fetchChartData();
        }
        setConfirmDeleteOpen(false);
        handleMenuClose();
        if (onDelete) {
            onDelete();
        }
    };

    const handleEdit = () => {
        if (selectedInsightChart) {
            setEditingDataPointId(selectedInsightChart.dataPointId || null);
            setEditModalType(selectedInsightChart.filter.chartType as 'Number' | 'Group' | 'Table' | 'Media');
            setEditMode(true);
            handleMenuClose();
        }
    };

    return (
        <Container maxWidth={false} disableGutters>
            <Box maxWidth="xl" mx="auto">
                {!isSingleChart && (
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant="h6" sx={{ my: 4, ml: 2 }}>View Insights &nbsp;
                                <a onClick={() => setNewChartModalOpen(true)} style={{ cursor: 'pointer' }}>
                                    <AddCircleIcon
                                        sx={{
                                            my: 0,
                                            color: 'text.primary',
                                            transform: 'scale(0.8)',
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.0)',
                                                color: 'action.selected',
                                            }
                                        }}
                                        fontSize="small"
                                    />
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={isSingleChart ? 12 : 6}>
                        <Box
                            key={insightsChart.dataPointId}
                            className="insight-box"
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={1}>
                                <Typography variant="subtitle1">{insightsChart.title}
                                    <div style={{ fontSize: 13 }}>{insightsChart.description}</div>
                                </Typography>
                                <Typography variant="subtitle2">
                                    {insightsChart.filter.type !== "None" && insightsChart.filter.type[0].toUpperCase() +
                                        insightsChart.filter.type.slice(1).replace(/(?!^)([A-Z]|\d+)/g, " $1")}
                                </Typography>

                                <IconButton
                                    aria-label="more"
                                    onClick={(event) => handleMenuOpen(event, insightsChart.dataPointId || "", insightsChart)}
                                >
                                    <MoreHorizIcon fontSize="small" />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={handleEdit}>
                                        <EditIcon fontSize="small" style={{ marginRight: '8px' }} />
                                        <Typography variant="body2">Edit insight</Typography>
                                    </MenuItem>
                                    <MenuItem style={{ color: 'red' }} onClick={() => setConfirmDeleteOpen(true)} >
                                        <DeleteIcon fontSize="small" style={{ marginRight: '8px' }} />
                                        <Typography variant="body2">Delete insight</Typography>
                                    </MenuItem>
                                </Menu>

                            </Box>
                            <Box
                                className="insight-chart-container"
                                sx={{
                                    flexGrow: 1,
                                    minHeight: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'hidden'
                                }}
                            >
                                {insightsChart.filter.chartType === 'Group' && (
                                    <GroupedChart
                                        insightsChart={insightsChart}
                                        loading={loading}
                                        chartQueryData={chartQueryData}
                                    />
                                )}
                                {insightsChart.filter.chartType === 'Number' && (
                                    <NumberChart
                                        insightsChart={insightsChart}
                                        loading={loading}
                                        chartQueryData={chartQueryData}
                                    />
                                )}
                                {insightsChart.filter.chartType === 'Table' && (
                                    <TableChart
                                        loading={loading}
                                        chartQueryData={chartQueryData?.data}
                                    />
                                )}
                                {insightsChart.filter.chartType === 'Media' && (<>
                                    {insightsChart.staticMedia?.type === 'Text' && (
                                        <Box
                                            sx={{
                                                whiteSpace: "pre-wrap",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 2,
                                                padding: "40px 120px 40px 120px"
                                            }}
                                        >
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{insightsChart.staticMedia?.media}</ReactMarkdown>
                                        </Box>
                                    )}
                                    {insightsChart.staticMedia?.type === 'Image' && (
                                        <img src={insightsChart.staticMedia?.media} alt="Preview" />
                                    )}
                                </>
                                )}
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
                <ConfirmDialog
                    title="Delete program"
                    content="Are you sure you want to delete this chart?"
                    open={confirmDeleteOpen}
                    setOpen={setConfirmDeleteOpen}
                    confirmAction={handleDelete}
                />
                <ChartEditor
                    boardId={boardId || ""}
                    open={editMode && editModalType !== null}
                    setModalOpen={handleModalClose}
                    order={0}
                    fetchData={fetchData}
                    onConfirm={handleEditComplete}
                    dataPointId={insightsChart.dataPointId}
                    chartType={editModalType || ""}
                />
            </Box>
        </Container>
    );
}