import React from 'react';
import { Box, IconButton, Paper, Stack, Typography, ListItemIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { ChartOption } from '../types';

interface ChartSidebarProps {
    showSidebar: boolean;
    selectedChartType: string | null;
    setShowSidebar: (show: boolean) => void;
    chartOptions: ChartOption[];
    handleChartOptionSelect: (type: string) => void;
    handleAddChartAtPosition: (event: React.MouseEvent<HTMLElement>, index: number) => void;
    addIndex: number;
}

const NewChartSidebar: React.FC<ChartSidebarProps> = ({
    showSidebar,
    selectedChartType,
    setShowSidebar,
    chartOptions,
    handleChartOptionSelect,
}) => {
    if(!showSidebar) {
        return <></>;
    }

    return (
        
        <Box 
            className="chart-type-sidebar"
            sx={{
                position: 'fixed',
                right: 0,
                top: 0,
                width: 460,
                height: '100%',
                backgroundColor: 'background.paper',
                borderLeft: '1px solid',
                borderColor: 'divider',
                p: 2,
                overflowY: 'auto'
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">
                    {selectedChartType ? selectedChartType.charAt(0).toUpperCase() + selectedChartType.slice(1) : ''} Options
                </Typography>
                <IconButton 
                    onClick={() => setShowSidebar(false)}
                    size="small"
                    sx={{
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        }
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            
            <Stack spacing={2}>
                {selectedChartType === 'survey' && (
                    <>
                        <Typography variant="body2" color="text.secondary">
                            Select a visualization type
                        </Typography>
                        {chartOptions.map((option) => (
                            <Paper
                                key={option.type}
                                sx={{
                                    p: 2,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                                onClick={() => handleChartOptionSelect(option.type)}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <ListItemIcon>
                                        {option.icon}
                                    </ListItemIcon>
                                    <Box>
                                        <Typography variant="subtitle1">{option.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {option.description}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Paper>
                        ))}
                    </>
                )}
            </Stack>
        </Box>
    );
};

export default NewChartSidebar; 