import { Box, CircularProgress, Typography } from "@mui/material";
import { ChartDataPoint } from 'components/insights/insightTypes';

interface NumberChartProps {
    insightsChart: ChartDataPoint;
    loading: boolean;
    chartQueryData: any;
}

export default function NumberChart({ insightsChart, loading, chartQueryData }: NumberChartProps) {
    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <CircularProgress />
            </Box>
        );
    }

    if (!chartQueryData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <Typography color="text.secondary">No data available</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ 
            width: '100%',
            height: '100%',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '200px'  // Ensure minimum height for proper centering
        }}>
            <Typography 
                variant="h2" 
                sx={{
                    textAlign: 'center',
                    paddingTop: "150px",
                    fontWeight: 'medium'
                }}
            >
                {Math.round(chartQueryData.data.data * 100) / 100}
            </Typography>
        </Box>
    );
} 