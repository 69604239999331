import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    TextField,
    Container,
    CircularProgress,
    Alert,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Rating,
    Stepper,
    Step,
    StepLabel,
    Divider,
    LinearProgress,
    Snackbar
} from '@mui/material';
import {
    NavigateNext as NextIcon,
    NavigateBefore as PrevIcon,
    Send as SubmitIcon
} from '@mui/icons-material';
import FathomClient from '@api/fathomapi';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

interface SurveyQuestion {
    type: string;
    title: string;
    choices?: string[];
    rangeMin?: number;
    rangeMax?: number;
    required?: boolean;
    askFollowUp?: boolean;
}

interface SurveyPage {
    title: string;
    description?: string;
    elements: SurveyQuestion[];
}

interface Survey {
    title: string;
    description: string;
    purpose?: string;
    pages: SurveyPage[];
}

interface SurveyResponse {
    questionId: string;
    answerText?: string;
    answerValue?: number;
    answerChoices?: string[];
}

export default function SubmitSurvey() {
    const { getAccessTokenSilently, isAuthenticated, isLoading: auth0Loading } = useAuth0();
    const { surveyId, participantId, clientId } = useParams();
        
    const [survey, setSurvey] = useState<Survey | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [responses, setResponses] = useState<{ [key: string]: SurveyResponse }>({});
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [startTime, setStartTime] = useState<Date | null>(null);

    // Function to get a token or create an axios instance for public access
    const getApiClient = async () => {
        try {
            if (isAuthenticated) {
                return new FathomClient(await getAccessTokenSilently(), false);
            } else {
                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_ENDPOINT,
                    withCredentials: true
                });
                
                // Return an object with the same interface as FathomClient
                return {
                    get: (endpoint: string) => {
                        return api.get(endpoint);
                    },
                    post: (endpoint: string, data: any) => {
                        return api.post(endpoint, data);
                    }
                };
            }
        } catch (error) {
            console.error("Error getting API client:", error);
            throw error;
        }
    };

    // Load survey structure
    useEffect(() => {
        const fetchSurvey = async () => {
            if (!surveyId) return;

            try {
                setLoading(true);
                setError(null);

                const apiClient = await getApiClient();
                const { data } = await apiClient.get(
                    `${clientId}/surveys/${surveyId}/participants/${participantId}/structure`
                );
                
                setSurvey(data);
                
                // Initialize responses object with question IDs
                const initialResponses: { [key: string]: SurveyResponse } = {};
                data.pages.forEach((page: SurveyPage, pageIndex: number) => {
                    page.elements.forEach((question: SurveyQuestion, questionIndex: number) => {
                        const questionId = `${pageIndex}-${questionIndex}`;
                        initialResponses[questionId] = {
                            questionId,
                            answerText: question.type === 'open' ? '' : undefined,
                            answerValue: ['rating', 'nps', 'opinion'].includes(question.type) ? undefined : undefined,
                            answerChoices: ['multi', 'ranking'].includes(question.type) ? [] : undefined
                        };
                    });
                });
                setResponses(initialResponses);
                
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load survey');
            } finally {
                setLoading(false);
            }
        };

        fetchSurvey();
    }, [surveyId, isAuthenticated, auth0Loading]);

    // Handle response changes
    const handleResponseChange = (pageIndex: number, questionIndex: number, value: any, responseType: 'text' | 'value' | 'choices') => {
        // Set start time when the first question is answered
        if (!startTime) {
            setStartTime(new Date());
        }
        
        const questionId = `${pageIndex}-${questionIndex}`;
        setResponses(prev => ({
            ...prev,
            [questionId]: {
                ...prev[questionId],
                [`answer${responseType.charAt(0).toUpperCase() + responseType.slice(1)}`]: value
            }
        }));
    };

    // Check if current page answers are valid
    const isCurrentPageValid = () => {
        if (!survey) return false;
        
        const currentPageQuestions = survey.pages[currentPage].elements;
        
        for (let i = 0; i < currentPageQuestions.length; i++) {
            const question = currentPageQuestions[i];
            if (!question.required) continue;
            
            const questionId = `${currentPage}-${i}`;
            const response = responses[questionId];
            
            if (question.type === 'open' && (!response.answerText || response.answerText.trim() === '')) {
                return false;
            } else if (['rating', 'nps', 'opinion'].includes(question.type) && response.answerValue === undefined) {
                return false;
            } else if (['multi', 'ranking'].includes(question.type) && 
                       (!response.answerChoices || response.answerChoices.length === 0)) {
                return false;
            }
        }
        
        return true;
    };

    // Handle next page navigation
    const handleNextPage = () => {
        if (!survey) return;
        
        if (currentPage < survey.pages.length - 1) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
        }
    };

    // Handle previous page navigation
    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 0);
        }
    };

    // Submit survey responses
    const handleSubmit = async () => {
        if (!survey || !surveyId || !participantId) return;
        
        try {
            setSubmitting(true);
            setError(null);
            
            // Transform responses into the expected DTO format
            const pageResponses = [];
            
            if (survey.pages) {
                for (let pageIndex = 0; pageIndex < survey.pages.length; pageIndex++) {
                    const page = survey.pages[pageIndex];
                    const elementResponses = [];
                    
                    for (let questionIndex = 0; questionIndex < page.elements.length; questionIndex++) {
                        const questionId = `${pageIndex}-${questionIndex}`;
                        const response = responses[questionId];
                        const question = page.elements[questionIndex];
                        
                        if (response) {
                            // Format the response based on the question type
                            let responseValue = '';
                            
                            if (question.type === 'open') {
                                responseValue = response.answerText || '';
                            } else if (['rating', 'nps', 'opinion'].includes(question.type)) {
                                responseValue = response.answerValue !== undefined ? response.answerValue.toString() : '';
                            } else if (['multi', 'ranking'].includes(question.type)) {
                                responseValue = response.answerChoices ? response.answerChoices.join(',') : '';
                            }
                            
                            elementResponses.push({
                                Title: question.title,
                                Type: question.type,
                                Response: responseValue
                            });
                        }
                    }
                    
                    pageResponses.push({
                        Title: page.title,
                        Elements: elementResponses
                    });
                }
            }

            const completedDate = new Date();
            
            const surveyResponseDto = {
                ParticipantId: participantId,
                CompletedDateUtc: completedDate.toISOString(),
                StartedDateUtc: startTime ? startTime.toISOString() : completedDate.toISOString(),
                Pages: pageResponses
            };
            
            const apiClient = await getApiClient();
            await apiClient.post(
                `${clientId}/surveys/${surveyId}/responses`,
                surveyResponseDto
            );
            
            setSubmitted(true);
            setSnackbarMessage('Survey submitted successfully!');
            setSnackbarOpen(true);
            
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to submit survey');
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ py: 4 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
                <Button 
                    variant="outlined" 
                    onClick={() => window.location.reload()}
                >
                    Try Again
                </Button>
            </Container>
        );
    }

    if (!survey) {
        return (
            <Container sx={{ py: 4 }}>
                <Alert severity="error">Survey not found.</Alert>
            </Container>
        );
    }

    if (!participantId) {
        return (
            <Container sx={{ py: 4 }}>
                <Alert severity="error">Participant ID is required.</Alert>
            </Container>
        );
    }

    if (submitted) {
        return (
            <Container sx={{ py: 8 }}>
                <Paper elevation={0} sx={{ p: 4, borderRadius: 2, textAlign: 'center' }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>Thank You!</Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        Your responses have been successfully submitted.
                    </Typography>
                </Paper>
            </Container>
        );
    }

    const currentSurveyPage = survey.pages[currentPage];

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>{survey.title}</Typography>
                {survey.description && (
                    <Typography variant="body1" color="text.secondary">{survey.description}</Typography>
                )}
            </Box>
            
            {survey.pages.length > 1 && (
                <Box sx={{ mb: 4 }}>
                    <Stepper 
                        activeStep={currentPage} 
                        alternativeLabel={survey.pages.length <= 5}
                        sx={{ 
                            display: { xs: survey.pages.length <= 3 ? 'flex' : 'none', sm: 'flex' } 
                        }}
                    >
                        {survey.pages.map((page, index) => (
                            <Step key={index}>
                                <StepLabel>{page.title}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    
                    <Box sx={{ display: { xs: survey.pages.length > 3 ? 'block' : 'none', sm: 'none' }, mt: 2 }}>
                        <LinearProgress 
                            variant="determinate" 
                            value={(currentPage / (survey.pages.length - 1)) * 100} 
                        />
                        <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', mt: 1 }}>
                            Page {currentPage + 1} of {survey.pages.length}
                        </Typography>
                    </Box>
                </Box>
            )}
            
            <Paper 
                elevation={0} 
                sx={{ 
                    p: 4, 
                    borderRadius: 2, 
                    border: '1px solid', 
                    borderColor: 'divider',
                    mb: 4
                }}
            >
                {currentSurveyPage.title && (
                    <Typography variant="h5" gutterBottom>{currentSurveyPage.title}</Typography>
                )}
                
                {currentSurveyPage.description && (
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                        {currentSurveyPage.description}
                    </Typography>
                )}
                
                {currentSurveyPage.title || currentSurveyPage.description ? <Divider sx={{ mb: 3 }} /> : null}
                
                {currentSurveyPage.elements.map((question, questionIndex) => {
                    const questionId = `${currentPage}-${questionIndex}`;
                    const response = responses[questionId];
                    
                    return (
                        <Box key={questionId} sx={{ mb: 4 }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormLabel 
                                    id={`question-${questionId}-label`}
                                    sx={{ 
                                        fontWeight: 500, 
                                        color: 'text.primary',
                                        fontSize: '1rem',
                                        mb: 2
                                    }}
                                >
                                    {question.title}
                                    {question.required && (
                                        <Typography 
                                            component="span" 
                                            color="error" 
                                            sx={{ ml: 0.5 }}
                                        >
                                            *
                                        </Typography>
                                    )}
                                </FormLabel>
                                
                                {question.type === 'open' && (
                                    <TextField
                                        multiline
                                        rows={3}
                                        fullWidth
                                        value={response.answerText || ''}
                                        onChange={(e) => handleResponseChange(currentPage, questionIndex, e.target.value, 'text')}
                                        placeholder="Enter your answer here..."
                                        required={question.required}
                                    />
                                )}
                                
                                {question.type === 'rating' && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Rating
                                            name={`rating-${questionId}`}
                                            value={response.answerValue || null}
                                            onChange={(_, newValue) => handleResponseChange(currentPage, questionIndex, newValue, 'value')}
                                            max={question.rangeMax || 5}
                                            size="large"
                                        />
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                                            {response.answerValue ? `${response.answerValue} / ${question.rangeMax || 5}` : ''}
                                        </Typography>
                                    </Box>
                                )}
                                
                                {question.type === 'multi' && (
                                    <RadioGroup
                                        aria-labelledby={`question-${questionId}-label`}
                                        name={`question-${questionId}`}
                                        value={response.answerChoices?.[0] || ''}
                                        onChange={(e) => handleResponseChange(currentPage, questionIndex, [e.target.value], 'choices')}
                                    >
                                        {question.choices?.map((choice, choiceIndex) => (
                                            <FormControlLabel
                                                key={choiceIndex}
                                                value={choice}
                                                control={<Radio />}
                                                label={choice}
                                                sx={{ mt: 1 }}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                                
                                {question.type === 'nps' && (
                                    <Box sx={{ mt: 2 }}>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            maxWidth: 660,
                                            mb: 1
                                        }}>
                                            {Array.from({ length: 11 }, (_, i) => (
                                                <Button
                                                    key={i}
                                                    variant={response.answerValue === i ? "contained" : "outlined"}
                                                    onClick={() => handleResponseChange(currentPage, questionIndex, i, 'value')}
                                                    sx={{ 
                                                        minWidth: '40px',
                                                        height: '40px',
                                                        p: 0,
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {i}
                                                </Button>
                                            ))}
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            maxWidth: 660,
                                            mt: 1
                                        }}>
                                            <Typography variant="caption" color="text.secondary">
                                                Not at all likely
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                Extremely likely
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                
                                {question.type === 'opinion' && (
                                    <RadioGroup
                                        aria-labelledby={`question-${questionId}-label`}
                                        name={`question-${questionId}`}
                                        value={response.answerValue || ''}
                                        onChange={(e) => handleResponseChange(currentPage, questionIndex, Number(e.target.value), 'value')}
                                    >
                                        {[
                                            {value: 1, label: 'Strongly disagree'},
                                            {value: 2, label: 'Disagree'},
                                            {value: 3, label: 'Neutral'},
                                            {value: 4, label: 'Agree'},
                                            {value: 5, label: 'Strongly agree'}
                                        ].map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                                sx={{ mt: 1 }}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                                
                                {question.type === 'ranking' && (
                                    <Box>
                                        {question.choices?.map((choice, choiceIndex) => (
                                            <Box 
                                                key={choiceIndex}
                                                sx={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center',
                                                    mb: 2,
                                                    p: 2,
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    borderRadius: 1,
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        bgcolor: 'action.hover'
                                                    },
                                                    bgcolor: response.answerChoices?.includes(choice) ? 'action.selected' : 'background.paper'
                                                }}
                                                onClick={() => {
                                                    const currentChoices = [...(response.answerChoices || [])];
                                                    const choiceIndex = currentChoices.indexOf(choice);
                                                    
                                                    if (choiceIndex === -1) {
                                                        // Add the choice
                                                        currentChoices.push(choice);
                                                    } else {
                                                        // Remove the choice
                                                        currentChoices.splice(choiceIndex, 1);
                                                    }
                                                    
                                                    handleResponseChange(currentPage, questionIndex, currentChoices, 'choices');
                                                }}
                                            >
                                                <Typography>
                                                    {choice}
                                                </Typography>
                                                {response.answerChoices?.includes(choice) && (
                                                    <Box 
                                                        sx={{ 
                                                            ml: 'auto',
                                                            bgcolor: 'primary.main',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            width: 24,
                                                            height: 24,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {response.answerChoices.indexOf(choice) + 1}
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </FormControl>
                        </Box>
                    );
                })}
            </Paper>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                    variant="outlined"
                    startIcon={<PrevIcon />}
                    onClick={handlePrevPage}
                    disabled={currentPage === 0}
                >
                    Previous
                </Button>
                
                {currentPage < survey.pages.length - 1 ? (
                    <Button
                        variant="contained"
                        endIcon={<NextIcon />}
                        onClick={handleNextPage}
                        disabled={!isCurrentPageValid()}
                    >
                        Next
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<SubmitIcon />}
                        onClick={handleSubmit}
                        disabled={!isCurrentPageValid() || submitting}
                    >
                        {submitting ? 'Submitting...' : 'Submit'}
                    </Button>
                )}
            </Box>
            
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Container>
    );
}
