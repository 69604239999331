import { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Switch,
    FormControlLabel,
    Button,
    Autocomplete,
    Chip,
    FormControl,
    FormHelperText,
    Menu,
    MenuItem,
    Drawer,
    Container,
    Grid,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";

interface User {
    email: string;
    name: string;
}

interface DashboardUserDto {
    userEmail: string;
    editor: boolean;
}

interface DashboardCreateDto {
    name: string;
    description: string;
    public: boolean;
    users: DashboardUserDto[];
}

interface CreateDrawerProps {
    open: boolean;
    toggleOpen: (open: boolean) => void;
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    marginTop: "25px"
}));

export const CreateDashboardDrawer = ({ open, toggleOpen }: CreateDrawerProps) => {
    const { clientId } = useParams<{ clientId: string }>();
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<DashboardUserDto[]>([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activeUserEmail, setActiveUserEmail] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const apiClient = new FathomClient(await getAccessTokenSilently());
                const response = await apiClient.get(`{clientId}/users`);
                setUsers(response.data.data);
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        };

        if (open) {
            fetchUsers();
        }
    }, [clientId, open]);

    const handleSubmit = async () => {
        const newErrors: { [key: string]: string } = {};
        if (!name.trim()) newErrors.name = 'Name is required';
        if (!description.trim()) newErrors.description = 'Description is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const dashboardData: DashboardCreateDto = {
            name: name.trim(),
            description: description.trim(),
            public: isPublic,
            users: selectedUsers,
        };

        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.post(`{clientId}/boards`, dashboardData);
            navigate("/boards/" + data.id)
        } catch (error) {
            console.error('Failed to create dashboard:', error);
        }
    };

    const handleClose = () => {
        setName('');
        setDescription('');
        setIsPublic(false);
        setSelectedUsers([]);
        setErrors({});
        toggleOpen(false);
    };

    const handleRoleMenuOpen = (event: React.MouseEvent<HTMLDivElement>, email: string) => {
        setAnchorEl(event.currentTarget);
        setActiveUserEmail(email);
    };

    const handleRoleMenuClose = () => {
        setAnchorEl(null);
        setActiveUserEmail(null);
    };

    const handleRoleChange = (isEditor: boolean) => {
        if (activeUserEmail) {
            setSelectedUsers(users =>
                users.map(user =>
                    user.userEmail === activeUserEmail
                        ? { ...user, editor: isEditor }
                        : user
                )
            );
        }
        handleRoleMenuClose();
    };

    const handleUserSelect = (event: any, value: User | null) => {
        if (value && !selectedUsers.some(user => user.userEmail === value.email)) {
            setSelectedUsers([...selectedUsers, { userEmail: value.email, editor: false }]);
        }
    };

    const handleRemoveUser = (email: string) => {
        setSelectedUsers(selectedUsers.filter(user => user.userEmail !== email));
    };

    return (
        <Drawer
            anchor='right'
            sx={{
                width: "600px",
            }}
            open={open}
            PaperProps={{
                sx: { width: "800px" },
            }}
        >
            <DrawerHeader>
                <Container>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Stack spacing={2} direction="row" justifyContent="left" sx={{ mt: '10px', ml: "15px" }}>
                                <Typography variant="h6" component="h2">
                                    Create New Dashboard
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={2} direction="row" justifyContent="right" sx={{ mr: '10px', mt: "5px" }}>
                                <IconButton aria-label="close" onClick={() => toggleOpen(false)}>
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </DrawerHeader>

            <Container sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    margin="normal"
                />

                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    error={!!errors.description}
                    helperText={errors.description}
                    margin="normal"
                    multiline
                    rows={3}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={isPublic}
                            onChange={(e) => setIsPublic(e.target.checked)}
                        />
                    }
                    label="Allow other team members to view this dashboard"
                    sx={{ my: 2 }}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <Autocomplete
                        options={users}
                        getOptionLabel={(option) => `${option.name} (${option.email})`}
                        onChange={handleUserSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Add Users"
                                variant="outlined"
                            />
                        )}
                    />
                    <FormHelperText>Add users to collaborate on this dashboard</FormHelperText>
                </FormControl>

                <Box sx={{ mt: 2 }}>
                    {selectedUsers.map((user) => {
                        const userDetails = users.find(u => u.email === user.userEmail);
                        return (
                            <Chip
                                key={user.userEmail}
                                label={userDetails ? userDetails.name : user.userEmail}
                                onDelete={() => handleRemoveUser(user.userEmail)}
                                onClick={(e) => handleRoleMenuOpen(e, user.userEmail)}
                                deleteIcon={<CloseOutlinedIcon />}
                                icon={<ExpandMoreIcon />}
                                sx={{ m: 0.5 }}
                            />
                        );
                    })}
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleRoleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => handleRoleChange(true)}>Editor</MenuItem>
                </Menu>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Create Dashboard
                    </Button>
                </Box>
            </Container>
        </Drawer>
    );
}; 