import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PollIcon from '@mui/icons-material/Poll';
import BarChartIcon from '@mui/icons-material/BarChart';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';

interface ChartMenuProps {
    anchorEl: HTMLElement | null;
    menuPosition: { x: number; y: number } | null;
    onClose: () => void;
    onChartTypeSelect: (type: string) => void;
}

export default function ChartMenu({ 
    anchorEl, 
    menuPosition, 
    onClose, 
    onChartTypeSelect 
}: ChartMenuProps) {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={
                menuPosition !== null
                    ? { top: menuPosition.y, left: menuPosition.x }
                    : undefined
            }
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => onChartTypeSelect('survey')}>
                <ListItemIcon>
                    <PollIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Survey data"
                    secondary="Use question data direct from your surveys"
                />
            </MenuItem>
            <MenuItem onClick={() => onChartTypeSelect('text')}>
                <ListItemIcon>
                    <TextFieldsIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Text"
                    secondary="Use text to tell a story"
                />
            </MenuItem>
        </Menu>
    );
} 