import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { Paper, Container, Grid, Typography, LinearProgress, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { PagedResult } from "app/types";
import { useEffect, useState } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import Moment from "moment";
import "./engagementList.css";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from "react";
import ConfirmDialog from "@einhorn/confirmDialog";
import SpreadSheetParse from "./create/dataupload/spreadsheetparse";
import LoadingBar from "@einhorn/loadingBar";
export default function EngagementList({ programId, setFirstReport, currentEngagementReportId }: any) {

    const { getAccessTokenSilently } = useAuth0();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const [engagementReports, setEngagementReports] = useState({
        data: [],
        paging: {
            length: 0,
            pageSize: 0,
            page: 0,
            total: 0
        }
    } as PagedResult<any>);
    const [loading, setLoading] = useState(true);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [reportId, setReportId] = useState(currentEngagementReportId);
    const [reportEditId, setEditReportId] = useState("");
    const loadEngagementReports = async (searchTerm: string, page: number, triggerIdUpdate: boolean = true) => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/engagement?search=` + searchTerm + "&page=" + page);
        setEngagementReports(data);
        if (data.paging.length > 0 && currentEngagementReportId == undefined) {
            if (triggerIdUpdate) {
                setFirstReport(data.data[0].id, false, "engagement")
                setReportId(data.data[0].id);
            }
        }

        setLoading(false);
    }

    const successSync = (report: any) => {
        return report.status == "Complete";
    }

    const syncing = (report: any) => {
        var isSyncing = report.status !== "Complete" && report.status.indexOf("Error") === -1;
        return isSyncing;
    }

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            loadEngagementReports("", 1)

        };
        setLoading(true);
        fetchData()
            .catch(console.error);
    }, []);


    const viewReport = (reportId: string) => {
        setFirstReport(reportId, true, "engagement")
        setReportId(reportId);
        let base = window.location.href;
        var index = base.indexOf("?reportId=");
        var updated = base.slice(0, (index < 0 ? base.length : index));
        window.history.pushState("", "", updated)
    }

    const getClassName = (report: any) => {

        if (report.id == reportId) {
            return "reportBoxActive"
        }

        return "reportBox";
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        setEditReportId(id)
        setAnchorEl(event.currentTarget);
    };
    const handleEditClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const deleteReport = async () => {
        if (reportEditId !== "") {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            await apiClient.delete(
                "{clientId}/programs/" + programId + "/engagement/" + reportEditId
            );
            setConfirmDeleteOpen(false)
            loadEngagementReports("", 0, false);
        }
    };

    const getEngagementReports = () => {
        return engagementReports.data.map((x: any) => (
            <Paper sx={{ marginBottom: "25px" }} className={(getClassName(x))} key={x.id} >
                <Container onClick={() => viewReport(x.id)}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid sm={2} item>
                            <DescriptionIcon sx={{ mt: "-20px" }} fontSize="large" />
                        </Grid>
                        <Grid sm={8} item sx={{ mt: "-18px" }}>
                            <Tooltip title={x.name}>
                                <Typography className="reportTitle">{x.name}</Typography>
                            </Tooltip>
                            <Typography sx={{ fontSize: "12px" }}>{Moment(x.reportDateUtc).format("D/MM/YY")}</Typography>
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Container>
                <IconButton aria-label="open" sx={{ mt: "-150px", ml: "230px" }} onClick={(ev: any) => handleEditClick(ev, x.id)}>
                    <MoreHorizIcon fontSize="small" />
                </IconButton>

            </Paper>
        ));
    };

    return (<Container>
        <ConfirmDialog
            title="Delete report"
            content="Are you sure you want to delete this report?"
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            confirmAction={deleteReport}
        />

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleEditClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            disableScrollLock={true}
        >
            <MenuItem onClick={() => {
                setEditDrawerOpen(true)
                setAnchorEl(null);
            }
            }>Edit</MenuItem>
            <MenuItem onClick={() => setConfirmDeleteOpen(true)}>Delete</MenuItem>
        </Menu>

        {loading && engagementReports.paging.length == 0 &&
            <LoadingBar />
        }

        {engagementReports.paging.length == 0 && !loading && (
            <Container sx={{ marginBottom: "25px", ml: "-25px" }}>
                <br />
                <Typography variant="h6">
                    No engagement reports found
                </Typography>
                <br />
            </Container>
        )}
        {getEngagementReports()}

        
        <SpreadSheetParse loadEngagementReports={loadEngagementReports} />
            
        <br />
    </Container>);

}   