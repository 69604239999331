import { useAuth0 } from "@auth0/auth0-react";
import LoadingBar from "@einhorn/loadingBar";
import { Container, Toolbar, Grid, Button, Paper, LinearProgress, Typography } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function ClientList() {
    const { getAccessTokenSilently } = useAuth0();
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`clients`);
        setClients(data);
        setLoading(false)
    };

    const selectClient = (client: any) => {

        getAccessTokenSilently({cacheMode: "off"})
        cookies.set('x-fathom-clientId', client.clientId, {
            path: "/"
          });

          localStorage.setItem("x-fathom-currentRole", client.clientRole)

          window.location.href = "/"
    };

    useEffect(() => {
        if(!loading) {
            setLoading(true);
            fetchData()
                .catch(console.error);
        }
    }, []);


    const getClients = () => {
        return clients.map((x: any) => (
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} key={x.id}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid sm={10} item>
                            <Typography
                                sx={{
                                    marginTop: "10px",
                                }}
                                variant="h6"
                            >
                                {x.title}
                            </Typography>
                            <Typography variant="subtitle1">{x.name}</Typography>
                            <Typography sx={{
                                marginBottom: "20px",
                                color: 'blue'
                            }} variant="subtitle2">{x.clientRole}</Typography>
                        </Grid>


                        <Grid sm={2} item>
                            <Typography
                                sx={{
                                    marginTop: "20px",
                                    color: "red",
                                }}
                            >
                                <Button variant="text" onClick={() => selectClient(x)}>
                                    Select
                                </Button>
                            </Typography>

                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </Container>
            </Paper>
        ));
    };

    return (
        <Container>
            <Container>
                <Toolbar />
                <Grid item xs={12}>
                    <br />
                    <Container>

                        <Button >Clients</Button>
                        {loading && clients.length == 0 &&
                            <LoadingBar />
                        }
                        {clients.length == 0 && !loading && (
                            <Paper>
                                {" "}
                                <Container>
                                    <br />
                                    <Typography variant="h6">
                                        Get added to your first client, check your emails for an invite and make sure you clicked accept.
                                    </Typography>
                                    <br />
                                </Container>
                            </Paper>
                        )}
                        {getClients()}
                    </Container>
                </Grid>
            </Container>
        </Container>
    );
}