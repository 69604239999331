import ProtectedRoute from "../auth/protected-route";
import { Route, Routes } from "react-router-dom";
import User from "./user/user";
import Programs from "./programs/programs";
import CreateProgram from "./programs/create/create";
import UsersList from "./users/users";
import ClientList from "./clientSelect/clientSelect";
import AcceptInvite from "./invite/invite";
import LoginPage from "./login/login";
import NotFound from "./NotFound"
import ViewProgram from "./viewProgram/viewProgram";
import ViewDashboards from "./dashboards/dashboards";
import ViewChat from "./chat/chat";
import ViewBoard from "./dashboards/insights/boards";
import Signup from "./signup/signup";
import Billing from "./billing/billing";
import Surveys from "./surveys/surveys";
import EditSurvey from "./surveys/editSurvey";
import SubmitSurvey from "./surveys/submit";
import Participants from "./participants/participants";
import SurveyOverview from "./surveys/overview";
import SurveyResponse from "./surveys/response";

const Routing = () => {
  return (
    <Routes >
      <Route
        path="/"
        element={<ProtectedRoute component={User} />}
      />
      <Route
        path="/login"
        element={<LoginPage />}
      />
      <Route
        path="/signup"
        element={<Signup />}
      />
      <Route
        path="/surveys"
        element={<ProtectedRoute component={Surveys} />}
      />
      <Route
        path="/surveys/:surveyId?/create"
        element={<ProtectedRoute component={EditSurvey} />}
      />
      <Route
        path="/surveys/:surveyId/overview"
        element={<ProtectedRoute component={SurveyOverview} />}
      />
      <Route
        path="/surveys/:surveyId/responses/:participantId"
        element={<ProtectedRoute component={SurveyResponse} />}
      />
      <Route
        path="/surveys/:clientId/:surveyId/:participantId/submit"
        element={<SubmitSurvey />}
      />
      <Route
        path="/programs"
        element={<ProtectedRoute component={Programs} />}
      />
      <Route
        path="/participants"
        element={<ProtectedRoute component={Participants} />}
      />
      <Route
        path="/programs/create/:id?/:step?"
        element={<ProtectedRoute component={CreateProgram} />}
      />
      <Route
        path="/programs/:programId"
        element={<ProtectedRoute component={ViewProgram} />}
      />
      <Route
        path="/boards"
        element={<ProtectedRoute component={ViewDashboards} />}
      />
      <Route
        path="/boards/:boardId"
        element={<ProtectedRoute component={ViewBoard} />}
      />
      <Route
        path="/chat"
        element={<ProtectedRoute component={ViewChat} />}
      />
      <Route
        path="/users"
        element={<ProtectedRoute component={UsersList} />}
      />
      <Route
        path="/select"
        element={<ProtectedRoute component={ClientList} />}
      />
      <Route
        path="/invite"
        element={<ProtectedRoute component={AcceptInvite} />}
      />
      <Route
        path="/callback"
        element={<ProtectedRoute component={LoginPage} />} />

      <Route
        path="/billing"
        element={<ProtectedRoute component={Billing} />} />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  )
}

export default Routing;
