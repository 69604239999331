import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Container,
    Box,
    Typography,
    Grid,
    styled,
    Stack,
} from "@mui/material";
import { useState, useEffect } from "react";
import ViewInsightsCharts from "./view/insightsCharts";
import { ChartDataPoint, ChartFilter, InsightsResponse } from "components/insights/insightTypes";
import "./insights.css"
import LoadingChart from "./charts/loadingChart";
import { useNavigate, useParams } from "react-router-dom";
import RGL, { Layout, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import BreadCrumb from "@einhorn/breadcrumb/breadcrumb";
import Title from "@einhorn/title";
import TableChartIcon from '@mui/icons-material/TableChart';
import CountIcon from '@mui/icons-material/Tag';
import { ChartOption } from "./types";
import ChartMenu from './menu/chartMenu';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';
import LoadingBar from "@einhorn/loadingBar";
import NewChartSidebar from "./menu/newChartSidebar";
import ChartEditor from "./ChartEditor";

const drawerWidth = 330;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    position: 'relative',
    maxWidth: '95%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
}));


const ChartSidebar = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '18px',
    height: '100%',
    top: 0,
    opacity: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    cursor: 'pointer',
    transition: 'all 0.2s',
    zIndex: 1,
    display: 'flex',
    '&:hover': {
        opacity: 1,
        backgroundColor: 'rgba(99, 39, 230, 0.1)',
    }
}));


const ReactGridLayout = WidthProvider(RGL);

const GridItemWrapper = styled('div')({
    position: 'relative',
    '& .chart-sidebar': {
        pointerEvents: 'none'
    },
    '&:hover .chart-sidebar': {
        pointerEvents: 'auto'
    },
    '& .grid-item': {
        backgroundColor: 'white',
        borderRadius: '8px',
        overflow: 'hidden',
        height: 'calc(100% - 20px)',
        margin: '10px',
        minHeight: '300px',
        padding: '20px'
    }
});

interface LayoutItem {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    static?: boolean;
    content: {
        data: any;
        chartId?: string;
        filter: ChartFilter;
        insightsChart: ChartDataPoint;
    };
}

export default function ViewBoard() {
    const { getAccessTokenSilently } = useAuth0();
    const [board, setBoard] = useState(null as any as InsightsResponse);
    const [loading, setLoading] = useState(true);
    const [randomChartIndex, setRandomChartIndex] = useState(Math.floor(Math.random() * 2));
    const { boardId } = useParams();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedChartType, setSelectedChartType] = useState<string | null>(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [layouts, setLayouts] = useState<LayoutItem[]>([]);
    const [addIndex, setAddIndex] = useState(0);
    const [menuPosition, setMenuPosition] = useState<{ x: number; y: number } | null>(null);
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [modalType, setModalType] = useState<'number' | 'group' | 'table' | 'media' | null>(null);

    const chartOptions: ChartOption[] = [
        {
            title: 'Number',
            type: 'number',
            description: 'Show a single number or metric',
            icon: <CountIcon />
        },
        {
            title: 'Table',
            type: 'table',
            description: 'Show data in table format',
            icon: <TableChartIcon />
        },
        {
            title: 'Chart',
            type: 'group',
            description: 'Visualize data in a chart',
            icon: <BarChartIcon />
        }
    ];

    const fetchInsightsData = async () => {
        setLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/boards/` + boardId + `/insights/`);
        setBoard(data);

        // Create layout items for existing charts
        if (data.insightsDataPoints && data.insightsDataPoints.length > 0) {
            const newLayouts = data.insightsDataPoints.map((chart: ChartDataPoint, index: number) => {
                const itemsInRow = index % 3;  // 0, 1, or 2 items in current row
                const rowStartIndex = Math.floor(index / 3) * 3;  // Start index of current row
                const itemsLeftInDataset = data.insightsDataPoints.length - rowStartIndex;
                const itemsInCurrentRow = Math.min(3, itemsLeftInDataset);

                let width = 12;  // Default to full width
                let xPosition = 0;

                if (itemsInCurrentRow === 2) {
                    width = 6;  // Half width for two items
                    xPosition = itemsInRow * 6;
                } else if (itemsInCurrentRow === 3) {
                    width = 4;  // Third width for three items
                    xPosition = itemsInRow * 4;
                }

                return {
                    i: chart.dataPointId,
                    x: xPosition,
                    y: Math.floor(index / 3) * 4, // New row every 3 charts
                    w: width,
                    h: 4,
                    content: {
                        data: null,
                        chartId: chart.dataPointId,
                        filter: chart.filter,
                        insightsChart: chart
                    }
                };
            });
            setLayouts(newLayouts);
        }

        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchInsightsData()
            .catch(console.error);

    }, [getAccessTokenSilently]);


    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuPosition(null);
    };

    const handleChartTypeSelect = (type: string) => {

        if (type == "text") {
            setModalType("media");
        }
        else if (type == "image") {
            setModalType("media");
        } else {
            setSelectedChartType(type);
            handleMenuClose();
            setShowSidebar(true);
        }
    };

    const handleChartOptionSelect = (type: string) => {
        setSelectedOption(type);
        setShowSidebar(false);
        setModalType(type as 'number' | 'group' | 'table');
    };

    const handleModalClose = () => {
        setModalType(null);
    };

    const handleModalConfirm = () => {
        fetchInsightsData();
        handleModalClose();
        setShowSidebar(false);
    };

    const handleChartDelete = () => {
        fetchInsightsData();
    };

    const onLayoutChange = (newLayout: Layout[]) => {
        const updatedLayouts = layouts.map((item) => {
            const layoutItem = newLayout.find((l) => l.i === item.i);
            if (layoutItem) {
                return { ...item, ...layoutItem };
            }
            return item;
        });
        setLayouts(updatedLayouts);
    };

    const viewBoards = () => {
        navigate("/boards/");
    };

    const handleAddChartAtPosition = (event: React.MouseEvent<HTMLElement>, index: number) => {
        event.stopPropagation();

        setMenuPosition({
            x: event.clientX,
            y: event.clientY
        });

        setAddIndex(index);

        setAnchorEl(event.currentTarget);
    };

    return (
        <Main>
            <Container
                maxWidth={false}
                sx={{
                    px: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    position: 'relative',
                    pb: 2,
                    overflow: 'auto'
                }}>
                    <Grid container>
                        <Grid item xs={11}>
                            <BreadCrumb buttonFunc={viewBoards} buttonText={"Boards"} pageText={board?.name} />
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                    </Grid>

                    <br />
                    <Container maxWidth={false}>
                        <Grid container sx={{ paddingLeft: '50px' }}>
                            <Grid item xs={11}>
                                <Title>{board?.name} &nbsp;&nbsp;</Title>
                                <Typography variant="subtitle2">{board?.description}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '10px' }} >
                                </Stack>
                            </Grid>
                        </Grid>

                        <ChartMenu
                            anchorEl={anchorEl}
                            menuPosition={menuPosition}
                            onClose={handleMenuClose}
                            onChartTypeSelect={handleChartTypeSelect}
                        />

                        <Box sx={{
                            flexGrow: 1,
                            overflow: 'auto',
                            pt: 1,
                            px: 2,
                            pb: 4
                        }}>
                            {!loading && !board && (
                                <Container sx={{ marginBottom: "25px" }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <br />
                                        <Typography variant="h6">
                                            Upload your first report on the right!
                                        </Typography>
                                        <br />
                                        <LoadingChart index={randomChartIndex} />

                                    </Box>
                                </Container>
                            )}
                            {loading &&
                                <LoadingBar />
                            }
                            {!loading && board && (
                                <ReactGridLayout
                                    className="layout"
                                    layout={layouts}
                                    cols={12}
                                    rowHeight={120}
                                    onLayoutChange={onLayoutChange}
                                    isDraggable={false}
                                    isResizable={false}
                                    preventCollision={true}
                                    compactType={null}
                                    margin={[20, 20]}
                                    containerPadding={[20, 20]}
                                    style={{ minHeight: '100%' }}
                                >
                                    {layouts.map((item, index) => (
                                        <GridItemWrapper
                                            key={item.i}
                                            className="grid-item-wrapper"
                                        >
                                            <ChartSidebar
                                                className="chart-sidebar"
                                                sx={{
                                                    left: -9,
                                                    height: 'calc(100% - 20px)',
                                                    top: '10px',
                                                    borderRadius: '0px 0 0 0px'
                                                }}
                                                onClick={(e) => handleAddChartAtPosition(e, index)}
                                            >
                                                <AddIcon color="primary" />
                                            </ChartSidebar>

                                            <div className="grid-item">
                                                <ViewInsightsCharts
                                                    boardId={boardId || ""}
                                                    chartId={item.content.chartId}
                                                    isSingleChart={true}
                                                    filter={item.content.filter}
                                                    insightsChart={item.content.insightsChart}
                                                    onDelete={handleChartDelete}
                                                    fetchData={fetchInsightsData}
                                                />
                                            </div>

                                            <ChartSidebar
                                                className="chart-sidebar"
                                                sx={{
                                                    right: -9,
                                                    height: 'calc(100% - 20px)',
                                                    top: '10px',
                                                    borderRadius: '0 0px 0px 0'
                                                }}
                                                onClick={(e) => handleAddChartAtPosition(e, index + 1)}
                                            >
                                                <AddIcon color="primary" />
                                            </ChartSidebar>
                                        </GridItemWrapper>
                                    ))}
                                    <div
                                        key="bottom-add"
                                        data-grid={{
                                            x: 0,
                                            y: layouts.length > 0 ? Math.max(...layouts.map(l => l.y)) + 4 : 0,
                                            w: 12,
                                            h: 1,
                                            static: true
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: '48px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'left',
                                                borderColor: 'divider',
                                                borderRadius: 1,
                                                paddingLeft: '10px',
                                                cursor: 'pointer',
                                                mt: 2,
                                                '&:hover': {
                                                    borderColor: 'primary.main',
                                                    backgroundColor: 'rgba(99, 39, 230, 0.1)',
                                                }
                                            }}
                                            onClick={(e) => handleAddChartAtPosition(e, layouts.length)}
                                        >

                                            <Stack direction="row" spacing={1} alignItems="left">
                                                <AddIcon color="primary" />
                                                <Typography color="primary">Add content</Typography>
                                            </Stack>
                                        </Box>
                                    </div>
                                </ReactGridLayout>
                            )}
                        </Box>
                    </Container>
                </Box>
            </Container>
            <NewChartSidebar
                showSidebar={showSidebar}
                selectedChartType={selectedChartType}
                setShowSidebar={setShowSidebar}
                chartOptions={chartOptions}
                handleChartOptionSelect={handleChartOptionSelect}
                handleAddChartAtPosition={handleAddChartAtPosition}
                addIndex={addIndex}
            />

            <ChartEditor
                boardId={boardId || ""}
                open={modalType !== null}
                setModalOpen={handleModalClose}
                order={addIndex}
                fetchData={fetchInsightsData}
                onConfirm={handleModalConfirm}
                dataPointId={undefined}
                chartType={modalType || ""}
            />

        </Main>
    );
}