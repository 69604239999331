import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { TableData } from 'components/insights/insightTypes';
import { useEffect, useState } from "react";

type GroupedTableData = {
    questionTitle: string;
    questionType: string;
    rows: TableData[];
};

const groupDataByQuestion = (data: TableData[]): GroupedTableData[] => {
    if (!Array.isArray(data)) {
        data = (data as any).data || [];
    }
    
    const groupedMap = data.reduce((acc, row) => {
        if (!acc[row.questionTitle]) {
            acc[row.questionTitle] = {
                questionTitle: row.questionTitle,
                questionType: row.questionType,
                rows: []
            };
        }
        acc[row.questionTitle].rows.push(row);
        return acc;
    }, {} as Record<string, GroupedTableData>);

    return Object.values(groupedMap);
};

interface TableChartProps {
    loading: boolean;
    chartQueryData: any;
    hideEmptyResponses?: boolean;
    onHideEmptyResponsesChange?: (checked: boolean) => void;
    showControls?: boolean;
}

export default function TableChart({ 
    loading, 
    chartQueryData,
    hideEmptyResponses = true,
    onHideEmptyResponsesChange,
    showControls = true
}: TableChartProps) {
    const [localHideEmpty, setLocalHideEmpty] = useState(hideEmptyResponses);

    const handleHideEmptyChange = (checked: boolean) => {
        setLocalHideEmpty(checked);
        if (onHideEmptyResponsesChange) {
            onHideEmptyResponsesChange(checked);
        }
    };

    const effectiveHideEmpty = onHideEmptyResponsesChange ? hideEmptyResponses : localHideEmpty;

    useEffect(() => {
    }, [chartQueryData]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <CircularProgress />
            </Box>
        );
    }

    if (!chartQueryData?.data) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                <Typography color="text.secondary">No data available</Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ 
                overflowX: 'auto', 
                maxHeight: '400px',
            }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '50%' }}>Response</TableCell>
                            <TableCell sx={{ width: '50%' }}>Score (Normalised / 100)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupDataByQuestion(chartQueryData.data)
                            .filter(group => !effectiveHideEmpty || 
                                group.rows.some(r => r.response && r.response.trim() !== ''))
                            .map((group, groupIndex) => (
                                <>
                                    <TableRow 
                                        key={`header-${groupIndex}`}
                                        sx={{ 
                                            backgroundColor: '#f5f5f5',
                                            '& td': { 
                                                fontWeight: 'bold',
                                                borderBottom: '2px solid rgba(224, 224, 224, 1)'
                                            }
                                        }}
                                    >
                                        <TableCell colSpan={2}>
                                            {group.questionTitle}
                                        </TableCell>
                                    </TableRow>
                                    {group.rows
                                        .filter(row => !effectiveHideEmpty || 
                                            (row.response && row.response.trim() !== ''))
                                        .map((row, rowIndex) => (
                                            <TableRow key={`data-${groupIndex}-${rowIndex}`}>
                                                <TableCell sx={{ width: '50%' }}>
                                                    {row.response}
                                                </TableCell>
                                                <TableCell sx={{ width: '50%' }}>
                                                    {row.score * (group.questionType === "Open" ? 1 : 10)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </>
                            ))}
                    </TableBody>
                </Table>
            </Box>
            {showControls && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, ml: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={effectiveHideEmpty}
                                onChange={(e) => handleHideEmptyChange(e.target.checked)}
                            />
                        }
                        label="Hide empty responses"
                    />
                </Box>
            )}
        </>
    );
} 