import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    Container,
    Grid,
    Divider,
    CircularProgress,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    InputAdornment,
    Card,
    CardContent,
    Alert,
    IconButton,
    Tooltip,
    Switch,
    FormControlLabel,
    Snackbar,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import {
    Search as SearchIcon,
    FilterList as FilterIcon,
    DownloadOutlined as DownloadIcon,
    MailOutline as EmailIcon,
    CheckCircleOutline as CompletedIcon,
    RemoveCircleOutline as PendingIcon,
    TrendingUp as TrendingUpIcon,
    Group as GroupIcon,
    Schedule as ScheduleIcon,
    PersonAdd as InviteIcon,
    ContentCopy as ContentCopyIcon,
    CalendarMonth as CalendarIcon,
    ToggleOn as ToggleOnIcon,
    ToggleOff as ToggleOffIcon,
    Add as AddIcon,
    Close as CloseIcon,
    FileUpload as FileUploadIcon
} from '@mui/icons-material';
import FathomClient from '@api/fathomapi';
import { useAuth0 } from '@auth0/auth0-react';
import { PagedResult } from 'app/types';
import Cookies from "universal-cookie";
import BreadCrumb from '@einhorn/breadcrumb/breadcrumb';
import { read, utils } from 'xlsx';

const cookies = new Cookies();

// Interface definitions
interface Participant {
    participantId: string;
    name: string;
    email: string;
    status: 'completed' | 'pending' | 'started' | string;
    completedDate?: string;
    lastActiveDate?: string;
    emailStatus?: string;
    scheduledEmailDateUtc?: string;
    sentEmailDateUtc?: string;
    emailErrorDetails?: string;
}

interface SurveyStats {
    completionRate: number;
    totalParticipants: number;
    pendingResponses: number;
    recentCompletions: number;
    averageCompletionTime?: number;
}

interface SurveyDetails {
    id: string;
    name: string;
    description: string;
    programId: string;
    createdDateUtc: string;
    surveyDateUtc: string;
    surveyStatus: 'Ready' | 'Draft' | 'Sent' | 'Scheduled' | 'Complete';
    questionCount: number;
}

interface SurveyOverviewData {
    survey: SurveyDetails;
    completionRate: number;
    totalParticipants: number;
    pendingResponses: number;
    recentCompletions: number;
    averageCompletionTime?: number | string;
    participants: PagedResult<Participant>;
}

// Add a helper function to parse time string
const parseTimeString = (timeString: string): { minutes: number, seconds: number } => {
    if (!timeString) return { minutes: 0, seconds: 0 };
    
    try {
        // Parse a string like "00:08:55.9760000"
        const parts = timeString.split(':');
        if (parts.length >= 3) {
            const hours = parseInt(parts[0]) || 0;
            const minutes = parseInt(parts[1]) || 0;
            // Handle seconds with potential decimal point
            const secondsPart = parts[2].split('.')[0];
            const seconds = parseInt(secondsPart) || 0;
            
            return { 
                minutes: hours * 60 + minutes,
                seconds: seconds
            };
        }
        return { minutes: 0, seconds: 0 };
    } catch (error) {
        console.error('Error parsing time string:', error);
        return { minutes: 0, seconds: 0 };
    }
};

export default function SurveyOverview() {
    const { getAccessTokenSilently } = useAuth0();
    const { surveyId } = useParams();
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [overviewData, setOverviewData] = useState<SurveyOverviewData | null>(null);
    const [filteredParticipants, setFilteredParticipants] = useState<Participant[]>([]);
    
    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    // Filter state
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState<string>('all');
    const [showOnlyRecent, setShowOnlyRecent] = useState(false);
    
    // Add participant menu state
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    
    // Modal states for participant addition
    const [manualAddOpen, setManualAddOpen] = useState(false);
    const [csvUploadOpen, setCsvUploadOpen] = useState(false);
    
    // New participant form state
    const [newParticipant, setNewParticipant] = useState({
        name: '',
        email: ''
    });
    
    // CSV file state
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewData, setPreviewData] = useState<{name: string, email: string}[]>([]);
    const [isPreviewReady, setIsPreviewReady] = useState(false);
    
    // Add snackbar state for copy confirmation
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    
    // Add publishing state
    const [publishing, setPublishing] = useState(false);

     const fetchSurveyOverview = async () => {
            if (!surveyId) return;
            
            try {
                setLoading(true);
                setError(null);
                
                const apiClient = new FathomClient(await getAccessTokenSilently());
                
                // Fetch survey overview data from the single endpoint
                const response = await apiClient.get(`{clientId}/surveys/${surveyId}/overview`);
                setOverviewData(response.data);
                
                // Initialize filtered participants
                if (response.data && response.data.participants) {
                    setFilteredParticipants(response.data.participants.data || []);
                }
            } catch (err) {
                console.error('Error fetching survey overview data:', err);
                setError('Failed to load survey data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
    
    useEffect(() => {
    
        
        fetchSurveyOverview();
    }, [surveyId, getAccessTokenSilently]);
    
    // Filter participants when filters change
    useEffect(() => {
        if (!overviewData || !overviewData.participants || !overviewData.participants.data) {
            return;
        }
        
        let filtered = [...overviewData.participants.data];
        
        // Apply search filter
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(p => 
                p.name.toLowerCase().includes(query) || 
                p.email.toLowerCase().includes(query)
            );
        }
        
        // Apply status filter
        if (statusFilter !== 'all') {
            filtered = filtered.filter(p => p.status === statusFilter);
        }
        
        // Apply recent filter
        if (showOnlyRecent) {
            const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            filtered = filtered.filter(p => {
                if (p.status === 'completed' && p.completedDate) {
                    return new Date(p.completedDate) > oneWeekAgo;
                }
                if (p.lastActiveDate) {
                    return new Date(p.lastActiveDate) > oneWeekAgo;
                }
                return false;
            });
        }
        
        setFilteredParticipants(filtered);
    }, [overviewData, searchQuery, statusFilter, showOnlyRecent]);
    
    // Handle page change
    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    // Handle rows per page change
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    // Handle search input change
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    
    // Handle status filter change
    const handleStatusFilterChange = (status: string) => {
        setStatusFilter(status === statusFilter ? 'all' : status);
        setPage(0);
    };
    
    // Handle invite participants button click
    const handleInviteParticipants = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    const handleManualAddOpen = () => {
        handleMenuClose();
        setManualAddOpen(true);
    };
    
    const handleManualAddClose = () => {
        setManualAddOpen(false);
        setNewParticipant({
            name: '',
            email: ''
        });
    };
    
    const handleCsvUploadOpen = () => {
        handleMenuClose();
        setCsvUploadOpen(true);
        setPreviewData([]);
        setIsPreviewReady(false);
    };
    
    const handleCsvUploadClose = () => {
        setCsvUploadOpen(false);
        setSelectedFile(null);
        setPreviewData([]);
        setIsPreviewReady(false);
    };
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewParticipant(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            processFile(e.target.files[0]);
        }
    };
    
    const processFile = (file: File) => {
        try {
            setLoading(true);
            
            // Read the file using XLSX library
            const fileReader = new FileReader();
            
            fileReader.onload = (event) => {
                try {
                    if (!event.target || !event.target.result) {
                        throw new Error('Failed to read file');
                    }
                    
                    // Parse workbook from array buffer
                    const arrayBuffer = event.target.result;
                    const workbook = read(arrayBuffer, { type: 'array' });
                    
                    // Get the first worksheet
                    const worksheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[worksheetName];
                    
                    // Convert to JSON
                    const data = utils.sheet_to_json(worksheet);
                    
                    if (!Array.isArray(data) || data.length === 0) {
                        throw new Error('No valid data found in file');
                    }
                    
                    // Map to the required format
                    const mappedData = mapToParticipantFormat(data);
                    setPreviewData(mappedData);
                    setIsPreviewReady(true);
                } catch (error) {
                    console.error('Error processing file:', error);
                    setError('Error processing file: ' + (error instanceof Error ? error.message : 'Invalid format'));
                } finally {
                    setLoading(false);
                }
            };
            
            fileReader.onerror = () => {
                console.error('Error reading file');
                setLoading(false);
                setError('Error reading file');
            };
            
            // Read as array buffer for XLSX processing
            fileReader.readAsArrayBuffer(file);
            
        } catch (error) {
            console.error('Error uploading file:', error);
            setLoading(false);
        }
    };
    
    // Function to map spreadsheet data to the participant format
    const mapToParticipantFormat = (data: any[]): Array<{name: string, email: string}> => {
        return data.map(row => {
            // Try various possible column names for name and email
            const name = row.name || row.Name || row.NAME || row.fullName || row['Full Name'] || 
                         (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : null) ||
                         (row.FirstName && row.LastName ? `${row.FirstName} ${row.LastName}` : null);
                         
            const email = row.email || row.Email || row.EMAIL || row['Email Address'] || row.emailAddress;
            
            if (!name || !email) {
                console.warn('Skipping row due to missing name or email:', row);
            }
            
            return { name, email };
        }).filter(item => item.name && item.email); // Filter out items with missing fields
    };
    
    const handleSubmitManual = async () => {
        if (!surveyId) return;
        
        try {
            setLoading(true);
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const clientId = cookies.get("x-fathom-clientId");
            
            await apiClient.post(`${clientId}/participants/surveys/${surveyId}`, [{
                name: newParticipant.name,
                email: newParticipant.email
            }]);
            
            // Show success message
            setSnackbarMessage(`Added ${newParticipant.name} to the survey`);
            setSnackbarOpen(true);
            
            // Refresh survey data to include the new participant
            const response = await apiClient.get(`${clientId}/surveys/${surveyId}/overview`);
            setOverviewData(response.data);
            
            if (response.data && response.data.participants) {
                setFilteredParticipants(response.data.participants.data || []);
            }
            
            handleManualAddClose();
        } catch (error) {
            console.error('Error adding participant:', error);
            setError('Error adding participant: ' + (error instanceof Error ? error.message : 'Unknown error'));
        } finally {
            setLoading(false);
        }
    };
    
    const handleSubmitCsv = async () => {
        if (!surveyId) return;
        
        if (previewData.length === 0) {
            setError('No valid participants found in file');
            return;
        }
        
        try {
            setLoading(true);
            
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const clientId = cookies.get("x-fathom-clientId");
            
            await apiClient.post(`${clientId}/participants/surveys/${surveyId}`, previewData);
            
            // Show success message
            setSnackbarMessage(`Added ${previewData.length} participants from file`);
            setSnackbarOpen(true);
            
            // Refresh survey data to include the new participants
            const response = await apiClient.get(`${clientId}/surveys/${surveyId}/overview`);
            setOverviewData(response.data);
            
            if (response.data && response.data.participants) {
                setFilteredParticipants(response.data.participants.data || []);
            }
            
            handleCsvUploadClose();
        } catch (error) {
            console.error('Error uploading participants:', error);
            setError('Error uploading participants: ' + (error instanceof Error ? error.message : 'Unknown error'));
        } finally {
            setLoading(false);
        }
    };
    
    // Handle sending reminder emails
    const handleSendReminders = async () => {
        if (!surveyId) return;
        
        try {
            setLoading(true);
            
            // Get pending participants to send reminders to
            const pendingParticipants = filteredParticipants.filter(p => p.status === 'pending');
            
            if (pendingParticipants.length === 0) {
                setSnackbarMessage('No pending participants to send reminders to.');
                setSnackbarOpen(true);
                return;
            }
            
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const clientId = cookies.get("x-fathom-clientId");
            
            // For each participant, raise an event to queue the email
            const queueResults = await Promise.all(
                pendingParticipants.map(participant => 
                    apiClient.post(`${clientId}/surveys/${surveyId}/participants/${participant.participantId}/queue-email`, {
                        emailType: 'Reminder'
                    })
                )
            );
            
            // Update the UI to show emails are scheduled
            const updatedParticipants = [...filteredParticipants];
            pendingParticipants.forEach(participant => {
                const index = updatedParticipants.findIndex(p => p.participantId === participant.participantId);
                if (index !== -1) {
                    updatedParticipants[index] = {
                        ...participant,
                        emailStatus: 'Scheduled',
                        scheduledEmailDateUtc: new Date().toISOString()
                    };
                }
            });
            
            if (overviewData) {
                setOverviewData({
                    ...overviewData,
                    participants: {
                        ...overviewData.participants,
                        data: updatedParticipants
                    }
                });
            }
            
            setFilteredParticipants(updatedParticipants);
            
            // Show success message
            setSnackbarMessage(`${pendingParticipants.length} reminder email${pendingParticipants.length > 1 ? 's' : ''} queued successfully!`);
            setSnackbarOpen(true);
        } catch (err) {
            console.error('Error queueing reminder emails:', err);
            setError('Failed to queue reminders. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    
    // Handle sending a single reminder email
    const handleSendSingleReminder = async (participantId: string) => {
        if (!surveyId) return;
        
        try {
            // Set UI feedback
            const tempParticipants = [...filteredParticipants];
            const participantIndex = tempParticipants.findIndex(p => p.participantId === participantId);
            
            if (participantIndex === -1) return;
            
            // Update UI temporarily to show pending
            tempParticipants[participantIndex] = {
                ...tempParticipants[participantIndex],
                emailStatus: 'Processing...'
            };
            
            setFilteredParticipants(tempParticipants);
            
            // Make API call to queue the email
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const clientId = cookies.get("x-fathom-clientId");
            
            await apiClient.post(`${clientId}/surveys/${surveyId}/participants/${participantId}/queue-email`, {
                emailType: 'Reminder'
            });
            
            // Update UI after successful queue
            const updatedParticipants = [...filteredParticipants];
            updatedParticipants[participantIndex] = {
                ...updatedParticipants[participantIndex],
                emailStatus: 'Scheduled',
                scheduledEmailDateUtc: new Date().toISOString()
            };
            
            if (overviewData) {
                setOverviewData({
                    ...overviewData,
                    participants: {
                        ...overviewData.participants,
                        data: updatedParticipants
                    }
                });
            }
            
            setFilteredParticipants(updatedParticipants);
            
            // Show success message
            setSnackbarMessage(`Reminder email for ${updatedParticipants[participantIndex].name} queued successfully!`);
            setSnackbarOpen(true);
        } catch (err) {
            console.error('Error queueing single reminder email:', err);
            setError(`Failed to queue reminder for participant. Please try again later.`);
        }
    };
    
    // Handle export data
    const handleExportData = async () => {
        if (!surveyId) return;
        
        try {
            // In a real implementation, this would be an API call that returns a file
            const apiClient = new FathomClient(await getAccessTokenSilently());
            // Using the get method with only the URL parameter, without responseType
            // You may need to update the FathomClient to support responseType in a separate PR
            const response = await apiClient.get(`{clientId}/surveys/${surveyId}/export`);
            
            // Create a download link and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `survey-${surveyId}-data.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            console.error('Error exporting data:', err);
            setError('Failed to export data. Please try again later.');
        }
    };
    
    // Handle copy survey link
    const handleCopyLink = (participantId: string, participantName: string) => {
        var clientId = cookies.get("x-fathom-clientId");
        const surveyUrl = `${window.location.origin}/surveys/${clientId}/${surveyId}/${participantId}/submit`;
        
        navigator.clipboard.writeText(surveyUrl)
            .then(() => {
                setSnackbarMessage(`Survey link for ${participantName} copied to clipboard!`);
                setSnackbarOpen(true);
            })
            .catch(err => {
                console.error('Failed to copy link:', err);
                setSnackbarMessage('Failed to copy link. Please try again.');
                setSnackbarOpen(true);
            });
    };
    
    // Handle snackbar close
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    
    const navigateToHome = () => {
        navigate('/surveys');
    };
    
    // Handle publish survey
    const handlePublishSurvey = async () => {
        if (!surveyId) return;
        
        try {
            setPublishing(true);
            setError(null);
            
            const clientId = cookies.get("x-fathom-clientId");
            const apiClient = new FathomClient(await getAccessTokenSilently());
            
            const currentStatus = overviewData?.survey?.surveyStatus;
            const newStatus = currentStatus === 'Ready' ? 'Scheduled' : 'Ready';
            
            await apiClient.post(`${clientId}/surveys/${surveyId}/schedule`, { TargetStatus: currentStatus === 'Ready' ? "Scheduled" : "Ready" });
            
            fetchSurveyOverview();
            
            setSnackbarMessage(`Survey has been ${newStatus === 'Scheduled' ? 'published' : 'unpublished'} successfully!`);
            setSnackbarOpen(true);
        } catch (err) {
            console.error(`Error ${overviewData?.survey?.surveyStatus === 'Ready' ? 'publishing' : 'unpublishing'} survey:`, err);
            setError(`Failed to ${overviewData?.survey?.surveyStatus === 'Ready' ? 'publish' : 'unpublish'} survey. Please try again later.`);
        } finally {
            setPublishing(false);
        }
    };
    
    if (loading && !overviewData) {
        return (
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh'
            }}>
                <CircularProgress />
            </Container>
        );
    }
    
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}
            <BreadCrumb buttonFunc={navigateToHome} buttonText={"Surveys"} pageText={"View Survey"} /><br />
            {/* Survey header */}
            <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {overviewData?.survey?.name || 'Survey Overview'}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button 
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            onClick={handleExportData}
                        >
                            Export Data
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={() => navigate(`/surveys/${surveyId}/create`)}
                        >
                            Edit Survey
                        </Button>
                    </Box>
                </Box>
                
                <Typography variant="body1" color="text.secondary" paragraph>
                    {overviewData?.survey?.description || 'No description provided.'}
                </Typography>
                
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
                    <Chip 
                        label={`Status: ${overviewData?.survey?.surveyStatus ? 
                            (typeof overviewData.survey.surveyStatus === 'string' 
                                ? overviewData.survey.surveyStatus.charAt(0).toUpperCase() + overviewData.survey.surveyStatus.slice(1)
                                : String(overviewData.survey.surveyStatus)) : 
                            'Unknown'}`} 
                        color={overviewData?.survey?.surveyStatus === 'Ready' ? 'success' : 
                               overviewData?.survey?.surveyStatus === 'Scheduled' ? 'default' : 'error'}
                        sx={{ 
                            '&.MuiChip-colorSuccess': { bgcolor: '#4caf50', color: '#fff' },
                            '&.MuiChip-colorDefault': { bgcolor: '#5586AB', color: '#fff' },
                            '&.MuiChip-colorError': { bgcolor: '#f44336', color: '#fff' }
                        }}
                    />
                    <Chip 
                        label={`Created: ${overviewData?.survey?.createdDateUtc ? 
                            new Date(overviewData.survey.createdDateUtc).toLocaleDateString() : 'Unknown'}`} 
                        variant="outlined"
                        sx={{ borderColor: '#9e9e9e', color: '#616161' }}
                    />
                    <Chip 
                        label={`Survey Date: ${overviewData?.survey?.surveyDateUtc ? 
                            new Date(overviewData.survey.surveyDateUtc).toLocaleDateString() : 'Unknown'}`} 
                        variant="outlined"
                        sx={{ borderColor: '#9e9e9e', color: '#616161' }}
                    />
                </Box>
                
                {/* Add publish toggle switch */}
                {(overviewData?.survey?.surveyStatus === 'Ready' || overviewData?.survey?.surveyStatus === 'Scheduled') && (
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1, 
                            mb: 0,
                            p: 1.5,
                            borderColor: overviewData?.survey?.surveyStatus === 'Scheduled' ? '#4caf50' : '#ff9800',
                            maxWidth: 'fit-content'
                        }}
                    >
                        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                            {overviewData?.survey?.surveyStatus === 'Scheduled' ? 'Published' : 'Not Published'}:
                        </Typography>
                        <IconButton 
                            color={overviewData?.survey?.surveyStatus === 'Scheduled' ? "success" : "warning"}
                            onClick={handlePublishSurvey}
                            disabled={publishing || ['Draft', 'Sent', 'Complete'].includes(overviewData?.survey?.surveyStatus || '')}
                            size="medium"
                            sx={{ p: 0.5 }}
                        >
                            {overviewData?.survey?.surveyStatus === 'Scheduled' 
                                ? <ToggleOnIcon fontSize="large" color="success" /> 
                                : <ToggleOffIcon fontSize="large" color="warning" />
                            }
                        </IconButton>
                        {publishing && (
                            <CircularProgress size={20} color={overviewData?.survey?.surveyStatus === 'Ready' ? "warning" : "success"} />
                        )}
                    </Box>
                )}
            </Box>
            
            <Divider sx={{ mb: 4 }} />
            
            {/* Stats cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Completion Rate
                                    </Typography>
                                    <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
                                        {overviewData?.completionRate.toFixed(0)}%
                                    </Typography>
                                </Box>
                                <Box sx={{ 
                                    bgcolor: 'primary.light', 
                                    borderRadius: '50%', 
                                    width: 40, 
                                    height: 40, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center' 
                                }}>
                                    <TrendingUpIcon sx={{ color: 'primary.contrastText' }} />
                                </Box>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {overviewData ? 
                                    `${overviewData.totalParticipants - overviewData.pendingResponses} of ${overviewData.totalParticipants} participants` : 
                                    '0 of 0 participants'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Total Participants
                                    </Typography>
                                    <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
                                        {overviewData?.totalParticipants || 0}
                                    </Typography>
                                </Box>
                                <Box sx={{ 
                                    bgcolor: 'info.light', 
                                    borderRadius: '50%', 
                                    width: 40, 
                                    height: 40, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center' 
                                }}>
                                    <GroupIcon sx={{ color: 'info.contrastText' }} />
                                </Box>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {overviewData?.pendingResponses || 0} pending responses
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Recent Completions
                                    </Typography>
                                    <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
                                        {overviewData?.recentCompletions || 0}
                                    </Typography>
                                </Box>
                                <Box sx={{ 
                                    bgcolor: 'success.light', 
                                    borderRadius: '50%', 
                                    width: 40, 
                                    height: 40, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center' 
                                }}>
                                    <CompletedIcon sx={{ color: 'success.contrastText' }} />
                                </Box>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                In the last 7 days
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Avg. Completion Time
                                    </Typography>
                                    <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
                                        {overviewData?.averageCompletionTime 
                                            ? (typeof overviewData.averageCompletionTime === 'string'
                                               ? (() => {
                                                   const { minutes, seconds } = parseTimeString(overviewData.averageCompletionTime as string);
                                                   return `${minutes}m ${seconds}s`;
                                                })()
                                               : `${Math.floor(overviewData.averageCompletionTime as number / 60)}m`) 
                                            : 'N/A'}
                                    </Typography>
                                </Box>
                                <Box sx={{ 
                                    bgcolor: 'warning.light', 
                                    borderRadius: '50%', 
                                    width: 40, 
                                    height: 40, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center' 
                                }}>
                                    <ScheduleIcon sx={{ color: 'warning.contrastText' }} />
                                </Box>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                Based on completed responses
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            
            {/* Participants section */}
            <Box sx={{ mb: 3 }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    mb: 2 
                }}>
                    <Typography variant="h5" component="h2">
                        Participants
                    </Typography>
                    
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button 
                            variant="outlined"
                            startIcon={<EmailIcon />}
                            onClick={handleSendReminders}
                            disabled={!filteredParticipants.some(p => p.status === 'pending')}
                        >
                            Send Reminders
                        </Button>
                        <Button 
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleInviteParticipants}
                        >
                            Invite Participants
                        </Button>
                    </Box>
                </Box>
                
                {/* Add Participant Menu */}
                <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleManualAddOpen}>
                        <InviteIcon sx={{ mr: 1 }} />
                        Add Manually
                    </MenuItem>
                    <MenuItem onClick={handleCsvUploadOpen}>
                        <FileUploadIcon sx={{ mr: 1 }} />
                        Upload CSV
                    </MenuItem>
                </Menu>
                
                {/* Manual Add Dialog */}
                <Dialog open={manualAddOpen} onClose={handleManualAddClose} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        Add Participant
                        <IconButton
                            aria-label="close"
                            onClick={handleManualAddClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Name"
                                    value={newParticipant.name}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={newParticipant.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleManualAddClose}>Cancel</Button>
                        <Button 
                            onClick={handleSubmitManual} 
                            variant="contained" 
                            disabled={!newParticipant.name || !newParticipant.email}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* CSV Upload Dialog */}
                <Dialog open={csvUploadOpen} onClose={handleCsvUploadClose} maxWidth="md" fullWidth>
                    <DialogTitle>
                        Upload Participants
                        <IconButton
                            aria-label="close"
                            onClick={handleCsvUploadClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Upload a CSV or Excel file with participant information. The file should have columns for name and email.
                        </Typography>
                        <Box 
                            sx={{ 
                                backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                                p: 2, 
                                borderRadius: 1,
                                fontFamily: 'monospace',
                                mb: 2
                            }}
                        >
                            <pre style={{ margin: 0, overflow: 'auto' }}>
{`name,email
John Doe,john@example.com
Jane Smith,jane@example.com`}
                            </pre>
                        </Box>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<FileUploadIcon />}
                        >
                            Select File
                            <input
                                type="file"
                                accept=".csv,.xlsx,.xls"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                        {selectedFile && (
                            <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                                Selected file: {selectedFile.name}
                            </Typography>
                        )}
                        
                        {/* Preview Data */}
                        {isPreviewReady && (
                            <>
                                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                    Preview ({previewData.length} participants)
                                </Typography>
                                
                                {previewData.length > 0 ? (
                                    <Grid container spacing={1} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                        {previewData.slice(0, 20).map((p, index) => (
                                            <Grid item xs={12} sm={6} key={`${p.email}-${index}`}>
                                                <Box 
                                                    sx={{ 
                                                        p: 1,
                                                        border: '1px solid',
                                                        borderColor: 'divider',
                                                        borderRadius: 1
                                                    }}
                                                >
                                                    <Typography variant="subtitle2">{p.name}</Typography>
                                                    <Typography variant="body2" color="text.secondary">{p.email}</Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Alert severity="warning" sx={{ mt: 1 }}>
                                        No valid participant data found in the file. Please check the file format.
                                    </Alert>
                                )}
                                
                                {previewData.length > 20 && (
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Showing 20 of {previewData.length} participants
                                    </Typography>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCsvUploadClose}>Cancel</Button>
                        <Button 
                            onClick={handleSubmitCsv} 
                            variant="contained"
                            disabled={!isPreviewReady || previewData.length === 0}
                        >
                            Add {previewData.length} Participant{previewData.length !== 1 ? 's' : ''}
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* Filter and search section */}
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    mb: 2,
                    flexWrap: 'wrap',
                    gap: 2
                }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Chip 
                            label="All"
                            clickable
                            color={statusFilter === 'all' ? 'primary' : 'default'}
                            sx={{ 
                                '&.MuiChip-colorPrimary': { bgcolor: '#2196f3', color: '#fff' },
                                '&.MuiChip-colorDefault': { bgcolor: '#e0e0e0', color: '#424242' }
                            }}
                            onClick={() => handleStatusFilterChange('all')}
                        />
                        <Chip 
                            icon={<CompletedIcon />}
                            label="Completed"
                            clickable
                            color={statusFilter === 'completed' ? 'primary' : 'default'}
                            sx={{ 
                                '&.MuiChip-colorPrimary': { bgcolor: '#2196f3', color: '#fff' },
                                '&.MuiChip-colorDefault': { bgcolor: '#e0e0e0', color: '#424242' }
                            }}
                            onClick={() => handleStatusFilterChange('completed')}
                        />
                        <Chip 
                            icon={<PendingIcon />}
                            label="Pending"
                            clickable
                            color={statusFilter === 'pending' ? 'primary' : 'default'}
                            sx={{ 
                                '&.MuiChip-colorPrimary': { bgcolor: '#2196f3', color: '#fff' },
                                '&.MuiChip-colorDefault': { bgcolor: '#e0e0e0', color: '#424242' }
                            }}
                            onClick={() => handleStatusFilterChange('pending')}
                        />
                        <Chip 
                            label="Started"
                            clickable
                            color={statusFilter === 'started' ? 'primary' : 'default'}
                            sx={{ 
                                '&.MuiChip-colorPrimary': { bgcolor: '#2196f3', color: '#fff' },
                                '&.MuiChip-colorDefault': { bgcolor: '#e0e0e0', color: '#424242' }
                            }}
                            onClick={() => handleStatusFilterChange('started')}
                        />
                    </Box>
                    
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={showOnlyRecent}
                                    onChange={(e) => setShowOnlyRecent(e.target.checked)}
                                    size="small"
                                />
                            }
                            label="Recent activity only"
                        />
                        
                        <TextField
                            placeholder="Search participants..."
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                
                {/* Participants table */}
                <TableContainer component={Paper} variant="outlined">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Completion Date</TableCell>
                                <TableCell>Email Status</TableCell>
                                <TableCell>Email Details</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                                        <CircularProgress size={24} />
                                    </TableCell>
                                </TableRow>
                            ) : filteredParticipants.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {overviewData?.participants?.data.length === 0 
                                                ? 'No participants found. Invite participants to get started.' 
                                                : 'No participants match the current filters.'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredParticipants
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((participant) => (
                                    <TableRow key={participant.participantId}>
                                        <TableCell>
                                            {participant.status === 'completed' ? (
                                                <Box 
                                                    component="a" 
                                                    sx={{ 
                                                        cursor: 'pointer', 
                                                        color: 'primary.main',
                                                        textDecoration: 'none',
                                                        '&:hover': {
                                                            textDecoration: 'underline'
                                                        }
                                                    }}
                                                    onClick={() => navigate(`/surveys/${surveyId}/responses/${participant.participantId}`)}
                                                >
                                                    {participant.name}
                                                </Box>
                                            ) : (
                                                participant.name
                                            )}
                                        </TableCell>
                                        <TableCell>{participant.email}</TableCell>
                                        <TableCell>
                                            <Chip 
                                                size="small"
                                                label={typeof participant.status === 'string' 
                                                    ? participant.status.charAt(0).toUpperCase() + participant.status.slice(1)
                                                    : String(participant.status)}
                                                color={
                                                    participant.status === 'completed' ? 'success' : 
                                                    participant.status === 'started' ? 'warning' : 'default'
                                                }
                                                sx={{ 
                                                    '&.MuiChip-colorSuccess': { bgcolor: '#4caf50', color: '#fff' },
                                                    '&.MuiChip-colorWarning': { bgcolor: '#ff9800', color: '#fff' }, 
                                                    '&.MuiChip-colorDefault': { bgcolor: '#9e9e9e', color: '#fff' }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {participant.completedDate 
                                                ? new Date(participant.completedDate).toLocaleDateString() 
                                                : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {participant.emailStatus ? (
                                                <Chip 
                                                    size="small"
                                                    label={participant.emailStatus}
                                                    color={
                                                        participant.emailStatus.toLowerCase() === 'sent' ? 'success' : 
                                                        participant.emailStatus.toLowerCase() === 'scheduled' ? 'info' : 
                                                        participant.emailStatus.toLowerCase() === 'failed' ? 'error' : 
                                                        'default'
                                                    }
                                                    sx={{ 
                                                        '&.MuiChip-colorSuccess': { bgcolor: '#4caf50', color: '#fff' },
                                                        '&.MuiChip-colorInfo': { bgcolor: '#2196f3', color: '#fff' },
                                                        '&.MuiChip-colorError': { bgcolor: '#f44336', color: '#fff' },
                                                        '&.MuiChip-colorDefault': { bgcolor: '#9e9e9e', color: '#fff' }
                                                    }}
                                                />
                                            ) : '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ fontSize: '0.875rem' }}>
                                                {participant.scheduledEmailDateUtc && (
                                                    <Tooltip title="Scheduled Date">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                                                            <ScheduleIcon fontSize="small" color="action" />
                                                            <span>Scheduled: {new Date(participant.scheduledEmailDateUtc).toLocaleDateString()}</span>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {participant.sentEmailDateUtc && (
                                                    <Tooltip title="Sent Date">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                                                            <EmailIcon fontSize="small" color="success" />
                                                            <span>Sent: {new Date(participant.sentEmailDateUtc).toLocaleDateString()}</span>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {participant.emailErrorDetails && (
                                                    <Tooltip 
                                                        title={participant.emailErrorDetails}
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <Box sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center', 
                                                            gap: 0.5,
                                                            color: 'error.main',
                                                            cursor: 'help' 
                                                        }}>
                                                            <PendingIcon fontSize="small" color="error" />
                                                            <span>Error (hover for details)</span>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                                <Tooltip title="Copy survey link">
                                                    <IconButton 
                                                        size="small"
                                                        onClick={() => handleCopyLink(participant.participantId, participant.name)}
                                                    >
                                                        <ContentCopyIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                {participant.status === 'pending' && (
                                                    <Tooltip title="Send reminder">
                                                        <IconButton 
                                                            size="small"
                                                            onClick={() => handleSendSingleReminder(participant.participantId)}
                                                            disabled={participant.emailStatus === 'Scheduled' || participant.emailStatus === 'Processing...'}
                                                        >
                                                            <EmailIcon 
                                                                fontSize="small" 
                                                                color={participant.emailStatus === 'Scheduled' ? 'disabled' : 'inherit'} 
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {participant.status === 'completed' && (
                                                    <Tooltip title="View response">
                                                        <IconButton 
                                                            size="small"
                                                            onClick={() => navigate(`/surveys/${surveyId}/responses/${participant.participantId}`)}
                                                        >
                                                            <FilterIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={filteredParticipants.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
            
            {/* Snackbar for copy confirmation */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Container>
    );
}
