import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Button, Stack, IconButton, styled, Snackbar, Typography, Divider, Tab, Avatar, Paper, Tooltip, Menu, MenuItem, TextField, Pagination, LinearProgress } from "@mui/material";
import FathomClient from "api/fathomapi";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { PagedResult } from "app/types";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateDashboardDrawer } from "./create/createDrawer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import debounce from 'lodash/debounce';
import { Board } from "./types";
import Moment from "moment";
import ConfirmDialog from 'einhorn/confirmDialog';
import LoadingBar from "@einhorn/loadingBar";

const drawerWidth = 330;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    position: 'relative',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
            },
        },
    ],
}));


export default function ViewDashboards() {
    const { getAccessTokenSilently } = useAuth0();
    const [boards, setBoards] = useState({
        data: [],
        paging: {
            length: 0,
            pageSize: 0,
            page: 0,
            total: 0
        }
    } as PagedResult<Board>);

    const [loading, setLoading] = useState(true);
    const [dataDrawerOpen, setDataDrawerOpen] = useState(true);
    const [currentTab, setCurrentTab] = useState("my");
    const [search, setSearch] = useState("");
    const [copyOpen, setCopyOpen] = useState(false);
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedBoardId, setSelectedBoardId] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [boardToDelete, setBoardToDelete] = useState<string | null>(null);

    const loadBoards = async (searchTerm: string, page: number) => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/boards?page=` + page + "&search=" + searchTerm);
        setBoards(data);
        setLoading(false);
    }

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            loadBoards("", 1);
        };

        setLoading(true);
        fetchData()
            .catch(console.error);
    }, []);


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const viewReports = (boardId: string) => {
        navigate("/boards/" + boardId);
    };

    const createNew = () => {
        setDrawerOpen(true);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, boardId: string) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedBoardId(boardId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedBoardId(null);
    };

    const handleEdit = () => {
        // TODO: Implement edit functionality
        handleMenuClose();
    };

    const handleDelete = () => {
        setConfirmDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (selectedBoardId) {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            await apiClient.delete(`{clientId}/boards/${selectedBoardId}`);
            loadBoards(search, page);
            setBoardToDelete(null);
        }

        setConfirmDialogOpen(false);
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        if (newPage != page) {
            setPage(page);
            loadBoards(search, newPage)
        }
    };

    const handleSearch = debounce((searchTerm: string, pageNo: number) => {
        loadBoards(searchTerm, pageNo)
    }, 300);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearch(newValue)
        handleChangePage({} as ChangeEvent<unknown>, 1)
        handleSearch(newValue, 1);
    };

    const boardList = (my: boolean) => {
        return (
            <>
                {loading && boards.paging.length == 0 &&
                    <LoadingBar />
                }
                {!loading &&
                    <>
                        <Stack spacing={2} direction="row" justifyContent="left" sx={{ marginBottom: '-20px' }}>
                            <TextField
                                label="Search"
                                variant="standard"
                                value={search}
                                onChange={handleSearchChange}
                            />
                        </Stack>
                        <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
                            <Pagination
                                count={Math.ceil(boards.paging.total / boards.paging.pageSize)}
                                page={boards.paging.page}
                                onChange={handleChangePage}
                                color="primary"
                                variant="outlined"
                                showFirstButton showLastButton
                            />
                        </Stack>
                        <Grid container spacing={3}>
                            {boards.data.filter(z => z.userIsOwner == my).map((x: any) => (
                                <Grid item xs={12} sm={6} md={4} key={x.id}>
                                        <Paper
                                            className="programOverview"
                                            sx={{
                                                p: 2,
                                                display: "flex",
                                                flexDirection: "column",
                                                height: 128,
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    boxShadow: "0px 0px 5px 1px rgba(99, 39, 230, 0.1)",
                                                    '& .menu-button': {
                                                        opacity: 1,
                                                    },
                                                },
                                                position: 'relative'
                                            }}
                                            onClick={() => viewReports(x.id)}
                                        >
                                            <IconButton
                                                className="menu-button"
                                                size="small"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    opacity: 0,
                                                    transition: 'opacity 0.2s',
                                                    zIndex: 1,
                                                }}
                                                onClick={(e) => handleMenuClick(e, x.id)}
                                            >
                                                <MoreVertIcon fontSize="small" />
                                            </IconButton>

                                            <Typography variant="h5" noWrap sx={{ mb: 1, fontSize: '14px', fontWeight: 'bold' }}>
                                                {x.name || "Untitled Program"}
                                            </Typography>

                                            <Typography variant="h6" noWrap sx={{ mb: 1, fontSize: '14px', fontWeight: 'bold' }}>
                                                {x.description || "Untitled Program"}
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center"
                                                sx={{
                                                    flex: 1,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center'
                                                }}
                                            >

                                                <Typography variant="body2" noWrap sx={{ fontSize: '12px', color: 'text.secondary' }} >
                                                    {Moment(x.createdDateUtc).format("Do MMMM YYYY") || ""}
                                                </Typography>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Avatar src={x.userPhoto} alt={x.userName} sx={{ width: 20, height: 20 }} />
                                                <Typography variant="body2" sx={{ fontSize: '12px', color: 'text.secondary' }} noWrap>{x.author || "John Doe"}</Typography> 
                                            </Stack>
                                        </Paper>
                                </Grid>
                            ))}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleEdit}> <EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit</MenuItem>
                                <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}> <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Delete</MenuItem>
                            </Menu>
                        </Grid>
                    </>
                }
            </>
        );
    }

    return (
        <>
            <Snackbar
                message="Copied to clipboard"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={() => setCopyOpen(false)}
                open={copyOpen}
            />
            <Main open={dataDrawerOpen}>
                <Container >

                    <br />
                    <Button >Boards</Button>
                    <Button variant="outlined" onClick={() => createNew()} sx={{ float: 'right', marginBottom: '-30px' }}>
                        New Board
                    </Button>
                    <br />
                    <br />
                    <TabContext value={currentTab} >
                        <TabList
                            onChange={handleChange}
                            aria-label="Module info"
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                            sx={{
                                '& .MuiTab-root.Mui-selected': {
                                    color: 'action.selected',

                                }, ml: "-15px", mt: "-15px"
                            }}
                        >
                            <Tab key={0} label="My boards" value={"my"} sx={{ textTransform: 'none' }} />
                            <Tab key={1} label="Shared" value={"shared"} sx={{ textTransform: 'none' }} />
                        </TabList>
                        <Divider sx={{ mt: '-10px' }} />
                        <TabPanel key={0} value={"my"}>
                            {boardList(true)}
                        </TabPanel>
                        <TabPanel key={1} value={"shared"}>
                            {boardList(false)}
                        </TabPanel>

                    </TabContext>

                </Container>

                <CreateDashboardDrawer open={drawerOpen} toggleOpen={setDrawerOpen} />
            </Main>

            <ConfirmDialog
                title="Confirm Deletion"
                content="Are you sure you want to delete this board?"
                setOpen={setConfirmDialogOpen}
                open={confirmDialogOpen}
                confirmAction={confirmDelete}
            />

        </>
    );
}
