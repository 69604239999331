import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [jobRole, setJobRole] = useState('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Handle signup logic here
        console.log({ email, businessName, jobRole });
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Sign Up
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Business Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                />
                <TextField
                    label="Job Role"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={jobRole}
                    onChange={(e) => setJobRole(e.target.value)}
                />
                <Button type="submit" variant="contained" color="primary">
                    Sign Up
                </Button>
            </form>
        </Container>
    );
};

export default Signup;
