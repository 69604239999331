import {
    Button,
    Container,
    Grid,
    Pagination,
    Paper,
    Typography,
    TextField,
    Stack,
    styled,
    Box,
    Chip,
    Divider,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    LinearProgress
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { PagedResult, SurveyDto } from "app/types";
import LoadingBar from "@einhorn/loadingBar";
import debounce from 'lodash/debounce';
import Moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmDialog from 'einhorn/confirmDialog';
import BreadCrumb from '@einhorn/breadcrumb/breadcrumb';

export default function Surveys() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState({
        data: [],
        paging: {
            length: 0,
            pageSize: 0,
            page: 0,
            total: 0
        }
    } as PagedResult<SurveyDto>);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedSurveyId, setSelectedSurveyId] = useState<string | null>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
        open?: boolean;
    }>(({ theme }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        position: 'relative',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    marginRight: 0,
                },
            },
        ],
    }));

    const IsAdmin = () => {
        return localStorage.getItem("x-fathom-currentRole") == "Admin"
    }

    const loadSurveys = async (searchTerm: string, pageNumber: number) => {
        try {
            setLoading(true);
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(
                `{clientId}/surveys?page=${pageNumber}&search=${searchTerm}`
            );
            setSurveys(data);
        } catch (error) {
            console.error('Error loading surveys:', error);
        } finally {
            setLoading(false);
        }
    };

    const createNewSurvey = () => {
        navigate(`/surveys/create`);
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        if (newPage !== page) {
            setPage(newPage);
            loadSurveys(search, newPage);
        }
    };

    // Create debounced search function
    const handleSearch = debounce((searchTerm: string, pageNo: number) => {
        setPage(pageNo); // Update page state when search executes
        loadSurveys(searchTerm, pageNo);
    }, 300);

    // Handle search input changes
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearch(newValue);
        handleChangePage({} as ChangeEvent<unknown>, 1)
        handleSearch(newValue, 1);
    };

    // Initial load
    useEffect(() => {
        loadSurveys("", 1);
    }, []);

    // Helper function to normalize stage values to user-friendly text
    const getNormalizedStage = (stage: string): string => {
        switch (stage?.toLowerCase()) {
            case 'pre':
                return 'Pre-Program';
            case 'mid':
                return 'Mid-Program';
            case 'end':
                return 'End of Program';
            case 'post':
                return 'Post-Program';
            default:
                return stage || 'Custom';
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, surveyId: string) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedSurveyId(surveyId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedSurveyId(null);
    };

    const handleEdit = () => {
        if (selectedSurveyId) {
            navigate(`/surveys/${selectedSurveyId}/overview`);
        }
        handleMenuClose();
    };

    const handleDelete = () => {
        setConfirmDialogOpen(true);
    };

    const confirmDelete = async () => {
        if (selectedSurveyId) {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            await apiClient.delete(`{clientId}/surveys/${selectedSurveyId}`);
            loadSurveys(search, page);
            setSelectedSurveyId(null);
        }
        setConfirmDialogOpen(false);
    };



    const handleSurveyClick = (survey: SurveyDto) => {
        if(survey.surveyStatus === "Draft") {
            navigate(`/surveys/${survey.surveyId}/create`);
        } else {
            navigate(`/surveys/${survey.surveyId}/overview`);
        }
    };

    return (
        <Main>
            <Container>

                <Button
                    variant="outlined"
                    hidden={!IsAdmin()}
                    onClick={() => createNewSurvey()}
                    sx={{ float: 'right', marginBottom: '-30px' }}
                >
                    New Survey
                </Button>
                <br />
                <br />

                <Stack spacing={2} direction="row" justifyContent="left" sx={{ marginBottom: '-20px' }}>
                    <TextField
                        label="Search"
                        variant="standard"
                        value={search}
                        onChange={handleSearchChange}
                        autoComplete="off"
                    />
                </Stack>
                <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
                    <Pagination
                        count={Math.ceil(surveys.paging.total / surveys.paging.pageSize)}
                        page={surveys.paging.page}
                        onChange={handleChangePage}
                        color="primary"
                        variant="outlined"
                        showFirstButton showLastButton
                    />
                </Stack>

                {loading && surveys.data.length === 0 && (
                    <LoadingBar />
                )}

                {!loading && surveys.data.length === 0 && (
                    <Paper>
                        <Container>
                            <br />
                            <Typography variant="h6">
                                No surveys found
                            </Typography>
                            <br />
                        </Container>
                    </Paper>
                )}

                {loading && surveys.data.length > 0 && (
                    <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
                        <LinearProgress />
                    </Box>
                )}

                <Grid container spacing={3}>
                    {surveys.data.map((survey: any) => (
                        <Grid item xs={12} sm={6} md={4} key={survey.id || survey.surveyId}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    height: 'auto',
                                    minHeight: 180,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        boxShadow: "0px 0px 5px 1px rgba(99, 39, 230, 0.1)",
                                        '& .menu-button': {
                                            opacity: 1,
                                        },
                                    },
                                    position: 'relative'
                                }}
                                onClick={() => handleSurveyClick(survey)}
                            >
                                <IconButton
                                    className="menu-button"
                                    size="small"
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        zIndex: 1,
                                    }}
                                    onClick={(e) => handleMenuClick(e, survey.surveyId)}
                                >
                                    <MoreVertIcon fontSize="small" />
                                </IconButton>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    <Typography variant="h5" noWrap sx={{ fontSize: '14px', fontWeight: 'bold', flexGrow: 1 }}>
                                        {survey.name || "Untitled Survey"}
                                    </Typography>
                                    <Chip
                                        label={survey.surveyStatus}
                                        size="small"
                                        color={survey.surveyStatus === "Draft" ? "default" : "primary"}
                                        sx={{ ml: 1 }}
                                    />
                                </Box>

                                <Typography variant="body2" sx={{ mb: 1 }}>
                                    {survey.description || ""}
                                </Typography>

                                <Divider sx={{ my: 1 }} />

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography variant="caption" color="text.secondary">
                                        Program: {survey.programName || 'N/A'}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        Stage: {getNormalizedStage(survey.stage)}
                                    </Typography>
                                </Box>

                                {(survey.responseCount > 0 || survey.participantCount > 0) && (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                        <Typography variant="caption" color="text.secondary">
                                            Participants: {survey.participantCount || 0}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            Responses: {survey.responseCount || 0}
                                        </Typography>
                                    </Box>
                                )}

                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{
                                        mt: 'auto',
                                        pt: 1,
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography variant="body2" noWrap sx={{ fontSize: '12px', color: 'text.secondary' }}>
                                        {Moment(survey.createdDateUtc).format("Do MMMM YYYY") || ""}
                                    </Typography>
                                    <Box sx={{ flexGrow: 1 }}></Box>
                                    <Avatar
                                        src={survey.authorPhoto}
                                        alt={survey.authorName || "Unknown"}
                                        sx={{ width: 20, height: 20 }}
                                    />
                                    <Typography variant="body2" sx={{ fontSize: '12px', color: 'text.secondary' }} noWrap>
                                        {survey.authorName || "Unknown"}
                                    </Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleEdit}><EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit</MenuItem>
                    {IsAdmin() && (
                        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                            <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Delete
                        </MenuItem>
                    )}
                </Menu>
            </Container>

            <ConfirmDialog
                title="Confirm Deletion"
                content="Are you sure you want to delete this survey?"
                setOpen={setConfirmDialogOpen}
                open={confirmDialogOpen}
                confirmAction={confirmDelete}
            />
        </Main>
    );
}