import { useAuth0 } from "@auth0/auth0-react";
import { Container, Grid, Paper, Typography, LinearProgress, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Button, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import Title from "@einhorn/title";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import "./billing.css";
import {loadStripe, Stripe} from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';


interface BillingDto {
    trialCreditsRemaining?: number;
    clientType: 'Trial' | 'Standard';
    billingDate?: string;
    usage: number;
}

interface BillingRecordDto {
    usage: number;
    trigger: string;
    triggerUser: string;
    triggerName: string;
    triggerId: string;
    createdUtc: string;
}

export default function Billing() {
    const { getAccessTokenSilently, user } = useAuth0();
    const [billingData, setBillingData] = useState<BillingDto | null>(null);
    const [billingHistoryData, setBillingHistoryData] = useState<BillingRecordDto[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [stripe, setStripe] = useState<Stripe | null>(null); 
    const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
    const [isManageLoading, setIsManageLoading] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [statusMessage, setStatusMessage] = useState<{title: string, message: string} | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    const fetchBillingData = async () => {
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(`{clientId}/billing/`);
            setBillingData(data);
        } catch (error) {
            console.error('Error fetching billing data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        const fetchBillingHistoryData = async () => {
            try {
                const apiClient = new FathomClient(await getAccessTokenSilently());
                const { data } = await apiClient.get(`{clientId}/billing/history`);
                setBillingHistoryData(data);
            } catch (error) {
                console.error('Error fetching billing data:', error);
            } finally {
                setLoading(false);  
            }
        };

        const initializeStripe = async () => {
            const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');
            setStripe(stripeInstance);
        };

        initializeStripe();

        fetchBillingData();
        fetchBillingHistoryData();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const state = params.get('state');

        if (state === 'success') {
            setStatusMessage({
                title: 'Subscription Updated',
                message: 'Your subscription has been successfully updated. The changes will be reflected in your account shortly.'
            });
            setShowStatusModal(true);
        } else if (state === 'cancel') {
            setStatusMessage({
                title: 'Update Cancelled',
                message: 'The subscription update was cancelled. Your current subscription remains unchanged.'
            });
            setShowStatusModal(true);
        }
    }, [location]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    
    const handleManageBilling = async () => {
        if (!stripe) {
            console.error('Stripe has not been initialized');
            return;
        }

        setIsManageLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(`{clientId}/billing/manage`);
            
            const portalUrl = data.url;
            window.open(portalUrl, '_blank');
        } catch (error) {
            console.error('Error initiating upgrade:', error);
        } finally {
            setIsManageLoading(false);
        }
    };


    const handleUpgrade = async () => {
        if (!stripe) {
            console.error('Stripe has not been initialized');
            return;
        }

        setIsUpgradeLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const { data } = await apiClient.get(`{clientId}/billing/upgrade`);
            
            if (!data) {
                console.error('No sessionId received from the server');
                return;
            }

            const result = await stripe.redirectToCheckout({
                sessionId: data
            });

            if (result.error) {
                console.error('Stripe checkout error:', result.error);
            }
        } catch (error) {
            console.error('Error initiating upgrade:', error);
        } finally {
            setIsUpgradeLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowStatusModal(false);
        navigate(location.pathname, { replace: true });
        fetchBillingData();
    };
    
    if (loading) {
        return <LinearProgress />;
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 25, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Title>Billing & Usage</Title>
                </Grid>

                <Grid container item spacing={3}>
                    <Grid item xs={12} md={billingData?.clientType === 'Trial' ? 7 : 12}>
                        <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom>
                                Account type: {billingData?.clientType}
                            </Typography>

                            {billingData?.billingDate && (
                                <Typography color="text.secondary" sx={{ mb: 2 }}>
                                    Last billing date: {new Date(billingData.billingDate).toLocaleDateString()}
                                </Typography>
                            )}
                            {billingData?.clientType !== 'Trial' &&
                                <>
                                    <Typography color="text.secondary" sx={{ mb: 2 }}>
                                        Current Usage: {billingData?.usage}
                                    </Typography>
                                </>
                            }
                            {billingData?.clientType === 'Trial' && (
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Trial credits remaining: {billingData.trialCreditsRemaining} / 600
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={(((billingData.trialCreditsRemaining ?? 0) / 600) * 100)}
                                        sx={{
                                            height: 10,
                                            mt: 2,
                                            borderRadius: 5,
                                            '& .MuiLinearProgress-bar': {
                                                borderRadius: 5,
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                            {billingData?.clientType !== 'Trial' &&
                                <Box sx={{ mt: 'auto', pt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleManageBilling}
                                        disabled={isManageLoading}
                                    >
                                        {isManageLoading ? 'Loading...' : 'Manage Subscription'}
                                    </Button>
                                </Box>
                            }
                        </Paper>
                    </Grid>
                    {billingData?.clientType === 'Trial' && (
                        <Grid item xs={12} md={5}>
                            <Paper sx={{ p: 3, height: '100%' }} className="upgradeBox">
                                <Typography variant="h6" gutterBottom>
                                    Upgrade to Individual plan
                                </Typography>
                                <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
                                    £100/month
                                </Typography>
                                <List dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color="primary" fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary="Single User, Single License"
                                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color="primary" fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary="PAYG Data Credits @ 6p per Credit"
                                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color="primary" fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary="Rolling monthly contract"
                                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color="primary" fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary="Upgrade anytime to Team plan"
                                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                                        />
                                    </ListItem>
                                </List>
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        size="medium"
                                        onClick={handleUpgrade}
                                        disabled={!stripe || isUpgradeLoading}
                                        sx={{ 
                                            minWidth: 160,
                                            py: 1
                                        }}
                                    >
                                        {!stripe ? 'Loading...' : isUpgradeLoading ? 'Loading...' : 'Upgrade Now'}
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Billed usage history
                        </Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="billing history table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Usage</TableCell>
                                        <TableCell>Trigger</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billingHistoryData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((record, index) => (
                                            <TableRow
                                                key={record.triggerId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    {new Date(record.createdUtc).toLocaleDateString()} {new Date(record.createdUtc).toLocaleTimeString()}
                                                </TableCell>
                                                <TableCell>{record.usage}</TableCell>
                                                <TableCell>{record.trigger}</TableCell>
                                                <TableCell>{record.triggerUser}</TableCell>
                                                <TableCell>{record.triggerName}</TableCell>
                                            </TableRow>
                                        ))}
                                    {billingHistoryData.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                No billed usage history available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={billingHistoryData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={showStatusModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {statusMessage?.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {statusMessage?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}