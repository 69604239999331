import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Paper,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Alert,
  Snackbar
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FathomClient from "api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import ProgramSetup from "./steps/setup";
import DynamicInput from "@einhorn/dynamicInput";
import Title from "@einhorn/title";
import dayjs from "dayjs";
import ConfirmDialog from "@einhorn/confirmDialog";
import TouchpointsTimeline from "./steps/touchpoints";
import ParticipantsUpload from "./steps/participants";
import { Sheet } from 'xlsx'; // This ensures TypeScript recognizes the xlsx types

export default function CreateProgram() {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [validationError, setValidationError] = useState("");
  const [showError, setShowError] = useState(false);
  const [participantsToAdd, setParticipantsToAdd] = useState<{name: string, email: string}[]>([]);
  const [savingParticipants, setSavingParticipants] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();

  const [program, setProgram] = useState({
    name: "",
    description: "",
    outcomes: ["", "", "", ""],
    id: null as string | null,
    startDateUtc: dayjs(),
    endDateUtc: dayjs().add(4, 'month'), // Default program length is 4 months
    touchpoints: [
      { name: "Pre-Program", date: dayjs(), stage: "pre", description: "Initial assessment before program begins" },
      { name: "Mid-Program", date: dayjs().add(1, 'month'), stage: "mid", description: "Check progress halfway through the program" },
      { name: "Program Completion", date: dayjs().add(2, 'month'), stage: "end", description: "Final evaluation at program end" },
      { name: "Post-Program", date: dayjs().add(5, 'month'), stage: "post", description: "Follow-up to measure long-term impact" }
    ]
  });

  // Function to update touchpoints based on program start and end dates
  const updateTouchpointsBasedOnDuration = (programData: any) => {
    if (!programData.startDateUtc || !programData.endDateUtc) return programData;
    
    // Ensure startDate and endDate are valid dayjs objects
    const startDate = dayjs(programData.startDateUtc);
    const endDate = dayjs(programData.endDateUtc);
    
    if (!startDate.isValid() || !endDate.isValid()) {
      console.error('Invalid date detected', { startDate, endDate });
      return programData;
    }
    
    const durationMonths = endDate.diff(startDate, 'month', true);
    
    let updatedTouchpoints = [];
    
    // Always add Pre-Program touchpoint (1 week before start)
    updatedTouchpoints.push({
      id: programData.touchpoints?.find((tp: any) => tp.stage === 'pre')?.id || `touchpoint-pre-${Date.now()}`,
      name: "Pre-Program",
      date: startDate.subtract(7, 'day'),
      stage: "pre",
      description: "Initial assessment before program begins"
    });
    
    // For programs longer than 2 months, add mid-program touchpoint
    if (durationMonths >= 2) {
      const midDate = startDate.add(durationMonths / 2, 'month');
      updatedTouchpoints.push({
        id: programData.touchpoints?.find((tp: any) => tp.stage === 'mid')?.id || `touchpoint-mid-${Date.now()}`,
        name: "Mid-Program",
        date: midDate,
        stage: "mid",
        description: "Check progress halfway through the program"
      });
    }
    
    // Always add End touchpoint (at program end date)
    updatedTouchpoints.push({
      id: programData.touchpoints?.find((tp: any) => tp.stage === 'end')?.id || `touchpoint-end-${Date.now()}`,
      name: "Program Completion",
      date: endDate,
      stage: "end",
      description: "Final evaluation at program end"
    });
    
    // Always add Post-Program touchpoint (1 month after end)
    updatedTouchpoints.push({
      id: programData.touchpoints?.find((tp: any) => tp.stage === 'post')?.id || `touchpoint-post-${Date.now()}`,
      name: "Post-Program",
      date: endDate.add(1, 'month'),
      stage: "post",
      description: "Follow-up to measure long-term impact"
    });
    
    // Preserve any custom touchpoints that fall within the program's duration
    const customTouchpoints = (programData.touchpoints || [])
      .filter((tp: any) => {
        // Ensure we have a valid dayjs object for the touchpoint date
        const tpDate = tp.date && dayjs.isDayjs(tp.date) ? tp.date : dayjs(tp.date);
        return tp.stage === 'custom' && 
               tpDate.isValid()
      });
              
    return {
      ...programData,
      touchpoints: [...updatedTouchpoints, ...customTouchpoints]
    };
  };

  // Monitor changes to start or end date and update touchpoints accordingly
  useEffect(() => {
    if (program.startDateUtc && program.endDateUtc) {
      const updatedProgram = updateTouchpointsBasedOnDuration(program);
      // Only update if touchpoints have changed
      if (JSON.stringify(program.touchpoints) !== JSON.stringify(updatedProgram.touchpoints)) {
        setProgram(updatedProgram);
      }
    }
  }, [program.startDateUtc, program.endDateUtc]);

  // Add a function to handle participants that need to be added
  const handleParticipantsUpdate = (participants: {name: string, email: string}[]) => {
    setParticipantsToAdd(participants);
  };

  const steps = [
    {
      label: 'Program Setup',
      description: 'Configure the basic information about your program',
      component: <ProgramSetup value={program} setValue={setProgram} />
    },
    {
      label: 'Learning Outcomes',
      description: 'Define what participants should achieve',
      component: (
        <>
          <Title>Learning outcomes</Title>
          <Typography variant="subtitle1" gutterBottom>
            Set up the learning outcomes for your program, so we can understand your goals and generate the most useful report for you.
          </Typography>
          <DynamicInput value={program} setValue={setProgram} property="outcomes" label="Outcome" />
        </>
      )
    },
    {
      label: 'Touchpoints Timeline',
      description: 'Schedule key events throughout your program',
      component: <TouchpointsTimeline value={program} setValue={setProgram} />
    },
    {
      label: 'Add Participants',
      description: 'Invite people to participate in your program',
      component: <ParticipantsUpload programId={program.id} onParticipantsUpdate={handleParticipantsUpdate} />
    }
  ];

  const handleNext = () => {
    const isLastStep = activeStep === steps.length - 1;
    
    if (isLastStep) {
      // If we're on the last step and Finish is clicked, navigate to program details
      if (program.id) {
        navigate(`/programs/${program.id}`);
      } else {
        // If program hasn't been saved yet, show an error
        setValidationError("Please save your program first");
        setShowError(true);
      }
    } else {
      // Normal navigation to next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepClick = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (id !== undefined) {
      setLoading(true);
      const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + id);
        if (data.outcomes == null) {
          data.outcomes = [];
        }
        
        // Set default end date if not present (4 months after start date)
        if (!data.endDateUtc && data.startDateUtc) {
          data.endDateUtc = dayjs(data.startDateUtc).add(4, 'month');
        } else if (data.endDateUtc) {
          data.endDateUtc = dayjs(data.endDateUtc);
        }
        
        // If touchpoints don't exist, initialize them
        if (!data.touchpoints) {
          data.touchpoints = [
            { name: "Pre-Program", date: dayjs(data.startDateUtc).subtract(7, 'day'), stage: "pre" },
            { name: "Mid-Program", date: dayjs(data.startDateUtc).add(2, 'month'), stage: "mid" },
            { name: "Program Completion", date: dayjs(data.endDateUtc), stage: "end" },
            { name: "Post-Program", date: dayjs(data.endDateUtc).add(1, 'month'), stage: "post" }
          ];
        } else {
          // Convert date strings to dayjs objects
          data.touchpoints.forEach((touchpoint: any) => {
            touchpoint.date = dayjs(touchpoint.date);
          });
        }

        setProgram(data);
        setLoading(false);
      };

      fetchData().catch(console.error);
    }
  }, [id, getAccessTokenSilently]);

  const saveProgram = async () => {
    // Reset success state
    setSaveSuccess('');
    
    // Validate required fields
    const isNameValid = program.name.trim() !== '';
    const isDescriptionValid = program.description.trim() !== '';
    const areOutcomesValid = program.outcomes && program.outcomes.length > 0 && 
                           program.outcomes.some(outcome => outcome && outcome.trim() !== '');
    const isEndDateValid = program.endDateUtc && dayjs(program.endDateUtc).isAfter(program.startDateUtc);
    
    if (!isNameValid || !isDescriptionValid || !areOutcomesValid || !isEndDateValid) {
      // We have validation errors, show appropriate step
      if (!isNameValid || !isDescriptionValid || !isEndDateValid) {
        const errorField = !isNameValid ? "name" : !isDescriptionValid ? "description" : "end date";
        setValidationError(`Program ${errorField} is required${errorField === "end date" ? " and must be after start date" : ""}`);
        setActiveStep(0); // Program Setup contains name, description, and dates
      } else if (!areOutcomesValid) {
        setValidationError("At least one learning outcome is required");
        setActiveStep(1); // Learning Outcomes step
      }
      setShowError(true);
      return; // Prevent saving
    }
    
    setSavingParticipants(true);
    
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      
      // Create a copy of the program for saving with serialized dates
      const programToSave = {
        ...program,
        id: id !== undefined ? program.id : null,
        startDateUtc: dayjs(program.startDateUtc).isValid() ? dayjs(program.startDateUtc).toISOString() : null,
        endDateUtc: dayjs(program.endDateUtc).isValid() ? dayjs(program.endDateUtc).toISOString() : null,
        touchpoints: program.touchpoints.map((tp: any) => ({
          ...tp,
          date: dayjs(tp.date).isValid() ? dayjs(tp.date).toISOString() : null
        }))
      };

      // Save the program first
      let savedProgramId: string;
      let isNewProgram = false;
      
      if (id !== undefined && program.id) {
        // Update existing program
        const { data } = await apiClient.put(
          `{clientId}/programs/` + program.id,
          programToSave
        );
        savedProgramId = data.id;
      } else {
        // Create new program
        isNewProgram = true;
        const { data } = await apiClient.post(
          `{clientId}/programs/`,
          programToSave
        );
        savedProgramId = data.id;
        
        // Update program with the new ID
        setProgram(prev => ({
          ...prev,
          id: savedProgramId
        }));
      }
      
      // If we have participants to add, add them
      let participantsAdded = 0;
      if (participantsToAdd.length > 0) {
        try {
          await apiClient.post(`/{clientId}/participants/program/${savedProgramId}`, participantsToAdd);
          participantsAdded = participantsToAdd.length;
          setParticipantsToAdd([]);
        } catch (error) {
          console.error('Error adding participants:', error);
          setValidationError('Program saved but there was an error adding participants');
          setShowError(true);
          // Continue execution to handle program save
        }
      }
      
      // Set success message
      if (participantsAdded > 0) {
        setSaveSuccess(`Program saved successfully with ${participantsAdded} participant${participantsAdded !== 1 ? 's' : ''} added`);
      } else {
        setSaveSuccess("Program saved successfully");
      }
      
      // If it's a new program, navigate to edit page and show participants step
      if (isNewProgram) {
        navigate(`/programs/${savedProgramId}`);
        // Set activeStep to Participants step (index 3)
        setActiveStep(3);
      }
      
    } catch (error) {
      console.error('Error saving program:', error);
      setValidationError('Error saving program');
      setShowError(true);
    } finally {
      setSavingParticipants(false);
    }
  };

  const deleteProgram = async () => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    if (program.id) {
      await apiClient.delete(
        `{clientId}/programs/` + program.id
      );
      navigate("/programs/");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ConfirmDialog
        title="Delete program"
        content="Are you sure you want to delete this program?"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        confirmAction={deleteProgram}
      />
      
      <Snackbar 
        open={showError} 
        autoHideDuration={6000} 
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowError(false)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {validationError}
        </Alert>
      </Snackbar>
      
      <Snackbar 
        open={!!saveSuccess} 
        autoHideDuration={6000} 
        onClose={() => setSaveSuccess('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSaveSuccess('')} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {saveSuccess}
        </Alert>
      </Snackbar>
      
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {id ? 'Edit Program' : 'Create New Program'}
            </Typography>
          </Grid>
          {id && (
            <Grid item xs={12}>
              <Button 
                variant="outlined" 
                color="error" 
                onClick={() => setConfirmDeleteOpen(true)}
                sx={{ mb: 2 }}
              >
                Delete Program
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label} onClick={() => handleStepClick(index)} sx={{ cursor: 'pointer' }}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography variant="body2">{step.description}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3, minHeight: '500px' }}>
            {steps[activeStep].component}
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              
              <Box>
                <Button 
                  variant="outlined" 
                  onClick={saveProgram}
                  sx={{ mr: 1 }}
                  disabled={savingParticipants}
                >
                  {participantsToAdd.length > 0
                    ? `Save Program with ${participantsToAdd.length} Participant${participantsToAdd.length !== 1 ? 's' : ''}`
                    : "Save Program"}
                </Button>
                
                {activeStep === steps.length - 1 ? (
                  <Button 
                    variant="contained" 
                    onClick={handleNext}
                    disabled={!program.id}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
