import { Modal, Box, DialogTitle, Typography, IconButton, Grid, FormControl, TextField, Select, Input, MenuItem, Checkbox, ListItemText, Tooltip, Button, Container, LinearProgress, SelectChangeEvent, Link } from "@mui/material";
import { InsightsChartType, InsightsChartAction, ChartDataPoint, ProgramReportQuestions, ReportQuestion, ReportQuestionId, ProgramOption, allowChartTypes, ValidProgramReportQuestions } from "components/insights/insightTypes";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { enqueueSnackbar } from "notistack";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import GroupedChart from "./view/charts/GroupedChart";
import TableChart from "./view/charts/TableChart";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface ChartEditorProps {
    boardId: string | null;
    dataPointId: string | undefined | null;
    open: boolean;
    setModalOpen: (open: boolean) => void;
    onConfirm: () => void;
    existingChart?: ChartDataPoint;
    chartType: string;
    fetchData: (id: string) => void;
    order: number;
}

export default function ChartEditor({
    boardId,
    dataPointId,
    open,
    setModalOpen,
    onConfirm,
    order,
    fetchData,
    existingChart,
    chartType
}: ChartEditorProps) {
    const { getAccessTokenSilently } = useAuth0();
    const [hideEmptyResponses, setHideEmptyResponses] = useState(true);

    const [chartDataPoint, setChartDataPoint] = useState<ChartDataPoint>({
        title: existingChart?.title || "",
        description: existingChart?.description || "",
        order: 0,
        filter: {
            reportIdFilter: [],
            questionIdFilter: [],
            programIdFilter: [],
            type: InsightsChartType.Rating,
            gptquery: "",
            action: InsightsChartAction.Average,
            chartType: "Media"
        },
        dataPointId: dataPointId || undefined,
        staticMedia: existingChart?.staticMedia || undefined
    });

    const [optionsLoaded, setOptionsLoaded] = useState(false);
    const [reportIdFilter, setReportIdFilter] = useState([] as ReportQuestion[])
    const [questionIdFilter, setQuestionIdFilter] = useState([] as ReportQuestionId[])
    const [loading, setLoading] = useState(false);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [chartQueryData, setChartQueryData] = useState({ categories: [], series: new Map<string, number[]>() } as any);

    const [validChartOptions, setValidChartOptions] = useState({
        reports: [],
    } as ValidProgramReportQuestions);

    const [reportQuestionMap, setReportQuestionMap] = useState(new Map<string, ReportQuestionId[]>());
    const [chartOptions, setChartOptions] = useState({
        programs: [],
    } as ProgramReportQuestions);
    const [selectedPrograms, setSelectedPrograms] = useState<ProgramOption[]>([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [allowQuery, setAllowQuery] = useState(true);

    const getOptions = async () => {
        setOptionsLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const response = await apiClient.post(`{clientId}/boards/${boardId}/insights/options`, {});
            setChartOptions(response.data);

            let validReports = response.data.programs.flatMap((z: ProgramOption) => z.reports);
            setValidChartOptions({ ...validChartOptions, reports: validReports });
            setOptionsLoaded(true);
        } catch (error) {
            console.error('Error loading options:', error);
        } finally {
            setOptionsLoading(false);
        }
    }

    useEffect(() => {
        getOptions();
    }, [order, open])

    useEffect(() => {
        if (chartDataPoint.filter?.gptquery?.length == 0
            && chartDataPoint.filter.reportIdFilter.length > 0
            && chartDataPoint.filter.reportIdFilter[0] != null) {
            getData();
        }
    }, [chartDataPoint.filter.reportIdFilter, chartDataPoint.filter.questionIdFilter, chartType, chartDataPoint.filter.action])

    const preLoadInsight = async () => {
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const response = await apiClient.get(`{clientId}/boards/${boardId}/insights/chart/${dataPointId}`);

            console.log('Loading chart:', response.data);

            // Ensure filter exists before accessing its properties
            const filter = response.data.filter || {
                reportIdFilter: [],
                questionIdFilter: [],
                type: chartDataPoint.filter.type,
                chartType: chartDataPoint.filter.chartType
            };

            const chartType = filter.type as keyof typeof allowChartTypes;

            // Set chart data point
            setChartDataPoint({
                title: response.data.title || '',
                order: order,
                dataPointId: response.data.dataPointId || '',
                description: response.data.description || '',
                filter: {
                    ...filter,
                    type: chartType,
                    gptquery: response.data.gptquery || ''
                },
            });

            // Find and set selected programs based on the reports
            if (chartOptions?.programs && filter.reportIdFilter?.length) {
                // Create a set of report IDs for efficient lookup
                const selectedReportIds = new Set(filter.reportIdFilter);

                // Find programs that contain any of the selected reports
                const matchingPrograms = chartOptions.programs.filter(program =>
                    program.reports.some(report => selectedReportIds.has(report.reportId))
                );

                console.log('Matching programs:', matchingPrograms);
                setSelectedPrograms(matchingPrograms);

                // Update valid reports based on selected programs and chart type
                const validReports = matchingPrograms
                    .flatMap(program => program.reports)
                    .filter(report =>
                        report.questionIds.some(q =>
                            allowChartTypes[chartType].includes(q.type)
                        )
                    );
                setValidChartOptions({ ...validChartOptions, reports: validReports });

                // Set selected reports
                const selectedReportObjects = validReports.filter(report =>
                    selectedReportIds.has(report.reportId)
                );
                setReportIdFilter(selectedReportObjects);

                // Initialize question map for selected reports
                const initialQuestionMap = new Map<string, any[]>();
                selectedReportObjects.forEach(report => {
                    const reportQuestions = report.questionIds.filter(question =>
                        filter.questionIdFilter?.includes(question.questionId)
                    );
                    if (reportQuestions.length > 0) {
                        initialQuestionMap.set(report.reportId, reportQuestions);
                    }
                });
                setReportQuestionMap(initialQuestionMap);
            }

            // Get the chart data if we have filter values
            if (filter.reportIdFilter?.length && filter.questionIdFilter?.length) {
                getData();
            }

        } catch (error) {
            console.error('Error loading insight:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const loadInsight = async () => {
            if (optionsLoaded && open && dataPointId) {
                await preLoadInsight();
            }
        };
        loadInsight();
    }, [optionsLoaded, open, dataPointId]);

    const handleActionChange = (event: SelectChangeEvent) => {
        setChartDataPoint({
            ...chartDataPoint,
            filter: {
                ...chartDataPoint.filter,
                action: event.target.value as unknown as InsightsChartAction
            }

        })
    }

    const getData = async () => {
        setDataLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        if (chartDataPoint.filter.reportIdFilter.map((y: any) => y.reportId).length > 0 && chartDataPoint.filter.questionIdFilter.map((y: any) => y.questionId).length > 0) {
            var filter = {
                reportIdFilter: chartDataPoint.filter.reportIdFilter,
                questionIdFilter: chartDataPoint.filter.questionIdFilter,
                programIdFilter: chartDataPoint.filter.programIdFilter,
                type: chartDataPoint.filter.type,
                gptquery: chartDataPoint.filter.gptquery,
                chartType: chartType,
                action: chartDataPoint.filter.action
            }
            setAllowQuery(true);
            const response = await apiClient.post(`{clientId}/insights/data/${chartType}`, filter)
                .catch(err => {
                    enqueueSnackbar(err, {
                        variant: "error",
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    return;
                });
            if (response != null) {
                setChartQueryData({
                    data: response?.data.data
                });
            }
        }
        setDataLoading(false);
    }

    const canSave = () => {
        return chartDataPoint.title !== "";
    };


    const confirmData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        var filter = {
            reportIdFilter: chartDataPoint.filter.reportIdFilter,
            questionIdFilter: chartDataPoint.filter.questionIdFilter,
            programIdFilter: chartDataPoint.filter.programIdFilter,
            type: chartDataPoint.filter.type,
            gptquery: chartDataPoint.filter.gptquery,
            chartType: chartType,
            action: chartDataPoint.filter.action
        }

        chartDataPoint.order = order;
        chartDataPoint.filter = filter;
        chartDataPoint.dataPointId = dataPointId || undefined;
        const method = dataPointId ? 'put' : 'post';
        const url = dataPointId
            ? `{clientId}/boards/${boardId}/insights/chart/${dataPointId}`
            : `{clientId}/boards/${boardId}/insights/chart`;

        await apiClient[method](url, chartDataPoint);
        fetchData(boardId || "")
        clearModal();
        setOptionsLoading(false);

    };

    const clearModal = () => {
        setModalOpen(false);
        setChartOptions({
            programs: [],
        })

        setReportIdFilter([])
        setQuestionIdFilter([])
        setValidChartOptions({
            reports: [],
        })
        setChartQueryData({
            categories: [],
            series: new Map<string, number[]>()
        })

        setChartDataPoint({
            title: "",
            description: "",
            order: order,
            filter: {
                reportIdFilter: [],
                questionIdFilter: [],
                type: InsightsChartType.Rating,
                gptquery: "",
                chartType: "Group"
            },
            id: undefined
        } as any as ChartDataPoint)

        setSelectedPrograms([]);
    }

    useEffect(() => {
        if (existingChart) {
            setChartDataPoint({
                ...chartDataPoint,
                title: existingChart.title,
                description: existingChart.description,
                filter: existingChart.filter,
                staticMedia: existingChart.staticMedia
            });
        }
    }, [existingChart]);

    useEffect(() => {
        if (open && dataPointId) {
            loadChartData();
        }
    }, [open, dataPointId]);

    const loadChartData = async () => {
        setLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            const response = await apiClient.get(`{clientId}/boards/${boardId}/insights/chart/${dataPointId}`);
            setChartDataPoint(response.data);
        } catch (error) {
            console.error('Error loading chart data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleReportChange = (event: any) => {
        // Clear question filters when reports change
        let mp = new Map<string, ReportQuestionId[]>();
        setReportQuestionMap(mp);
        setReportIdFilter(event.target.value)
        setChartDataPoint({
            ...chartDataPoint,
            filter: {
                ...chartDataPoint.filter,
                reportIdFilter: event.target.value.map((x: ReportQuestion) => x.reportId),
                questionIdFilter: [] // Reset question filters
            }
        });
    };


    const handleQuestionChange = (report: any, event: any) => {


        let mp = reportQuestionMap;

        if (event.target.value === "clear") {
            mp.delete(report.reportId);
        } else {
            mp.set(report.reportId, event.target.value)
        }

        let allQuestions = Array.from(mp.values()).flat()
        setReportQuestionMap(mp);
        setQuestionIdFilter(allQuestions)
        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                questionIdFilter: allQuestions.map((x: ReportQuestionId) => x.questionId)
            }
        })
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        let newType = event.target.value as unknown as InsightsChartType

        setChartDataPoint({
            ...chartDataPoint,
            filter: {
                reportIdFilter: [],
                questionIdFilter: [],
                type: newType,
                gptquery: "",
                chartType: "Group",
                programIdFilter: []
            }
        })

        let mp = reportQuestionMap;
        mp.clear();

        setReportQuestionMap(mp);


        let validReports = chartOptions.programs.flatMap((z: ProgramOption) => z.reports).filter(y => y.questionIds.map(z => allowChartTypes[newType].indexOf(z.type) > -1).length > 0);
        setValidChartOptions({ ...validChartOptions, reports: validReports });

    };

    const handleProgramChange = (event: SelectChangeEvent<string[]>) => {
        const selectedIds = Array.isArray(event.target.value)
            ? event.target.value
            : [event.target.value];

        const selectedProgramObjects = chartOptions.programs.filter(
            program => selectedIds.includes(program.programId)
        );

        setSelectedPrograms(selectedProgramObjects);

        // Update valid reports based on selected programs
        const validReports = selectedProgramObjects
            .flatMap(program => program.reports)
            .filter(report =>
                report.questionIds.some(q =>
                    allowChartTypes[chartDataPoint.filter.type].includes(q.type)
                )
            );

        setValidChartOptions({ ...validChartOptions, reports: validReports });

        // Reset report and question filters
        let mp = new Map<string, ReportQuestionId[]>();
        setReportQuestionMap(mp);

        setChartDataPoint({
            ...chartDataPoint,
            filter: {
                ...chartDataPoint.filter,
                reportIdFilter: [], // Reset report filter
                questionIdFilter: [], // Reset question filter,
                programIdFilter: selectedIds
            }
        });
    };



    return (
        <Modal open={open} onClose={clearModal}>
            <Box className="modal-content">
                {loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <DialogTitle>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography variant="h6">{existingChart ? "Edit" : "Add"} Chart</Typography>
                                </Box>
                                <IconButton onClick={clearModal}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <Box className="modal-body">
                            <Grid container spacing={2}>
                                <Grid item container xs={6}>
                                    <div className="rounded-box top-box">
                                        <Grid container item xs={12}>
                                            <Grid item xs={3}><span className="titleFieldRow">Title</span></Grid>
                                            <Grid item xs={9}>
                                                <FormControl fullWidth className="dataFilterField">
                                                    <TextField
                                                        fullWidth
                                                        value={chartDataPoint.title}
                                                        onChange={(e) => setChartDataPoint({ ...chartDataPoint, title: e.target.value })}
                                                        margin="normal"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Grid item xs={3}><span className="titleFieldRow">Description</span></Grid>
                                            <Grid item xs={9}>
                                                <FormControl fullWidth className="dataFilterField">
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                        value={chartDataPoint.description}
                                                        onChange={(e) => setChartDataPoint({ ...chartDataPoint, description: e.target.value })}
                                                        margin="normal"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid hidden={chartType.toLocaleLowerCase() == "media"} item container xs={12}>
                                            <Grid item xs={3}><span className="titleFieldRow">Chart Type</span></Grid>
                                            <Grid item xs={9}>
                                                <FormControl fullWidth className="dataFilterField">
                                                    <Select
                                                        labelId="type-filter"
                                                        fullWidth
                                                        className="selectBox"
                                                        id="type-filter-chip"
                                                        value={chartDataPoint?.filter?.type}
                                                        onChange={handleTypeChange}
                                                        input={<Input id="select-multiple-chip" />}
                                                        disableUnderline
                                                        MenuProps={MenuProps}
                                                    >
                                                        <MenuItem value={InsightsChartType.Nps}>Nps</MenuItem>
                                                        <MenuItem value={InsightsChartType.Rating}>Rating</MenuItem>
                                                        <MenuItem value={InsightsChartType.Opinion}>Opinion</MenuItem>
                                                        <MenuItem value={InsightsChartType.Multi}>Multi</MenuItem>
                                                        <MenuItem value={InsightsChartType.OpinionSummary}>Opinion Summary</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid hidden={chartType.toLocaleLowerCase() != "number"} item container xs={12} >

                                            <Grid item xs={3} ><span className="titleFieldRow">Action</span></Grid>
                                            <Grid item xs={9} >
                                                <FormControl fullWidth className="dataFilterField">
                                                    <Select
                                                        labelId="type-filter"
                                                        fullWidth
                                                        className="selectBox"
                                                        id="type-filter-chip"
                                                        value={chartDataPoint?.filter?.action}
                                                        onChange={handleActionChange}
                                                        input={<Input id="select-multiple-chip" />}
                                                        disableUnderline
                                                        MenuProps={MenuProps}
                                                    >
                                                        <MenuItem value={InsightsChartAction.Sum}>Sum</MenuItem>
                                                        <MenuItem value={InsightsChartAction.Average}>Average</MenuItem>
                                                        <MenuItem value={InsightsChartAction.Count}>Count</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid hidden={chartType.toLocaleLowerCase() == "media"} item container xs={12}>
                                            <Grid item xs={3}><span className="titleFieldRow">Programs</span></Grid>
                                            <Grid item xs={9}>
                                                <FormControl fullWidth className="dataFilterField">
                                                    <Select
                                                        labelId="program-filter"
                                                        fullWidth
                                                        className="selectBox"
                                                        id="program-filter-chip"
                                                        multiple
                                                        value={selectedPrograms.map(p => p.programId)}
                                                        onChange={handleProgramChange}
                                                        input={<Input id="select-multiple-chip" />}
                                                        MenuProps={MenuProps}
                                                        disableUnderline
                                                        renderValue={(selected) => (
                                                            <>{selectedPrograms.length > 1
                                                                ? `${selectedPrograms.length} selected`
                                                                : selectedPrograms[0]?.programTitle}</>
                                                        )}
                                                    >
                                                        {chartOptions.programs.map((program) => (
                                                            <MenuItem
                                                                value={program.programId}
                                                                key={program.programId}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedPrograms.some(p => p.programId === program.programId)}
                                                                />
                                                                <ListItemText primary={program.programTitle} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid hidden={chartType.toLocaleLowerCase() == "media"} item container xs={12}>
                                            <Grid item xs={3} ><span className="titleFieldRow">Reports</span></Grid>
                                            <Grid item xs={9} >
                                                <FormControl fullWidth className="dataFilterField">
                                                    <Select
                                                        labelId="question-filter"
                                                        fullWidth
                                                        className="selectBox"
                                                        id="question-filter-chip"
                                                        multiple
                                                        value={reportIdFilter}
                                                        onChange={handleReportChange}
                                                        input={<Input id="select-multiple-chip" />}
                                                        MenuProps={MenuProps}
                                                        disableUnderline
                                                        renderValue={(selected) => (<>{selected.length > 1 ? selected.length + " selected" : selected[0].title}</>)}
                                                        disabled={selectedPrograms.length === 0}
                                                    >
                                                        {validChartOptions.reports.map((report) => (
                                                            <MenuItem value={report as any} key={report.reportId}>
                                                                <Tooltip title={report.title.length > 45 ? report.title : ''}  >
                                                                    <span>{report.title}</span>
                                                                </Tooltip>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Grid hidden={chartType.toLocaleLowerCase() == "media"} item xs={12} className={chartDataPoint?.filter?.reportIdFilter.length > 0 ? "rounded-box middle-box" : ""}>
                                        {reportIdFilter.map((report: ReportQuestion) =>
                                            <Grid container item xs={12} key={report.reportId} >
                                                <Grid item xs={3} ><span className="titleFieldRow">{report.title}</span></Grid>
                                                <Grid item xs={9} >
                                                    <Select
                                                        labelId="question-filter"
                                                        className="selectBox"
                                                        id="question-filter-chip"
                                                        value={reportQuestionMap.get(report.reportId) || []}
                                                        multiple
                                                        onChange={(event) => handleQuestionChange(report, event)}
                                                        input={<Input id="select-multiple-chip" />}
                                                        MenuProps={MenuProps}
                                                        disableUnderline
                                                        renderValue={(selected) => (<>{selected.length > 1 ? selected.length + " selected" : selected[0].title}</>)}
                                                        fullWidth
                                                    >

                                                        {report.questionIds
                                                            .filter((y) => allowChartTypes[chartDataPoint?.filter?.type].indexOf(y.type) > -1)
                                                            .map((question: any) => (
                                                                <MenuItem value={question as any} key={question.questionId}>
                                                                    <Tooltip title={question.title.length > 45 ? question.title : ''}  >
                                                                        <span>{question.title}</span>
                                                                    </Tooltip>
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <div hidden={chartType.toLocaleLowerCase() != "media"} className="rounded-box top-box">
                                        <Grid item container xs={12}>
                                            <Grid item xs={3}><span className="titleFieldRow">Text</span></Grid>
                                            <Grid item xs={9}>
                                                <FormControl fullWidth className="dataFilterField">
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={8}
                                                        value={chartDataPoint.staticMedia?.media || ""}
                                                        onChange={(e) => setChartDataPoint({ ...chartDataPoint, staticMedia: { type: "text", media: e.target.value } })}
                                                        margin="normal"
                                                    />
                                                    <p>Write your text here, <Link href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noopener noreferrer">markdown</Link> is supported.</p>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Grid>
                                <Grid item xs={6}>
                                    <Container>
                                        <div className="rounded-box chartpreview-box">
                                            <Typography variant="subtitle1">Preview</Typography>
                                            <br />
                                            <div className="rounded-box">
                                                {
                                                    chartDataPoint?.staticMedia?.type.toLocaleLowerCase() == "text" &&
                                                    <Box
                                                        sx={{
                                                            whiteSpace: "pre-wrap",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                            padding: "40px 120px 40px 120px"
                                                        }}
                                                    >
                                                        <Typography variant="h6" component="h2">{chartDataPoint.title}</Typography>
                                                        {chartDataPoint.description && (
                                                            <Typography variant="body2" color="textSecondary" sx={{ px: 0, pb: 2 }}>
                                                                {chartDataPoint.description}
                                                            </Typography>
                                                        )}
                                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{chartDataPoint.staticMedia?.media}</ReactMarkdown>
                                                    </Box>
                                                }
                                                {
                                                chartDataPoint?.filter?.questionIdFilter.length > 0 && chartType.toLocaleLowerCase() == "number" &&
                                                <>
                                                    <Typography variant="h6" component="h2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{chartDataPoint.title}</Typography>
                                                    {chartDataPoint.description && (
                                                        <Typography variant="body2" color="textSecondary" sx={{ px: 2, pb: 2 }}>
                                                            {chartDataPoint.description}
                                                        </Typography>
                                                    )}
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        minHeight: '200px'  // Gives enough height for vertical centering
                                                    }}>
                                                        <Typography variant="h3" component="h1">
                                                            {Math.round((chartQueryData?.data?.data || chartQueryData.data )  * 100) / 100}
                                                        </Typography>
                                                    </Box>
                                                </>
                                            }
                                                {
                                                    chartQueryData?.data?.categories?.length > 0 && chartDataPoint?.filter?.questionIdFilter?.length > 0 && chartType.toLocaleLowerCase() == "group" &&
                                                    <>
                                                        <Typography variant="h6" component="h2">{chartDataPoint.title}</Typography>
                                                        {chartDataPoint.description && (
                                                            <Typography variant="body2" color="textSecondary" sx={{ px: 2, pb: 2 }}>
                                                                {chartDataPoint.description}
                                                            </Typography>
                                                        )}
                                                        <br />
                                                        <GroupedChart
                                                            insightsChart={chartDataPoint}
                                                            loading={dataLoading}
                                                            chartQueryData={chartQueryData} />

                                                    </>
                                                }
                                                {
                                                    chartDataPoint?.filter?.questionIdFilter.length > 0 && chartType.toLocaleLowerCase() == "table" &&
                                                    <>
                                                        <Typography variant="h6" component="h2">&nbsp;&nbsp;&nbsp;{chartDataPoint.title}</Typography>
                                                        <TableChart
                                                            loading={dataLoading}
                                                            chartQueryData={chartQueryData}
                                                            hideEmptyResponses={hideEmptyResponses}
                                                            onHideEmptyResponsesChange={setHideEmptyResponses}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Grid item xs={1}>
                                <Tooltip title={canSave() ? '' : "Please set a title and select at least one report"}>
                                    <span><Button variant="outlined" disabled={!canSave()} onClick={confirmData}>Confirm</Button></span>
                                </Tooltip>
                            </Grid>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
} 