import { read, utils } from "xlsx";
import { useState } from "react";
import { MappingAnalysisResponse, Survey } from './types';
import FathomClient from 'api/fathomapi';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Drawer, Grid, IconButton, LinearProgress, Paper, Stack, styled, Typography, Alert, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ReportCreate from "../create";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import dayjs from "dayjs";


export default function SpreadSheetParse({ loadReports, setApiError }: any) {

    const { getAccessTokenSilently } = useAuth0();
    const [sheetData, setSheetData] = useState([] as any[]);
    const [analysedSurvey, setAnalysedSurvey] = useState({} as MappingAnalysisResponse);
    const [headers, setHeaders] = useState([] as string[]);
    const [step, setStep] = useState(0);
    const [analysing, setAnalysing] = useState(false);
    const [report, setReport] = useState({} as any);
    const [newReportDrawerOpen, setNewReportDrawerOpen] = useState(false);
    const { programId } = useParams();
    const [parsedSurvery, setParsedSurvey] = useState({} as Survey);
    const [boxDragOver, setBoxDragOver] = useState(false);
    const [sheetNames, setSheetNames] = useState<string[]>([]);
    const [sheetSelectOpen, setSheetSelectOpen] = useState(false);
    const [workbookData, setWorkbookData] = useState<any>(null);

    const cleanString = (str: string) => {
        return str.replaceAll("â", "").replaceAll(/’/g, "").replaceAll(/'/g, "").replaceAll(/[^\x20-\x7E]/g, '').trim();
    }

    const getHeaderRow = (sheet: any, range: any) => {
        const headers = [];
        let C;
        const R = range.s.r;
        for (C = range.s.c; C <= range.e.c; ++C) {
            const cell = sheet[utils.encode_cell({ c: C, r: R })];
            let hdr = "EMPTY HEADER " + C;
            if (cell && cell.t == "s") {
                cell.v = cleanString(cell.v);
                if (cell.w) {
                    cell.w = cleanString(cell.w);
                }
            }
            if (cell && cell.t) {
                hdr = utils.format_cell(cell)
            }
            headers.push(cleanString(hdr));
        }
        return headers;
    }

    const parseData = (resultData: Survey) => {
        resultData.title = parsedSurvery.title;
        resultData.reportDate = parsedSurvery.reportDate || dayjs();

        resultData.structure.forEach(questionBlock => {
            var questions = questionBlock.questions;
            questions.forEach(question => {
                question.responses = [];
            })
        });

        sheetData.forEach((i: any) => {
            let responseId = crypto.randomUUID();
            resultData.structure.forEach(questionBlock => {
                var questions = questionBlock.questions;
                questions.forEach(question => {
                    let title = cleanString(question.title);
                    
                    // Try exact match first
                    let val = i[title];
                    
                    if (val === undefined) {
                        // Try finding by matching the first 100 characters
                        const titleStart = title.substring(0, 100);
                        const matchingKey = Object.keys(i).find(key => 
                            cleanString(key).startsWith(titleStart)
                        );
                        
                        if (matchingKey) {
                            val = i[matchingKey];
                        }
                        
                        // If still not found, try without spaces
                        if (val === undefined) {
                            let noSpaceTitle = title.replaceAll(" ", "");
                            let filtered = Object.keys(i).filter(z => {
                                return cleanString(z).replaceAll(" ", "") === noSpaceTitle;
                            });
                            if (filtered.length > 0) {
                                val = i[filtered[0]];
                            }
                        }
                    }

                    question.responses.push({
                        response: String(val),
                        responseId: responseId
                    });
                });
            });
        });

        resultData.responseCount = sheetData.length;
        setParsedSurvey(resultData);
        setStep(1);
    }

    const runQuestionAnalysis = async (headers: string[], sheetData: any[]) => {
        let qha = [];
        for (let index = 0; index < headers.length; index++) {
            const hd = headers[index];
            qha.push({
                question: {
                    id: "",
                    header: hd
                },
                answers: sheetData.map(z => "" + z[hd])
            })
        }
        setAnalysing(true);
        setApiError(null);

        try {
            const apiClient = new FathomClient(await getAccessTokenSilently(), false);
            const response = await apiClient.post(
                `{clientId}/programs/` + programId + '/reports/analyseQuestions', 
                qha
            );
            setAnalysedSurvey(response.data);
        } catch (error: any) {
            // Extract error message from error object
            const errorMessage = error.response?.data?.ErrorMessage || error.message || 'An error occurred while analyzing questions';
            setApiError(errorMessage);
            setAnalysedSurvey({} as MappingAnalysisResponse);
            setNewReportDrawerOpen(false)
        } finally {
            setAnalysing(false);
        }
    }

    const handleSheetSelect = (sheetName: string) => {
        setSheetSelectOpen(false);
        processSheet(workbookData, sheetName);
    };

    const processSheet = (workbook: any, sheetName: string) => {
        var sheet = workbook.Sheets[sheetName];
        
        let firstCellVal = sheet["A1"];
        let rStart = 0, rEnd = 1;
        if ((firstCellVal?.h || firstCellVal?.v)?.indexOf("there are more sheets in this document") > -1) {
            rStart = 2, rEnd = 3;
        }
        var range = utils.decode_range(sheet['!ref'] as string);
        range.s.r = rStart;
        
        // Get raw header row
        var header = getHeaderRow(sheet, range);
        range.s.r = rEnd;

        // Create a map to track duplicate headers and their original indices
        const headerMap = new Map<string, number[]>();
        const uniqueHeaders = header.map((h, index) => {
            const cleanH = cleanString(h);
            if (!headerMap.has(cleanH)) {
                headerMap.set(cleanH, [index]);
                return cleanH;
            } else {
                const indices = headerMap.get(cleanH)!;
                indices.push(index);
                headerMap.set(cleanH, indices);
                // Append index to make unique key
                return `${cleanH} [${indices.length}]`;
            }
        });

        // Convert sheet to array of arrays
        sheet['!ref'] = utils.encode_range(range);
        const rawData = utils.sheet_to_json(sheet, { header: uniqueHeaders, range: range, defval: "" });

        // Process the data to handle duplicate headers
        const processedData = rawData.map((row: any) => {
            const newRow: any = {};
            Object.entries(row).forEach(([key, value]) => {
                if (!key.startsWith('EMPTY HEADER ')) {
                    newRow[key] = value;
                }
            });
            return newRow;
        });

        // Filter out empty headers while preserving duplicates with their indices
        const cleanedHeaders = uniqueHeaders.filter(h => !h.startsWith('EMPTY HEADER ') && processedData.filter(z => z[h]).filter(z => z.toString().trim() !== '').length > 0);
        
        setSheetData(processedData);
        setHeaders(cleanedHeaders);
        setParsedSurvey({ responseCount: processedData.length } as Survey);
        setStep(0);
        runQuestionAnalysis(cleanedHeaders, processedData);
    };

    const manageFile = async (e: any) => {
        const data = await e.arrayBuffer();
        var workbook = read(data, {
            type: 'binary',
            cellDates: true,
            cellNF: false,
            cellText: false
        });

        setWorkbookData(workbook);
        const sheets = Object.keys(workbook.Sheets);
        
        if (sheets.length > 1) {
            setSheetNames(sheets);
            setSheetSelectOpen(true);
        } else {
            processSheet(workbook, sheets[0]);
        }
    };

    const [fileName, setFileName] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!analysing) {
            setNewReportDrawerOpen(true)
            setBoxDragOver(false);
            const file = event.target.files?.[0];
            if (file) {
                setFileName(file.name);
                manageFile(file);
            }
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {

        event.preventDefault();
        if (!analysing) {
            setBoxDragOver(false);
            setNewReportDrawerOpen(true)
            const file = event.dataTransfer.files[0];
            if (file) {
                setFileName(file.name);
                manageFile(file);
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setBoxDragOver(true);


    };

    const FileUploader = () => {
        return (
            <>

                <div
                >
                    <input
                        type="file"
                        id="fileInput"
                        accept=".csv,.xlsx,.xls,.ods"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        disabled={analysing}
                    />
                    <Grid container spacing={2} alignItems="center" sx={{ marginTop: "15px" }}>
                        <Grid sm={2} item>
                            <UploadFileOutlinedIcon sx={{ mt: "-20px" }} fontSize="large" />
                        </Grid>
                        <Grid sm={7} item sx={{ mt: "-18px" }}>
                            {!analysing && <>
                                <b>Upload report</b>
                                <Typography sx={{ fontSize: "12px" }}>csv / xls / xlsx</Typography>
                            </>}
                            {analysing && <LinearProgress color="secondary" />}
                        </Grid>
                        <Grid sm={2} item sx={{ mt: "-18px" }}>
                            <Button sx={{
                                flexShrink: 0,
                                '& .MuiButton-startIcon': {
                                    mr: 0,
                                    ml: 0
                                }
                            }}
                                hidden={analysing}
                                disableElevation
                                startIcon={<ArrowForwardOutlinedIcon fontSize="large" />}>
                            </Button>

                        </Grid>
                    </Grid>
                </div>


            </>
        );
    };

    const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setBoxDragOver(false);
    };

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        marginTop: "20px"
    }));

    const resetCreate = () => {
        setNewReportDrawerOpen(false);
        setStep(0);
    }

    return (
        <>

            <Paper className={(boxDragOver || analysing) ? "uploadBox-drag" : "uploadBox"}
                sx={{ marginBottom: "25px" }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragEnd}
                onClick={() => document.getElementById('fileInput')?.click()}
            >
                <Container>

                    <FileUploader />
                </Container>
            </Paper>
            <Drawer
                anchor='right'
                sx={{
                    width: "600px",
                }}
                open={newReportDrawerOpen}
                PaperProps={{
                    sx: { width: "800px" },
                }}
            >
                <DrawerHeader >
                    <Container>
                        <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '0px' }}>
                            <IconButton aria-label="open" onClick={() => resetCreate()}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </Stack>
                    </Container>
                </DrawerHeader>
                <ReportCreate
                    loadReports={loadReports}
                    setTopReport={setReport}
                    analysing={analysing}
                    step={step}
                    setStep={setStep}
                    parsedSurvery={parsedSurvery}
                    setParsedSurvey={setParsedSurvey}
                    sheetData={sheetData}
                    analysedSurvey={analysedSurvey}
                    parseData={parseData}
                    setNewReportDrawerOpen={setNewReportDrawerOpen} />
            </Drawer>
            <Dialog open={sheetSelectOpen} onClose={() => setSheetSelectOpen(false)}>
                <DialogTitle>Select Report Sheet</DialogTitle>
                <DialogContent>
                    <List>
                        {sheetNames.map((sheetName) => (
                            <ListItem disablePadding key={sheetName}>
                                <ListItemButton onClick={() => handleSheetSelect(sheetName)}>
                                    <ListItemText primary={sheetName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
}
