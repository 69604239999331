import { useState, useEffect } from 'react';
import { 
    DataGrid, 
    GridColDef, 
    GridPaginationModel 
} from '@mui/x-data-grid';
import { 
    Box, 
    Container, 
    Typography, 
    Button, 
    Menu, 
    MenuItem, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    TextField, 
    Grid,
    IconButton,
    Stack,
    Alert
} from '@mui/material';
import { useParams } from 'react-router-dom';
import FathomClient from '@api/fathomapi';
import { useAuth0 } from '@auth0/auth0-react';
import { read, utils } from 'xlsx';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { PagedResult } from 'app/types';

interface ParticipantsProps {
    programId?: string;
}

interface Participant {
    participantId: string;
    name: string;
    email: string;
    dateCreated: string;
    status: string;
}


const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1.5 }
];

const Participants = ({ programId }: ParticipantsProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 25,
    });

    // Add participant menu state
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    // Modal states
    const [manualAddOpen, setManualAddOpen] = useState(false);
    const [csvUploadOpen, setCsvUploadOpen] = useState(false);
    
    // New participant form state
    const [newParticipant, setNewParticipant] = useState({
        name: '',
        email: ''
    });
    
    // CSV file state
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewData, setPreviewData] = useState<{name: string, email: string}[]>([]);
    const [isPreviewReady, setIsPreviewReady] = useState(false);

    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleManualAddOpen = () => {
        handleMenuClose();
        setManualAddOpen(true);
    };

    const handleManualAddClose = () => {
        setManualAddOpen(false);
        setNewParticipant({
            name: '',
            email: ''
        });
    };

    const handleCsvUploadOpen = () => {
        handleMenuClose();
        setCsvUploadOpen(true);
        setPreviewData([]);
        setIsPreviewReady(false);
    };

    const handleCsvUploadClose = () => {
        setCsvUploadOpen(false);
        setSelectedFile(null);
        setPreviewData([]);
        setIsPreviewReady(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewParticipant(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            processFile(e.target.files[0]);
        }
    };

    const processFile = (file: File) => {
        try {
            setLoading(true);
            
            // Read the file using XLSX library
            const fileReader = new FileReader();
            
            fileReader.onload = (event) => {
                try {
                    if (!event.target || !event.target.result) {
                        throw new Error('Failed to read file');
                    }
                    
                    // Parse workbook from array buffer
                    const arrayBuffer = event.target.result;
                    const workbook = read(arrayBuffer, { type: 'array' });
                    
                    // Get the first worksheet
                    const worksheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[worksheetName];
                    
                    // Convert to JSON
                    const data = utils.sheet_to_json(worksheet);
                    
                    if (!Array.isArray(data) || data.length === 0) {
                        throw new Error('No valid data found in file');
                    }
                    
                    // Map to the required format
                    const mappedData = mapToParticipantFormat(data);
                    setPreviewData(mappedData);
                    setIsPreviewReady(true);
                } catch (error) {
                    console.error('Error processing file:', error);
                    setErrorMessage('Error processing file: ' + (error instanceof Error ? error.message : 'Invalid format'));
                } finally {
                    setLoading(false);
                }
            };
            
            fileReader.onerror = () => {
                console.error('Error reading file');
                setLoading(false);
                setErrorMessage('Error reading file');
            };
            
            // Read as array buffer for XLSX processing
            fileReader.readAsArrayBuffer(file);
            
        } catch (error) {
            console.error('Error uploading file:', error);
            setLoading(false);
        }
    };

    const handleSubmitManual = async () => {
        try {
            setLoading(true);
            const apiClient = new FathomClient(await getAccessTokenSilently());
            
            const endpoint = programId 
                ? `/{clientId}/participants/program/${programId}` 
                : `/{clientId}/participants`;
                
            await apiClient.post(endpoint, [{
                name: newParticipant.name,
                email: newParticipant.email
            }]);
            
            // Show success message
            setSuccessMessage(`Added ${newParticipant.name} to the program`);
            setTimeout(() => setSuccessMessage(''), 3000);
            
            // Refresh the participant list
            fetchParticipants();
            handleManualAddClose();
        } catch (error) {
            console.error('Error adding participant:', error);
            setErrorMessage('Error adding participant: ' + (error instanceof Error ? error.message : 'Unknown error'));
            setTimeout(() => setErrorMessage(''), 5000);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitCsv = async () => {
        if (previewData.length === 0) {
            setErrorMessage('No valid participants found in file');
            return;
        }
        
        try {
            setLoading(true);
            
            const apiClient = new FathomClient(await getAccessTokenSilently());
            
            const endpoint = programId 
                ? `/{clientId}/participants/program/${programId}` 
                : `/{clientId}/participants`;
            
            await apiClient.post(endpoint, previewData);
            
            // Show success message
            setSuccessMessage(`Added ${previewData.length} participants from file`);
            setTimeout(() => setSuccessMessage(''), 3000);
            
            // Refresh the participant list
            fetchParticipants();
            handleCsvUploadClose();
        } catch (error) {
            console.error('Error uploading participants:', error);
            setErrorMessage('Error uploading participants: ' + (error instanceof Error ? error.message : 'Unknown error'));
            setTimeout(() => setErrorMessage(''), 5000);
        } finally {
            setLoading(false);
        }
    };

    // Function to map spreadsheet data to the participant format
    const mapToParticipantFormat = (data: any[]): Array<{name: string, email: string}> => {
        return data.map(row => {
            // Try various possible column names for name and email
            const name = row.name || row.Name || row.NAME || row.fullName || row['Full Name'] || 
                         (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : null) ||
                         (row.FirstName && row.LastName ? `${row.FirstName} ${row.LastName}` : null);
                         
            const email = row.email || row.Email || row.EMAIL || row['Email Address'] || row.emailAddress;
            
            if (!name || !email) {
                console.warn('Skipping row due to missing name or email:', row);
            }
            
            return { name, email };
        }).filter(item => item.name && item.email); // Filter out items with missing fields
    };

    const fetchParticipants = async () => {
        
        setLoading(true);
        try {
            const apiClient = new FathomClient(await getAccessTokenSilently());
            
            const endpoint = programId 
                ? `/{clientId}/participants/program/${programId}?page=${paginationModel.page + 1}&pageSize=${paginationModel.pageSize}`
                : `/{clientId}/participants?page=${paginationModel.page + 1}&pageSize=${paginationModel.pageSize}`;
            
            const response = await apiClient.get(endpoint);
            
            if (!response.data) {
                throw new Error('Failed to fetch participants');
            }

            const data: PagedResult<Participant> = response.data;

            setParticipants(data.data);
            setTotalRows(data.paging.total);
        } catch (error) {
            console.error('Error fetching participants:', error);
            setErrorMessage('Error fetching participants: ' + (error instanceof Error ? error.message : 'Unknown error'));
            setTimeout(() => setErrorMessage(''), 5000);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParticipants();
    }, [programId, paginationModel]);

    return (
        <Container>
            <Box sx={{ height: 600, width: '100%', marginTop: 10 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                    <Typography variant="h5">
                        People
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleAddClick}
                        color="primary"
                    >
                        Add Participants
                    </Button>
                </Stack>
                
                {/* Success and Error Messages */}
                {successMessage && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {successMessage}
                    </Alert>
                )}
                
                {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
                
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleManualAddOpen}>
                        <PersonAddIcon sx={{ mr: 1 }} />
                        Add Manually
                    </MenuItem>
                    <MenuItem onClick={handleCsvUploadOpen}>
                        <FileUploadIcon sx={{ mr: 1 }} />
                        Upload CSV
                    </MenuItem>
                </Menu>
                
                {/* Manual Add Dialog */}
                <Dialog open={manualAddOpen} onClose={handleManualAddClose} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        Add Participant
                        <IconButton
                            aria-label="close"
                            onClick={handleManualAddClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="name"
                                    label="Name"
                                    value={newParticipant.name}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={newParticipant.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleManualAddClose}>Cancel</Button>
                        <Button 
                            onClick={handleSubmitManual} 
                            variant="contained" 
                            disabled={!newParticipant.name || !newParticipant.email}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* CSV Upload Dialog */}
                <Dialog open={csvUploadOpen} onClose={handleCsvUploadClose} maxWidth="md" fullWidth>
                    <DialogTitle>
                        Upload Participants
                        <IconButton
                            aria-label="close"
                            onClick={handleCsvUploadClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Upload a CSV or Excel file with participant information. The file should have columns for name and email.
                        </Typography>
                        <Box 
                            sx={{ 
                                backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                                p: 2, 
                                borderRadius: 1,
                                fontFamily: 'monospace',
                                mb: 2
                            }}
                        >
                            <pre style={{ margin: 0, overflow: 'auto' }}>
{`name,email
John Doe,john@example.com
Jane Smith,jane@example.com`}
                            </pre>
                        </Box>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<FileUploadIcon />}
                        >
                            Select File
                            <input
                                type="file"
                                accept=".csv,.xlsx,.xls"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                        {selectedFile && (
                            <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                                Selected file: {selectedFile.name}
                            </Typography>
                        )}
                        
                        {/* Preview Data */}
                        {isPreviewReady && (
                            <>
                                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                    Preview ({previewData.length} participants)
                                </Typography>
                                
                                {previewData.length > 0 ? (
                                    <Grid container spacing={1} sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                        {previewData.slice(0, 20).map((p, index) => (
                                            <Grid item xs={12} sm={6} key={`${p.email}-${index}`}>
                                                <Box 
                                                    sx={{ 
                                                        p: 1,
                                                        border: '1px solid',
                                                        borderColor: 'divider',
                                                        borderRadius: 1
                                                    }}
                                                >
                                                    <Typography variant="subtitle2">{p.name}</Typography>
                                                    <Typography variant="body2" color="text.secondary">{p.email}</Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Alert severity="warning" sx={{ mt: 1 }}>
                                        No valid participant data found in the file. Please check the file format.
                                    </Alert>
                                )}
                                
                                {previewData.length > 20 && (
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Showing 20 of {previewData.length} participants
                                    </Typography>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCsvUploadClose}>Cancel</Button>
                        <Button 
                            onClick={handleSubmitCsv} 
                            variant="contained"
                            disabled={!isPreviewReady || previewData.length === 0}
                        >
                            Add {previewData.length} Participant{previewData.length !== 1 ? 's' : ''}
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <DataGrid
                    rows={participants || []}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode="server"
                    rowCount={totalRows}
                    loading={loading}
                    disableRowSelectionOnClick
                    pageSizeOptions={[10, 25, 50]}
                    getRowId={(row) => row.participantId}
                />
            </Box>
        </Container>
    );
};

export default Participants;
